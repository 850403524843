import React, { useState, useEffect } from "react";
import Avatar from "../Avatar";
import EditProfile from "./EditProfile";
import challenges from "../../pages/friendsManagement/challenges.png";
import message from "../../images/message.png";
// import FollowBtn from "../FollowBtn";
import Followers from "./Followers";
import Following from "./Following";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
// import StatusButton from "../home/StatusButton";
import "./InfoStyle.css";
import InvitationBtn from "../home/invitationBtn";
import axios from "axios";
import {useHistory} from "react-router-dom";

const Info = ({ id, auth, profile, dispatch }) => {

  const [userData, setUserData] = useState([]);
  const [onEdit, setOnEdit] = useState(false);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowing, setShowFollowing] = useState(false);
  const [challengerState, setChallengerState] = useState({})
  const history = useHistory();

  
  // Get whether the visited profile is being challenged or not
  useEffect(() => {
    async function getChallengerState() {
      await axios.post('/api/user/' + id + '/challenger',
        { id: auth.user._id }, {
        headers: { Authorization: auth.token }
      })
        .then(res => setChallengerState(res.data))

    }
    if (id !== auth.user._id) getChallengerState()
  }, [auth, id])


  
  // Get whether the visited profile is being challenged or not
  useEffect(() => {
    async function getChallengerState() {
      await axios.post('/api/user/' + id + '/challenger',
        { id: auth.user._id }, {
        headers: { Authorization: auth.token }
      })
        .then(res => setChallengerState(res.data))

    }
    if (id !== auth.user._id) getChallengerState()
  }, [auth, id])

  useEffect(() => {
    if (id === auth.user._id) {
      setUserData([auth.user]);
    } else {
      const newData = profile.users.filter((user) => user._id === id);
      setUserData(newData);
    }
  }, [id, auth, dispatch, profile.users]);

  useEffect(() => {
    if (showFollowers || showFollowing || onEdit) {
      dispatch({ type: GLOBALTYPES.MODAL, payload: true });
    } else {
      dispatch({ type: GLOBALTYPES.MODAL, payload: false });
    }
  }, [showFollowers, showFollowing, onEdit, dispatch]);

  const handleChallengeRequest = () => {
    if (challengerState.areChallenging)
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "You are already challenging this user!" },
      });

    if (challengerState.pendingSendChallengeRequest)
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "You have already sent this user a challenge request!" },
      });

    if (challengerState.pendingReceivedChallengeRequest)
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "This user has already sent you a challenge request!" },
      });

    dispatch({ type: GLOBALTYPES.CHALLENGE, payload: true })
  }
  return (
    <div className="info" id="id--info">
      {userData.map((user) => (
        <div className="info_container" key={user._id}>
          <div id="id--centerAvatar">
            <Avatar
              src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${user.avatar}`}
              size="supper-avatar"
            />
          </div>
          <div className="info_content">
            <div className="info_content_title">
              <div >
                <h2 id="id--fullName" >{user.fullname}</h2>
                {
                    (auth.user._id !== id && !challengerState.notFriends)
                    && <img
                        alt=''
                        style={{
                          position: "absolute",
                          right: "5%",
                          width: "auto", // Setting "auto" width to maintain aspect ratio
                          maxWidth: "2O%",
                        }}
                        src={message}
                        className="challenge_btn"
                        onClick={()=>{
                          history.push(`/message/${user._id}`)
                        }}
                    />
                }
                {
                  (auth.user._id !== id && !challengerState.notFriends)
                    && <img
                      alt=''
                      style={{
                        position: "absolute",
                        left: "5%",
                        width: "initial",
                        filter: challengerState.areChallenging ? "initial" : "brightness(0)",
                      }}
                      src={challenges}
                      className="challenge_btn"
                      onClick={handleChallengeRequest}
                    />
                }
                {(auth.user._id !== id) ?
                  (auth.user.friends.some(friend => friend._id === userData[0]._id) ?
                    (<h4>friend</h4>) :
                    (<InvitationBtn user={userData[0]} />)) : (null)}

              </div>

              {/*  {user._id === auth.user._id ? (
                <button
                  className="btn btn-outline-info"
                  onClick={() => setOnEdit(true)}
                >
                  Edit Profile
                </button>
              ) : (
                <FollowBtn user={user} />
              )} */}
            </div>
            {/* <div className="follow_btn">
              <span className="mr-4" onClick={() => setShowFollowers(true)}>
                {user.followers.length} Followers
              </span>
              <span className="ml-4" onClick={() => setShowFollowing(true)}>
                {user.following.length} Following
              </span>
            </div> */}
            {/* <h6>
              {user.username} <span className="text-danger">{user.mobile}</span>
            </h6>
            <p className="m-0">{user.address}</p>
            <h6 className="m-0">{user.email}</h6>
            <a href={user.website} target="_blank" rel="noreferrer">
              {user.website}
            </a>
            <p>{user.story}</p> */}
          </div>
          {onEdit && <EditProfile setOnEdit={setOnEdit} />}
          {showFollowers && (
            <Followers
              users={user.followers}
              setShowFollowers={setShowFollowers}
            />
          )}
          {showFollowing && (
            <Following
              users={user.following}
              setShowFollowing={setShowFollowing}
            />
          )}
        </div>
      ))}

      {/* <StatusButton /> */}
    </div>
  );
};

export default Info;
