import { Stack, TextField, Divider } from '@mui/material'
import { React, useState } from 'react'


export default function CreateServiceForm(props) {

    const { handleClose, addService } = props
    // Controlled form data
    const [formData, setFormData] = useState({
        serviceName: "",
        serviceDesc: ""
    })

    // True if the form is sumbitted, meaning that empty fields will not
    // show errors unless the user tried to create a page.
    const [isTouched, setIsTouched] = useState(false)

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleServiceCreate = () => {
        if (!isTouched)
            setIsTouched(true)
        if (!formData.serviceName || !formData.serviceDesc)
            return;
        addService(formData.serviceName, formData.serviceDesc)   
        handleClose()
    }

    return (
        <Stack sx={{ padding: 2 }} spacing={2}>
            <TextField
                name="serviceName"
                value={formData.serviceName}
                variant='filled'
                label='Service Name (required)'
                sx={{ width: "100%" }}
                onChange={handleChange}
                error={!formData.serviceName && isTouched}
                helperText={!formData.serviceName && isTouched ? 'This field is required' : null}
            />
            <TextField
                multiline
                rows={5}
                name="serviceDesc"
                value={formData.serviceDesc}
                variant='filled'
                label='Service Description (required)'
                sx={{ width: "100%" }}
                onChange={handleChange}
                error={!formData.serviceDesc && isTouched}
                helperText={!formData.serviceDesc && isTouched ? 'This field is required' : null}
            />

            <Divider />
            <div className="text-center " style={{ paddingTop: "16px" }}>
                <button type="button" className="btn btn-primary btn-lg" onClick={handleServiceCreate}>Create Service</button>
            </div>
        </Stack>
    )
}
