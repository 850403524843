import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PollLikeButton from "../../PollLikeButton";
import PollDislikeButton from "../../PollDislikeButton";
import { useSelector, useDispatch } from "react-redux";
import {likePost, unLikePost} from "../../../redux/actions/postAction";


const PollCardFooter = ({ poll,post, postId }) => {

  const [loadLike, setLoadLike] = useState(false);

  const [loadDislike, setLoadDislike] = useState(false);

  const [isLike, setIsLike] = useState(false);

  const [isDislike, setIsDislike] = useState(false);

  const { auth, theme, socket } = useSelector((state) => state);
  const dispatch = useDispatch();





  // Dislikes
  useEffect(() => {
    if (poll.post1[0]._id === postId) {
      if (
        poll.post1[0].dislikes.find((dislike) => dislike._id === auth.user._id)
      ) {
        setIsDislike(true);
      } else {
        setIsDislike(false);
      }
    } else if (poll.post2[0]._id === postId) {
      if (
        poll.post2[0].dislikes.find((dislike) => dislike._id === auth.user._id)
      ) {
        setIsDislike(true);
      } else {
        setIsDislike(false);
      }
    }
  }, [poll, postId, auth.user._id]);

  useEffect(()=>{

   // setIsLike(((post.likes).includes(auth.user._id)))


  },[poll,post])


  return (
      <>
      {post &&(
    <div className="card_footer">
      <div className="card_icon_menu">
            <PollLikeButton
              post={post}
              poll={poll}
            />

            <PollDislikeButton
              poll={poll}
              post={post}
            />

          <Link to={`/poll/${poll._id}`} className="text-dark">
            <i className="far fa-comment" data-number={post.comments.length} />
          </Link>
      </div>

    </div>)}
          </>

  );
};

export default PollCardFooter;
