import React, { useState } from 'react'
import PageLink from './PageLink'
import axios from 'axios'
import { useSelector } from 'react-redux'

export default function PageSocial({ websites, owner }) {

    const [links, setLinks] = useState(websites)
    const { auth } = useSelector(state => state)
    const pageName = window.location.pathname.split('/').at(-1)

    const handleSave = async (link, adress) => {
        setLinks({ ...links, [link]: adress })
        await axios.patch(
            `/api/page/${pageName}/websites`,
            { ...links, [link]: adress },
            { headers: { Authorization: auth.token } }
        )
    }
    return (
        <>
            {Object.entries(links).map(([link, adress]) => <PageLink link={link} adress={adress} key={link} handleSave={handleSave} />)}
        </>
    )
}