import { Stack, Typography } from "@mui/material";
import { React, useState } from "react";
import AboutPage from "./AboutPage";

export default function PageMain(props) {
    const [section, setSection] = useState("section1")

    const handleClick = e => setSection(e.target.id)

    return (
        <>
            <Stack direction="row"
                sx={{
                    width: "50%",
                    margin: "0 auto",
                    justifyContent: "space-around",
                    minWidth: "350px",
                    backgroundColor: "white",
                    padding: "16px"
                }}>
                <Typography id="section1" onClick={handleClick}
                    sx={{
                        fontFamily: "Century gothic",
                        cursor: "pointer",
                        fontWeight: section === "section1" ? "bold" : ""
                    }}>
                    Publications
                </Typography>
                <Typography id="section2" onClick={handleClick}
                    sx={{
                        fontFamily: "Century gothic",
                        cursor: "pointer",
                        fontWeight: section === "section2" ? "bold" : ""
                    }}>
                    About
                </Typography>
                <Typography id="section3" onClick={handleClick}
                    sx={{
                        fontFamily: "Century gothic",
                        cursor: "pointer",
                        fontWeight: section === "section3" ? "bold" : ""
                    }}>
                    Shop
                </Typography>
            </Stack>
            {section === "section1" && "section1"}
            {section === "section2" && <AboutPage {...props}/>}
            {section === "section3" && "section3"}
        </>
    )
}