import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { register, loginFacebook, loginGoogle } from "../redux/actions/authAction";
import { facebook, google } from "../images";

const Register = ({toggleLoginComp}) => {
  const { auth, alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const initialState = {
    fullname: "",
    username: "",
    email: "",
    password: "",
    cf_password: "",
    gender: "male",
  };
  const [userData, setUserData] = useState(initialState);
  const { fullname, username, email, password, cf_password } = userData;

  const [typePass, setTypePass] = useState(false);
  const [typeCfPass, setTypeCfPass] = useState(false);

  useEffect(() => {
    if (auth.token && auth.token !== "") {
      history.push("/");
    }
  }, [auth.token, history]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register(userData));
  };
  const handleGoogle = (e) => {
    e.preventDefault();
    dispatch(loginGoogle());
  }

  const handleFacebook = (e) => {
    e.preventDefault();
    dispatch(loginFacebook());
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="registerbtn">
        <div className="login" onClick={toggleLoginComp}>Login</div>
        <div className="register" >Register</div>
      </div>
      <div className="auth_gf">
        <span>Sign Up With:</span>
        <div className="gf_container">
        <button onClick={handleGoogle} ><img src={google} alt="google-button" /></button>
          <button onClick={handleFacebook}><img src={facebook} alt="facebook-button" /></button>
        </div>

        <span>Or:</span>
      </div>
        <div className="form-group register">
          <input
            type="text"
            className="form-control"
            id="fullname"
            name="fullname"
            placeholder="Name"
            onChange={handleChangeInput}
            value={fullname}
            style={{ background: `${alert.fullname ? "#fd2d6a14" : ""}` }}
          />

          <small className="form-text text-danger">
            {alert.fullname ? alert.fullname : ""}
          </small>
        </div>

        <div className="form-group register">

          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="UserName"
            name="username"
            onChange={handleChangeInput}
            value={username.toLowerCase().replace(/ /g, "")}
            style={{ background: `${alert.username ? "#fd2d6a14" : ""}` }}
          />

          <small className="form-text text-danger">
            {alert.username ? alert.username : ""}
          </small>
        </div>

        <div className="form-group register">
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            name="email"
            placeholder="Email"
            onChange={handleChangeInput}
            value={email}
            style={{ background: `${alert.email ? "#fd2d6a14" : ""}` }}
          />

          <small className="form-text text-danger">
            {alert.email ? alert.email : ""}
          </small>
        </div>

        <div className="form-group register">

          <div className="pass">
            <input
              type={typePass ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword1"
              onChange={handleChangeInput}
              value={password}
              placeholder="Password"
              name="password"
              style={{ background: `${alert.password ? "#fd2d6a14" : ""}` }}
            />

            
          </div>

          <small className="form-text text-danger">
            {alert.password ? alert.password : ""}
          </small>
        </div>

        <div className="form-group register">
          <div className="pass">
            <input
              type={typeCfPass ? "text" : "password"}
              className="form-control"
              id="cf_password"
              placeholder="Confirm Password"
              onChange={handleChangeInput}
              value={cf_password}
              name="cf_password"
              style={{ background: `${alert.cf_password ? "#fd2d6a14" : ""}` }}
            />

            
          </div>

          <small className="form-text text-danger">
            {alert.cf_password ? alert.cf_password : ""}
          </small>
        </div>
        <label class="terms-checkbox">
          I Have read and Agree to the terms
          <input type="checkbox" />
          <span class="checkmark"></span>
        </label>
        
        
        <button
        type="submit"
        className="btn_login login"
      >
        SIGN UP
      </button>
      </form>
  );
};

export default Register;
