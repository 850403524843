import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

export default function PageCover(props) {
    const { coverImage, pageImage } = props
    const { id } = useParams()
    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="flex-end" 
            sx={{
                background: coverImage ? `url(${coverImage})` : "linear-gradient(to bottom ,#ccc, #fff)",
                height: "250px",
                borderBottom: "2px solid #ccc"
            }} >
            <Avatar
                src={pageImage}
                sx={{ 
                    width: 'clamp(100px, 15%, 150px)',
                    height: 'initial', 
                    aspectRatio: '1/1', 
                    objectFit: 'contain', 
                }}
            />
            <Typography variant="h4" padding='16px'>
                {id}
            </Typography>
        </Stack>
    )
}