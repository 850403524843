import React, { useState, useEffect } from "react";

const Countdown = ({ poll }) => {

  const calculateTimeLeft = () => {

    const difference = +new Date(poll.finishDate) - +new Date();
    if (difference > 0)
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
        minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
        seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0'),
      };
      
    return 0

  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  /* if(timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds ===0){
    poll = {...poll, finishDate : "finished"};
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: poll });
    setShow(false);
  }

  if(poll.finishDate =="finished"){
    setShow(false);
  } */

  return (
    timeLeft ?
      <div style={{ fontSize: "24px", textAlign: "right" }}>
        <strong>{days}</strong>D&nbsp;
        <strong>{hours}</strong>H&nbsp;
        <strong>{minutes}</strong>M&nbsp;
        <strong>{seconds}</strong>S&nbsp;
      </div>
      : null
  );
};

export default Countdown;
