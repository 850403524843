import React, { useState } from 'react'
import { Box, Rating, Typography } from '@mui/material';
import AddAndEdit from './AddAndEdit';
import CustomModal from './CustomModal';
import AddReviewForm from './AddReviewForm';

export default function PageReviews() {

    const [review, setReview] = useState({})
    const [isReviewed, setIsReviewed] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);
    const handleSave = (rating, comment) => {
        setReview({ rating, comment })
        setIsReviewed(true)
    }

    return (
        <>
            <AddAndEdit edit={isReviewed} handleOpen={handleOpen} />

            {isReviewed && <Box>
                <Rating
                    name="size-large"
                    readOnly
                    size="large"
                    value={review.rating}
                    sx={{ padding: "8px", fontSize: "40px", '& label': { display: "inline" } }}
                />
                <Typography
                    sx={{
                        fontSize: "24px",
                        padding: "14px",

                    }}
                >
                    {review.comment}
                </Typography>
            </Box>}

            <CustomModal
                open={open}
                handleClose={handleClose}
                name={isReviewed ? "Edit Review" : "Add review"}
            >
                <AddReviewForm
                    handleClose={handleClose}
                    handleSave={handleSave}
                />
            </CustomModal>
        </>
    )
}