import React, { useState, useEffect, useRef } from "react";
import UserCard from "../UserCard";
import { useSelector, useDispatch } from "react-redux";
import { getDataAPI } from "../../utils/fetchData";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import { useHistory, useParams } from "react-router-dom";
import Badge from '@mui/material/Badge';

import {
  MESS_TYPES,
  getConversations, ReadMessage,
} from "../../redux/actions/messageAction";
import {unLikePost} from "../../redux/actions/postPollAction";
import ListeElement from "./ListeElement";
import notify from "../../redux/reducers/notifyReducer";

const LeftSide = () => {
  const { auth, message, online } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchUsers, setSearchUsers] = useState([]);
  const [FiltredUser, setFiltredUser] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const pageEnd = useRef();
  const [page, setPage] = useState(0);



  useEffect(async () => {
    //const handleSearch = async (e) => {
    //e.preventDefault();
    //if (!search) return setSearchUsers([]);
//console.log(auth)
    try {
      const res = await getDataAPI(`getUserAndMessage/${auth.user._id}`, auth.token);
  
      setSearchUsers(res.data.resultMap);
      setFiltredUser(res.data.resultMap)
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {error: err.response.data.msg},
      });
    }
  },[])

  const handleAddUser = (user) => {
    setSearch("");
    setSearchUsers([]);
    dispatch({
      type: MESS_TYPES.ADD_USER,
      payload: { ...user, text: "", media: [] },
    });
    dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online });
    return history.push(`/message/${user._id}`);
  };

  const isActive = (user) => {
    if (id === user._id) return "active";
    return "";
  };

  useEffect(() => {
    if (message.firstLoad) return;
    dispatch(getConversations({ auth }));
  }, [dispatch, auth, message.firstLoad]);

  // Load More
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setPage((p) => p + 1);
        }
      },
      {
        threshold: 0.1,
      }
    );

    observer.observe(pageEnd.current);
  }, [setPage]);

  useEffect(() => {
    if (message.resultUsers >= (page - 1) * 9 && page > 1) {
      dispatch(getConversations({ auth, page }));
    }
  }, [message.resultUsers, page, auth, dispatch]);

  // Check User Online - Offline
  useEffect(() => {
    if (message.firstLoad) {
      dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online });
    }
  }, [online, message.firstLoad, dispatch]);
  const  handleViewMessage=async (user) => {
    if(user.messageNoViewed>0) {
      await dispatch(ReadMessage({user,auth}));
      user.messageNoViewed=0

    }
  }
  const handleSearch = (e) => {
    setSearch(e.target.value);
  
    const filteredUsers =searchUsers .filter((user) =>
        user._doc.fullname.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFiltredUser(filteredUsers);
  };

  return (
    <>
      {/*<form className="message_header" onSubmit={handleSearch}>*/}

      <input   value={search} type="text" className="form-control" placeholder="Enter to Search..."
               onChange={handleSearch}
      />

      <button type="submit" style={{ display: "none" }}>
          Search
        </button>

      <div className="message_chat_list">
          <>
            {FiltredUser.map((user) => (
                <>
               < ListeElement user={user} />
                </>
            ))}
          </>



        <button ref={pageEnd} style={{ opacity: 0 }}>
          Load More
        </button>
      </div>
    </>
  );
};

export default LeftSide;
