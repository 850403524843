import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const PostTypes = () => {
  const Post_Types = [
    { name: "FASHION", type: "fashion" },
    { name: "BEAUTY", type: "beauty" },
    { name: "PHOTOGRAPHY", type: "photography" },
    { name: "FOOD", type: "food" },
    { name: "GADGETS AND TECHNOLOGY", type: "gadgets_and_technology" },
    { name: "DIY Crafts", type: "diy_crafts" },
    { name: "MUSIC AND INSTRUMENTS", type: "music_and_instruments" },
    { name: "ART", type: "art" },
    { name: "HEALTH & FITNESS", type: "health_and_fitness" },
    { name: "SPORTS", type: "sports" },
    { name: "DIET & WEIGHT LOSS", type: "diet_&_weight_loss" },
    { name: "SELF-MOTIVATION", type: "self-motivation" },
    { name: "ENTREPRENEURSHIP", type: "entrepreneurship" },
    { name: "BEVERAGE", type: "beverage" },
    { name: "FOREIGN LANGUAGES", type: "foreign_languages" },
    { name: "INTERIOR DESIGN", type: "interior_design" },
    { name: "GARDENING", type: "gardening" },
    { name: "FARMING", type: "farming" },
    { name: "JOB AND RESUME", type: "job_and_resume" },
    { name: "DESIGN & DEVELOPMENT", type: "design_and_development" },
    { name: "LIFESTYLE", type: "lifestyle" },
    { name: "BOOKS & LITERATURE", type: "books_and_literature" },
    { name: "CARS & MOTO", type: "cars_&_moto" },
  ];
  const [showList, setShowList] = useState(false);
  function handleTitleClick() {
    setShowList(!showList);
  }
  return (
    <div>
      <h6 className="PostTypes--h666" onClick={handleTitleClick}>
        Intrested In
      </h6>
      {showList && (
        <ul>
          {Post_Types.map((category, index) => (
            <li className="PostTypes--liii" key={index}>
              <Link to={`/?postType=${category.type}`}>{category.name}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default PostTypes;
