import { Divider, Rating, Stack, TextareaAutosize } from '@mui/material'
import React, { useState } from 'react'

export default function AddReviewForm(props) {

    const { handleClose, handleSave } = props
    const [rating, setRating] = useState(0)
    const [value, setValue] = useState("")
    const [comment, setComment] = useState("")
    const [isReviewed, setIsReviewed] = useState(false)


    const labels = {
        0.5: 'Useless',
        1: 'Useless+',
        1.5: 'Poor',
        2: 'Poor+',
        2.5: 'Ok',
        3: 'Ok+',
        3.5: 'Good',
        4: 'Good+',
        4.5: 'Excellent',
        5: 'Excellent+',
    };

    return (
        <>
            <Stack direction="row" alignItems="center">
                <Rating
                    name="size-large"
                    size="large"
                    value={rating}
                    precision={.5}
                    onChange={(_, newVal) => { setRating(newVal); setValue(labels[newVal]) }}
                    onChangeActive={
                        (_, newVal) => newVal !== -1
                            ? setValue(labels[newVal])
                            : rating
                                ? setValue(labels[rating])
                                : setValue("")
                    }
                    sx={{ padding: "8px", fontSize: "40px", '& label': { display: "inline" } }}
                />
                <div>
                    {value}
                </div>
            </Stack>
            <Divider />
            <TextareaAutosize
                style={{ padding: "16px", margin: "0 auto" }}
                minRows={3}
                maxRows={6}
                value={comment}
                onChange={e => setComment(e.target.value)}
            />
            <Divider />
            <div className="text-center " style={{ paddingTop: "16px" }}>
                <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    onClick={() => {
                        handleSave(rating, comment);
                        setIsReviewed(true);
                        handleClose()
                    }}>
                    {isReviewed ? "Edit Review" : "Add Review"}
                </button>
            </div>
        </>
    )
}