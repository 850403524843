import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dislikePost, unLikePost} from "../redux/actions/postAction";
import {unDislikePost} from "../redux/actions/postAction";

const DislikeButton = ({post}) => {
  const { auth, theme, socket } = useSelector((state) => state);



  useEffect(()=>{
    setIsDisLike(((post.dislikes).includes(auth.user._id)))

  },[post.dislikes.length,post.likes.length])

  const dispatch = useDispatch();

  const [isDisLike, setIsDisLike] = useState(((post.dislikes).includes(auth.user._id)));

  const handleDislike = async () => {
    //   if (loadDislike) return;
    if(!isDisLike){
      if((post.likes).includes(auth.user._id)){
        await dispatch(unLikePost({ post,postId:post._id, auth, socket }));

      }
      setIsDisLike(true);
      try {
        await dispatch(dislikePost({ post, postId:post._id, auth, socket }));
      } catch (error) {
      }
    }
  };

  const handleUnDislike = async () => {
    setIsDisLike(false);

    //setLoadDislike(true);
    await dispatch(unDislikePost({post, postId:post._id, auth, socket }));
    //setLoadDislike(false);
  };
  return (
    <>
      {isDisLike ? (

<div className="like-button">
<div className="like-icon">
        <i
          className="fas fa-thumbs-down text-danger"
          onClick={handleUnDislike}
          style={{ filter: theme ? "invert(1)" : "invert(0)" }}
          data-number={post.dislikes.length}
        />
        </div>

<div className="like-buttonPoll text-danger ">{post.dislikes.length}</div>

        </div>
      ) : (
        <div className="like-button">
<div className="like-icon">
        <i
          className="far fa-thumbs-down"
          onClick={handleDislike}
          data-number={post.dislikes.length}

          
        />
         </div>

<div className="like-buttonPoll ">{post.dislikes.length}</div>

        </div>
      )}
    </>
  );
};

export default DislikeButton;
