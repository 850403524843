import React, {Fragment, useEffect, useState} from "react";
import "./ChallengesSection.css";
import myArena from "../../images/myArena.png";
import {getDataAPI} from "../../utils/fetchData";
import {useDispatch, useSelector} from "react-redux";
import {useParams,useHistory} from "react-router-dom";
import PollCard from "../../components/PollCard";
import ney from "../../images/cross.png";
import yey from "../../images/king.png";
import equal2 from "../../images/equal2.png";
import my_Adds from "../../components/home/my_Adds.png";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";
import PollModal from "../../components/PollModal";
import { Dialog } from "@mui/material";
import ChallengeCard from "../../components/ChallengeCard";

const ChallengesSection = () => {

    const dispatch = useDispatch();

    const [FinishedChallenge, setFinishedChallenge] = useState([]);
    const [ImageListe, setImageListe] = useState([]);
    const [RunningChallenge, setRunningChallenge] = useState([]);
    const { homePosts,page, theme } = useSelector((state) => state);

    const { auth, poll } = useSelector((state) => state);
    const { id } = useParams()
    const history = useHistory();

    // Dialog open state and src state
    const [open, setOpen] = useState(false)
    const [dialogImageSrc, SetDialogImageSrc] = useState()

    // Get whether visited profile is friend or not
    const isFriend = auth.user.friends.some(friend => friend._id === id)

    // Dialog controller
    const showChallange = (id) => {
        history.push("/ViewChallenge/"+id)
        //SetDialogImageSrc(`${process.env.REACT_APP_PUBLIC_FOLDER}/${src}`)
        //setOpen(true)
    }

    useEffect(async () => {
        try {
            const result = await getDataAPI(`getUserChallange/${id}`, auth.token);

            const filtredFinishedChallenge = result.data.Challenge.filter((challenge) => {
                const endDate = new Date(challenge.finishDate);
                const today = new Date();

                return endDate < today;

            });



            const filtredNonFinishedChallenge = result.data.Challenge.filter((challenge) => {
                const endDate = new Date(challenge.finishDate);
                const today = new Date();

                return endDate > today;
            });



            setFinishedChallenge(filtredFinishedChallenge)
            setRunningChallenge(filtredNonFinishedChallenge)

        } catch (e) {

        }
    }, [auth.token]);




    useEffect(async () => {
        const updatedChallenges = FinishedChallenge.map(challenge => {
            const {post1} = challenge;




            const {post2} = challenge;


            const winnerId = (post1[0].likes.length-post1[0].dislikes.length) > (post2[0].likes.length -post2[0].dislikes.length) ? post1[0]._id :((post1[0].likes.length-post1[0].dislikes.length) == (post2[0].likes.length-post2[0].dislikes.length))? "equal":post2[0]._id;

            return { ...challenge, winner: [winnerId] };
        });
console.log(updatedChallenges)
        let ImagesListe=[]
        for (let i = 0; i < updatedChallenges.length; i++) {
console.log(updatedChallenges[i])
            // Get which post is posted by visited user
            // Only when visited user is a friend or the same as the visior
            const userPost = updatedChallenges[i].post1[0].user._id === id && (isFriend || id === auth.user._id)
                ? "post1"
                : updatedChallenges[i].post2[0].user._id === id && (isFriend || id === auth.user._id)
                    ? "post2"
                    : "none"



            // Only show images posted by user if exists
            if ( updatedChallenges[i].winner[0]=="equal" ){
                if (updatedChallenges[i].post1[0].user._id === id)
                    ImagesListe.push({
                        link : updatedChallenges[i].post1[0].images[0].filename,
                        result : "equals",challengeId:updatedChallenges[i]._id})
                else   
                                 ImagesListe.push({
                        link : updatedChallenges[i].post2[0].images[0].filename,
                        result : "equals",challengeId:updatedChallenges[i]._id})
            } else {


                console.log(updatedChallenges[i])
                console.log('herer')
                console.log(updatedChallenges[i].winner[0])
                console.log(updatedChallenges[i].post1[0]._id)
                if (updatedChallenges[i].winner.includes(updatedChallenges[i].post1[0]._id)){
                    console.log("iiiiiiiiiicccccccccc222222222ccciiiiiiiii")
                    console.log(userPost)

                    if (updatedChallenges[i].post1[0].user._id === id)
    
                    ImagesListe.push({
                            link : updatedChallenges[i].post1[0].images[0].filename
                            ,result : "winner",challengeId:updatedChallenges[i]._id})
                    else if (userPost === "post2" || userPost === "none")
                        ImagesListe.push({
                            link:updatedChallenges[i].post2[0].images[0].filename,
                            result : "loser",challengeId:updatedChallenges[i]._id})
                } else {
                    console.log("iiiiiiiiiiccccccccccccciiiiiiiii")
                    if (updatedChallenges[i].post1[0].user._id === id){
                        ImagesListe.push({
                            link : updatedChallenges[i].post1[0].images[0].filename,
                            result : "loser",challengeId:updatedChallenges[i]._id})
                        console.log("im gere") 
                        } else {
                            console.log("im gereszz") 

                                            ImagesListe.push({
                            link : updatedChallenges[i].post2[0].images[0].filename
                            ,result : "winner",challengeId:updatedChallenges[i]._id})
                }}

            }

        }
console.log(ImageListe)
        setImageListe(ImagesListe)
        //setFinishedChallenge(updatedChallenges)
    }, [FinishedChallenge]);

    useEffect(async () => {


    },[ImageListe])

    return (

        <div  style={{ display: 'flex' }}>
            <div style={{ backgroundColor: '#d5d5d5', flex: 1, marginRight: '10px',marginLeft:"10px",minHeight: '100%',borderRadius:"20px" }}>
                <div className="polls-container--YN">
                    <div className="polls--header--container">
                        <img src={yey} className="header--img--yey--ney" />
                        <img src={ney} className="header--img--yey--ney" />
                    </div>
                    {(ImageListe!=[]) && (
                        <Fragment>

                            {ImageListe.map((friend) => (
                                <div className="pollscard--YN" key={friend}>

                                    {friend.result === "winner" && (
                                        <div className="card--badge1">
                                            <img src={yey} className="polls--img--yey--ney" />
                                        </div>
                                    )}
                                    {friend.result === "loser" && (
                                        <div className="card--badge2">
                                            <img src={ney} className="polls--img--yey--ney" />
                                        </div>
                                    )}
                                    {friend.result === "equals" && (
                                        <div className="card--badge3">
                                            <img src={equal2} className="polls--img--yey--ney"
                                                // id="polls--img--yey--ney--equal2"
                                            />
                                        </div>
                                    )}


                                    <img onClick={() => showChallange(friend.challengeId)}
                                         src={process.env.REACT_APP_PUBLIC_FOLDER+"/"+friend.link.filename}
                                         alt=""
                                         style={{ cursor: "pointer" }}
                                         className="polls--img--polls"
                                    />
                                </div>
                            ))}
                        </Fragment>)}
                </div>


            </div>
            <div style={{ backgroundColor: '#d5d5d5', flex: 1 ,marginRight:"10px",minHeight: '300px',borderRadius:"20px"}}>
                <img src={myArena} className="challengesDiv--arenaImage" />
                <div className="challengesDiv rightChallenges d-flex flex-column" >
                    <div className="challengesDiv--header">
                        {(RunningChallenge!= []) && (
                            <div className="posts">
                                {RunningChallenge.map((poll) => (
                                    <ChallengeCard
                                        key={poll._id}
                                        challenge={poll}
                                        theme={theme}
                                        isPicks={true}
                                    />
                                )) }
                            </div>
                        )}
                    </div>
                </div>

            </div>
            {/*<Dialog */}
            {/*    open={open} */}
            {/*    onClose={() => setOpen(false)}*/}
            {/*    sx={{"& .MuiDialog-container": {*/}
            {/*        "& .MuiPaper-root": {*/}
            {/*          minWidth: "450px", */}
            {/*        },*/}
            {/*      }*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <img */}
            {/*    src={dialogImageSrc}*/}
            {/*    alt=''*/}
            {/*  />*/}
            {/*</Dialog>*/}
        </div>


        /*

       <Fragment>
         <div className="challengesDiv leftChallenges">Zone Pub</div>
         <div className="challengesDiv centerChallenges"></div>
         <div className="challengesDiv rightChallenges">
           <div className="challengesDiv--header">
               {(Challenge!=null) && (
                   <div className="posts">
                       {Challenge.map((poll) => (
                           <PollCard
                               key={poll._id}
                               poll={poll}
                               theme={theme}
                               isPicks={true}
                           />
                       )) }
                   </div>
               )}
           </div>
         </div>
       </Fragment>*/
    );
};
export default ChallengesSection;
