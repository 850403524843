import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import king from "../images/king.png";
import yey from "../images/yey.png";
import ney from "../images/ney.png";
import cross from "../images/cross.png";
import equal2 from "../images/equal2.png";
import "../pages/profile/PollsSection.css";
import audiothumbnail from '../utils/audiothumbnail.png'
import { Dialog } from "@mui/material";
const Carousel = ({ images, id, type,post, poll }) => {

  const [open, setOpen] = useState(false)
  const [statu, setStatu] = useState("")

  const [dialogImageSrc, SetDialogImageSrc] = useState()

  const handleDialogOpen = src => {
    SetDialogImageSrc(`${process.env.REACT_APP_PUBLIC_FOLDER}/${src}`)
    setOpen(true)
  }
  useEffect(()=>{

    if(poll){
    if(new Date(poll.finishDate)>new Date()){
      setStatu("NotFinished")
    }
    else {
      console.log(poll.post1[0])
      const winnerId = (poll.post1[0].likes.length - poll.post1[0].dislikes.length) > (poll.post2[0].likes.length - poll.post2[0].dislikes.length) ? poll.post1[0]._id : ((poll.post1[0].likes.length - poll.post1[0].dislikes.length) == (poll.post2[0].likes.length - poll.post2[0].dislikes.length)) ? "equal" : poll.post2[0]._id;
      if (winnerId == post._id)
        setStatu("win")

      else if (winnerId == "equal")
        setStatu("equal")
      else
        setStatu("lose")


    }}},[poll])





  let winOrlose = true;
  let equal;
  if (poll) {


    winOrlose = id === poll.winner[0];
    if (poll.winner[0]) {
      equal = false;
    } else {
      equal = true;
    }
  }
  const isActive = (index) => {
    if (index === 0) return "active";
  };
  const { theme } = useSelector((state) => state);

  return (
    <div id={`image${id}`} className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators" style={{ zIndex: 1 }}>
        {images.length > 1 && images.map((img, index) => (
          <li
            key={index}
            data-target={`#image${id}`}
            data-slide-to={index}
            className={isActive(index)}
          />
        ))}
      </ol>
      <div className="carousel-inner">
        {images.map((img, index) => (

          <div
            key={index}
            className={`carousel-item ${isActive(index)}`}

            style={{ 
              maxHeight: "80vh"
             }}
          >

            {poll &&( statu=='win') &&((type=="poll")) &&  (

              <div className="card--badge1">
                <img
                  src={yey}
                  className="polls--img--yey--ney"
                  id="poll--card--idBadge1"
                />
              </div>
            )}
            {poll &&( statu=='win') &&((type=="challenge")) &&  (

<div className="card--badge1">
  <img
    src={king}
    className="polls--img--yey--ney"
    id="poll--card--idBadge1"
  />
</div>
)}
            {poll &&( statu === "equal")  && (

                <img
                  src={equal2}
                  className="polls--img--yey--ney"
                  id="poll--card--idBadge3"
                />
            )}
            {poll && (statu === "lose")&&((type=="poll")) && (
              <div className="card--badge2">
                <img
                  src={ney}
                  className="polls--img--yey--ney"
                  id="poll--card--idBadge2"
                />
              </div>
            )}
             {poll && (statu === "lose") &&((type=="challenge")) &&(
              <div className="card--badge2">
                <img
                  src={cross}
                  className="polls--img--yey--ney"
                  id="poll--card--idBadge2"
                />
              </div>
            )}
            {img.filetype.filetype.startsWith("video/") ? (
              <video
                controls
                src={`/upload/${img.filename.filename}`}
                className="d-block w-100 sameImageSizePoll"
                alt={`${process.env.REACT_APP_PUBLIC_FOLDER}/${img}`}
              />
            ) : img.filetype.filetype.startsWith("audio/") ? (
              <div
                style={{
                  backgroundImage: "url(/yourock_audio.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
              >

                <audio

                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${img.filename.filename}`}
                  className="d-block w-100 sameImageSizePoll"
                  controls

                // alt={`${process.env.REACT_APP_PUBLIC_FOLDER}/${img}`}
                />
              </div>
            ) : (
                <img
                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${img.filename.filename}`}
                  className="d-block w-100 sameImageSizePoll"
                  alt={`${process.env.REACT_APP_PUBLIC_FOLDER}/${img}`}
                  onClick={() => handleDialogOpen(img.filename.filename)}
                  key={index}
                />
            )}
            <Dialog 
              open={open} 
              onClose={() => setOpen(false)}
              sx={{"& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      minWidth: "350px", 
                    },
                  }
                }}
            >
              <img 
                src={dialogImageSrc}
                alt=''
              />
            </Dialog>
          </div>
        ))}
      </div>
      {images.length > 1 && (
        <>
          <a
            className="carousel-control-prev"
            href={`#image${id}`}
            role="button"
            data-slide="prev"
            style={{ width: "5%" }}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href={`#image${id}`}
            role="button"
            data-slide="next"
            style={{ width: "5%" }}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </>
      )}

    </div>
  );
};
export default Carousel;