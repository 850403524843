import React, {useEffect, useState} from "react";
import CardHeader from "./home/post_card/CardHeader";
import CardBody from "./home/post_card/CardBody";
import CardFooter from "./home/post_card/CardFooter";
import Comments from "./home/Comments";
import InputComment from "./home/InputComment";
import {useDispatch, useSelector} from "react-redux";
import {GLOBALTYPES} from "../redux/actions/globalTypes";


const PostCard = ({ post, theme }) => {

    const { auth, page } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [Post, setPost] = useState(post);
    const changeVariable=async () => {

        if ((page.ElemntChanged != null) && (post._id == page.ElemntChanged._id)) {
            await setPost(page.ElemntChanged)
        }}

  return (
    <div className="card my-3">
      <CardHeader post={post} />
      <CardBody post={post} theme={theme} />
      <CardFooter post={post} />
      <Comments post={post} />
      <InputComment post={post} />
    </div>
  );
};

export default PostCard;
