import React, { useState, useEffect } from "react";
import { getDataAPI, patchDataAPI } from '../utils/fetchData'
import { useSelector, useDispatch } from "react-redux";
import { acceptChallenge } from "../redux/actions/challengeAction";
import moment from 'moment';
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import invitations from "../pages/friendsManagement/Invitations";

const ChallengeRes = () => {
  const { profile, auth, socket ,page} = useSelector((state) => state);
  const [challenges, setChallenges] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [DataLoaded, setDataLoaded] = useState(false);
  const [on_going_challenge, setOn_going_challenge] = useState(null);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
const reloadData= async () => {
  setChallenges([])
  let res;
    res = await getDataAPI(`user/${auth.user._id}/challengerequests`, auth.token);
  setChallenges(res.data.user.challengeRequests);
  setOn_going_challenge(res.data.user.on_going_challenge);
  setOn_going_challenge(res.data.user.on_going_challenge);
  setDataLoaded(true)


}
  useEffect(async () => {

    await reloadData()

  }, [auth,page.RealoadData]);
useEffect(async () => {

  challenges.map((invitation) => {
  })
  }, [challenges]);



  const handleAcceptChallenge = async (invitation) => {
    setLoading(false)
    if (load) return;

    setLoad(true);
    await dispatch(acceptChallenge({ user: invitation.userCreator, users: profile.users, subject: invitation.subject, challenge_id: invitation._id, auth, socket }));
    setLoad(false);
    setLoading(true)

    reloadData()
  };


  const handleRefuseChallenge = async (invitation) => {
    if (load) return;

    setLoad(true);
    const res = await patchDataAPI(`challenge/${invitation._id}/refuse`, null, auth.token);
    setLoad(false);
    reloadData()
  };

  const handleUploadMedia = async (invitation) => {
    if (load) return;
    setLoad(true);
    dispatch({ type: GLOBALTYPES.UPLOADMEDIA, payload: invitation });
    setLoad(false);
  //  GLOBALTYPES.REALOADDATA

  };

  return (

    <div>
      { DataLoaded && (
      <div className="item-container--YN">
        {challenges.map((invitation) => (


            <>
            {(((auth.user._id==invitation.userCreator._id)&&(invitation.status=="pending"))||(invitation.status=="refuse")||(invitation.status=="finished"))?null:


          <div className="card--YN" key={invitation._id}>
            <div className="div--img--YN">
              <img
                src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${invitation.userCreator.avatar}`}
                alt=""
                className="FeaturedFriendsImg--YN"
                id="Suggestions--picture--YN"
              />
            </div>
            <div className="div--text--YN">

              <h3 id="h3--username">{invitation.userCreator.fullname}</h3>
              <h6
              >Subject : {invitation.subject}</h6>
              <h6 >From : {moment(invitation.beginDate).format("YYYY-MM-DD HH:mm")}</h6>
              <h6>To : {moment(invitation.finishDate).format("YYYY-MM-DD HH:mm")}</h6>
              {["finished", "refused", "pending"].includes(invitation.post2.status)}
              {
                  (! (["finished", "refuse", "pending"].includes(invitation.status)) )? (((
                  auth.user._id == invitation.userCreator._id) && (invitation.post1.length == 0)) ? (
                      <>

                    <button
                       onClick={() => handleUploadMedia(invitation)} 
                      className="Suggestions--Button--YN"
                      id="button-7"
                    >
                      Upload Media
                    </button>
                      </>
                  ) : ((auth.user._id == invitation.userinvited._id )&&(invitation.post2.length == 0 ))? (
                    <button
                       onClick={() => handleUploadMedia(invitation)} 
                      className="Suggestions--Button--YN"
                      id="button-7"
                    >
                      Upload Media
                    </button>
                  ) : (auth.user._id == invitation.userCreator._id && invitation.post1.length != 0 )? (
                    <h4>Media Uploaded already</h4>
                  ) : (auth.user._id ==invitation.userinvited._id && invitation.post2.length != 0) ? (
                    <h4>Media Uploaded already</h4>
                  ) : null
                ) : (((invitation.status=="pending"))?(
                  <>
                    { Loading &&           (
                        <>
                        <button
                       onClick={() =>{


                           handleAcceptChallenge(invitation)} }
                      className="Suggestions--Button--YN"
                      id="button-7"
                    >
                      Accept
                    </button>
                    <button
                       onClick={() =>
                       {

                           handleRefuseChallenge(invitation)} }
                      className="Suggestions--Button--YN"
                      id="button-8"
                    >
                      Refuse
                    </button>
                        </>)}
                  </>
                ):null)
              }
            </div>
          </div>}


            </>


        ))}
      </div>

        )}
    </div>
  )
}

export default ChallengeRes