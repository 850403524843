import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions/authAction";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import Avatar from "../Avatar";
import NotifyModal from "../NotifyModal";
import CustomModal from "../userpage/CustomModal";
import CreatePageForm from "../userpage/CreatePageForm";
import Badge from '@mui/material/Badge';
import {getDataAPI} from "../../utils/fetchData";
import {NOTIFY_TYPES} from "../../redux/actions/notifyAction";


const Menu = () => {

  // CreatePageModal states and listeners
  const [open, setOpen] = useState(false);
  const [NotReadedMessage, setNotReadedMessage] = useState(0);
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false);

  const { auth, page,theme, notify } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

    useEffect(() => {
        if(notify.AddMessage){
            setNotReadedMessage(NotReadedMessage+1)
            dispatch({
                type:NOTIFY_TYPES.ADDMESSAGE
            });
        }

            }, [notify.AddMessage]);




 const UpdateNotificationNumber =async () => {
    const res = await getDataAPI(`getNotReaded/${auth.user._id}`, auth.token);
   
    setNotReadedMessage(res.data.result)

  };


  useEffect(async () => {
    UpdateNotificationNumber()
  },[auth])
  useEffect(async () => {
    if(page.NotificationNumberUpdate){

      UpdateNotificationNumber()
      dispatch({
        type:GLOBALTYPES.CHANGENOTIFICATIONNUMBER
      });
    }
  },[page.NotificationNumberUpdate])




  const navLinks = [
    { label: "Home", icon: "home", path: "/" },
    { label: "Message", icon: "message", path: "/message" },
    {
      label: "FriendsManagement",
      icon: "groups",
      path: `/friendsManagement/${auth.user._id}`,
    },
    { label: "Discover", icon: "local_fire_department", path: "/discover" },
    { label: "Challenge", icon: "swords", path: "/challenge" },
    { label: "Savedd", icon: "bookmark", path: `/savedd/${auth.user._id}` },
  ];
  const isActive = (pn) => {
    if (pn === pathname) return "active";
  };
  return (
    <div className="menu">
      <ul className="navbar-nav flex-row">
        {navLinks.map((link, index) => (
          <li className={`nav-item px-2 ${isActive(link.path)}`} key={index}>
            <Link className="nav-link" to={link.path}>

              {link.label==("Message") ?
                  <Badge badgeContent={NotReadedMessage} color="primary">

              <span className="material-icons material-symbols-outlined">
                {link.icon}
              </span>
              </Badge>:
                  <span className="material-icons material-symbols-outlined">
                {link.icon}
              </span>}
            </Link>
          </li>
        ))}
        <li className="nav-item dropdown" style={{ opacity: 1 }}>
          <span
            className="nav-link position-relative"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span
              className="material-icons"
              style={{
                color: notify.data.filter(notif => !notif.isRead).length > 0
                  ? "crimson"
                  : ""
              }}
            >
              notifications
            </span>
            <span className="notify_length">
              {notify.data.filter(notif => !notif.isRead).length}
            </span>
          </span>
          <div
            className="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style={{ transform: "translateX(75px)" }}
          >
            <NotifyModal />
          </div>
        </li>
        <li className="nav-item dropdown" style={{ opacity: 1 }}>
          <span
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <Avatar
              src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${auth.user.avatar}`}
              size="medium-avatar"
            />
          </span>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to={`/profile/${auth.user._id}`}>
              Profile
            </Link>
            <Link className="dropdown-item" to={`/settings/${auth.user._id}`}>
              Settings
            </Link>
            <button className="dropdown-item" onClick={handleOpen}>
              Create Page
            </button>
            <label
              htmlFor="theme"
              className="dropdown-item"
              onClick={() =>
                dispatch({
                  type: GLOBALTYPES.THEME,
                  payload: !theme,
                })
              }
            >
              {theme ? "Light mode" : "Dark mode"}
            </label>
            <div className="dropdown-divider"></div>
            <Link
              className="dropdown-item"
              to="/"
              onClick={() => dispatch(logout())}
            >
              Logout
            </Link>
          </div>
        </li>
      </ul>
      <CustomModal
        open={open}
        handleClose={handleClose}
        name="Create Page"
      >
        <CreatePageForm />
      </CustomModal>
    </div>
  );
};

export default Menu;
