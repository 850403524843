import React, { useState } from 'react'

export default function PageLink(props) {
    const { link, adress, handleSave } = props

    const [isEditing, setIsEditing] = useState(false)

    // Show only username if social media
    // Show entire url if website link
    const [input, setInput] = useState(
        link !== "website"
            ? adress.replace(/^.*[/@]/, "")
            : adress
    )

    const domainName = link === "facebook" ? "https://www.facebook.com/"
        : link === "tiktok" ? "https://www.tiktok.com/@"
            : link === "linkedin" ? "https://www.linkedin.com/"
                : link === "instagram" ? "https://www.instagram.com/" :
                    link === "youtube" ? "https://www.youtube.com/@"
                        : ""

    const handleInput = e => setInput(e.target.value)

    const saveLink = () => {
        handleSave(link, domainName + input)
        setIsEditing(false)
    }

    return (
        <>
            <div style={{ padding: "10px 0", fontSize: "18px" }}>
                <img
                    src={require(`./icons/${link}.svg`).default}
                    alt={link}
                    style={{ height: "64px", width: "64px", padding: "6px" }}
                />
                {adress && !isEditing ?
                    <>
                        <a href={adress} target="_blank" rel="noreferrer">{adress}</a>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setIsEditing(true)}
                            style={{ margin: "8px" }}
                        >Edit</button>
                    </>
                    :
                    isEditing ?
                        <span style={{ display: "inline-flex", gap: "10px", alignItems: "center" }}>
                            <span
                                style={{
                                    backgroundColor: "#ddd",
                                    padding: "5px", borderRadius: "5px"
                                }}>
                                {domainName}
                            </span>
                            <input
                                type="text"
                                value={input}
                                onChange={handleInput}
                                onKeyDown={e => e.code === "Enter" ? saveLink() : null}
                                placeholder={link === "website" ? "" : "Type username only"}
                                style={{ padding: "5px", borderRadius: "10px", border: '1px solid #ddd' }}></input>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveLink}
                            >Save</button>
                            <span
                                style={{ textDecoration: "underline", color: "#007bff", cursor: "pointer" }}
                                onClick={() => setIsEditing(false)}
                            >Cancel</span>
                        </span> :
                        <span>Click&nbsp;
                            <span
                                style={{ textDecoration: "underline", color: "#007bff", cursor: "pointer" }}
                                onClick={() => setIsEditing(true)}
                            >here</span>
                            &nbsp;to add a link
                        </span>}
            </div>
        </>
    )
}