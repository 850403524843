import { GLOBALTYPES, DeleteData } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'
import { POST_TYPES } from './postAction'
import { PROFILE_TYPES } from './profileAction'

export const createChallenge = ({ user, subject, finishDate, beginDate, auth, socket, postType, mentions }) => async (dispatch) => {

    try {

        const res = await postDataAPI(`challenge`, { userinvited: user._id, beginDate, subject, finishDate, postType, mentions }, auth.token)

        dispatch({ type: PROFILE_TYPES.INVITE, payload: res.data.newUser })

        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                ...auth,
                user: { ...auth.user, challengesSentt: [...auth.user.challengesSent, res.data.result._id] }
            }
        })
        socket.emit('follow', res.data.newUser)

        // Notify
        const content = subject;
        const msg = {
            id: auth.user._id,
            text: 'sent you a challenge request.',
            recipients: [res.data.newUser._id],
            url: `/challenge`,
            content
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const acceptChallenge = ({ user, users, subject, challenge_id, auth, socket }) => async (dispatch) => {


    /*     console.log(challenge_id)
    let newUser;

    if (users.every(item => item._id !== user._id)) {
        newUser = { ...user, on_going_challenge: challenge_id, challengesSent: DeleteData(user.challengesSent, challenge_id) }
    } else {
        users.forEach(item => {
            if (item._id === user._id) {
                newUser = { ...item, on_going_challenge: challenge_id, challengesSent: DeleteData(item.challengesSent, challenge_id) }
            }
        })
    }
    dispatch({ type: PROFILE_TYPES.INVITE, payload: newUser }) */

    try {

        const res = await patchDataAPI(`challenge/${challenge_id}/accept`, null, auth.token)

        /* socket.emit('follow', res.data.newUser) */

        // Notify
        const content = subject;
        const msg = {
            id: auth.user._id,
            text: 'Accepted your challenge request.',
            recipients: [user._id],
            url: `/challenge`,
            content
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}


export const deleteChallenge    = ({ Challenge, auth, socket }) => async (dispatch) => {
    //  dispatch({ type: POST_TYPES.DELETE_POST, payload: poll })

    try {

        const res = await deleteDataAPI(`challenge/${Challenge.post._id}`, auth.token)

        //const content = res.data.newPoll.subject;
        // Notify

        //dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {

      /*  dispatch({
            type: GLOBALTYPES.ALERT,

            payload: { error: err.response.data.msg }
        })*/
        console.log(err)
    }
}