import axios from 'axios'

export const getDataAPI = async (url, token) => {

  /*  const res1 = await axios.get(`/api/getnotif/64cf918f3818bc2d84e55124`, {
        headers: { Authorization: token}
    })
    console.log(res1)*/

    const res = await axios.get(`/api/${url}`, {
        headers: { Authorization: token}
    })

    return res;
}
export const getVerifiedDataAPI = async (url, token) => {

    const res = await axios.get(`/api/${url}`, {
        headers: { Authorization: token}
    })


    return res;
}

export const postDataAPI = async (url, post, token) => {


    const res = await axios.post(`/api/${url}`, post, {
        headers: { Authorization: token}
    })
    
    


    return res;
}

export const putDataAPI = async (url, post, token) => {
    const res = await axios.put(`/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const patchDataAPI = async (url, post, token) => {
    const res = await axios.patch(`/api/${url}`, post, {
        headers: { Authorization: token}
    })
    return res;
}

export const deleteDataAPI = async (url, token) => {
    const res = await axios.delete(`/api/${url}`, {
        headers: { Authorization: token}
    })
    return res;
}