import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const PostThumb = ({ posts, result }) => {
  const { theme } = useSelector((state) => state);
  
  if (result === 0) return <h2 className="text-center text-danger">No Post</h2>;
  return (
    <div className="my_AddsContainer">
      <div className="ZonePub">Zone Pub</div>
      <div className="post_thumb">
        {posts.map((post) => (
          <Link key={post._id} to={`/post/${post._id}`}>
            <div 
              className="post_thumb_display"
              style={ {
                backgroundImage: post.images[0].filetype.filetype.startsWith("audio/") && "url(/yourock_audio.png)",
                backgroundPosition: "center",
                backgroundSize: "cover"
              }}
            >

              {post.images[0].filetype.filetype.startsWith("video/") ? (
                <video
                  controls
                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.images[0].filename.filename}`}
                  alt={post.images[0].url}
                  style={{ filter: theme ? "invert(1)" : "invert(0)" }}
                />
              ) : (post.images[0].filetype.filetype.startsWith("audio/")?(
                  <audio 
                    src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.images[0].filename.filename}`} 
                    controls
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%"
                    }}
                  >
                  </audio>)

                  :(
                <img
                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.images[0].filename.filename}`}
                  alt={post.images[0].url}
                  onClick={()=>console.log(`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.images[0].filename.filename}`)}
                  style={{ filter: theme ? "invert(1)" : "invert(0)" }}
                />
              ))}
              <div className="post_thumb_menu">
                <i className="far fa-thumbs-up">{post.likes.length}</i>
                <i className="far fa-thumbs-down">{post.dislikes.length}</i>
                <i className="far fa-comment">{post.comments.length}</i>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PostThumb;
