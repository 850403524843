import { GLOBALTYPES } from '../actions/globalTypes'




const initialState = {
    ClickChallangeComment:false,
     ClickInvitations :false,
RealoadData:false,
    ElemntChanged:null,
    NotificationNumberUpdate:false

};


//-----------------------|| CARD REDUCER ||-----------------------//

const PageReducer = (state = initialState, action) => {

    //const [listecard, addcart] = useState({cards:});

    switch (action.type) {
        case GLOBALTYPES.CLICK:
            state.ClickInvitations=true
            return {
                ...state

            };
            case GLOBALTYPES.CLICKCHALLENGECOMMENT:
            state.ClickInvitations=true
            return {
                ...state

            };
            case GLOBALTYPES.ELEMENTCHANGED:
            state.ElemntChanged=action.payload.element
            return {
                ...state

            };
            case GLOBALTYPES.CHANGENOTIFICATIONNUMBER:
                console.log("iiiiiimmmmmmmmmmm heeeeeeeeeeerrrrrrreeeeeeeeee")
                console.log(state.NotificationNumberUpdate)
            state.NotificationNumberUpdate=!state.NotificationNumberUpdate
            return {
                ...state

            };
            case GLOBALTYPES.INITIALIZEELEMENT:
            state.ElemntChanged=null
            return {
                ...state

            };

        case GLOBALTYPES.INITIALSTATE:

            state.ClickInvitations=false;
            state.ClickChallangeComment=false;

            return {
                ...state

            };
            case GLOBALTYPES.REALOADDATA:

            state.RealoadData=! state.RealoadData;


            return {
                ...state

            };
        default:
            return state;


    }};

export default PageReducer;
