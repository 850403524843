import React, { useEffect, useState } from "react";
import InfoSettings from "../../components/profile/InfoSettings";
import { useSelector, useDispatch } from "react-redux";
import { getProfileUsers } from "../../redux/actions/profileAction";
import { useParams } from "react-router-dom";

const Settings = () => {
  const { profile, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { id } = useParams();
  const [saveTab, setSaveTab] = useState(false);

  useEffect(() => {
    if (profile.ids.every((item) => item !== id)) {
      dispatch(getProfileUsers({ id, auth }));
    }
  }, [id, auth, dispatch, profile.ids]);

  return (
    <div className="profile">
      <InfoSettings auth={auth} profile={profile} dispatch={dispatch} id={id} />
    </div>
  );
};

export default Settings;
