import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PostCard from "../PostCard";
import PollCard from "../PollCard";
import ChallengeCard from "../ChallengeCard";
import LoadIcon from "../../images/loading.gif";
import LoadMoreBtn from "../LoadMoreBtn";
import { getDataAPI } from "../../utils/fetchData";
import { POST_TYPES } from "../../redux/actions/postAction";
import { useLocation } from "react-router-dom";

const Posts = () => {
  const { homePosts, auth, theme } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const handleLoadMore = async () => {
    setLoad(true);
    const res = await getDataAPI(
      `posts?limit=${homePosts.page * 9}`,
      auth.token
    );
    dispatch({
      type: POST_TYPES.GET_POSTS,
      payload: { ...res.data, page: homePosts.page + 1 },
    });
    setLoad(false);
  };

  const location = useLocation();
  const postType = new URLSearchParams(location.search).get("postType");
  
  return (
    <div className="posts">
      {postType !== null
        ? homePosts.posts
          .filter((post) => post.postType === postType)
          .map((post) => {

            if (post.userCreator) {
              return <ChallengeCard key={post._id} challenge={post} theme={theme} />;

              
            } else if (post.user && post.post1) {
              return <PollCard key={post._id} poll={post} theme={theme} />;
            } else {
              return <PostCard key={post._id} post={post} theme={theme} />;
            }
          })
        : homePosts.posts.map((post) => {
          if (post.userCreator) {

            return <ChallengeCard key={post._id} challenge={post} theme={theme} />;

          } else if (post.user && post.post1) {
            return <PollCard key={post._id} poll={post} theme={theme} />;
          } else {
            return <PostCard key={post._id} post={post} theme={theme} />;
          }
        })}
      {load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />}
      <LoadMoreBtn
        result={homePosts.result}
        page={homePosts.page}
        load={load}
        handleLoadMore={handleLoadMore}
      />
    </div>
  );
};

export default Posts;
