import React, { useState, useEffect, useRef } from "react";
import UserCard from "../UserCard";
import { useSelector, useDispatch } from "react-redux";
import { getDataAPI } from "../../utils/fetchData";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import { useHistory, useParams } from "react-router-dom";
import Badge from '@mui/material/Badge';

import {
    MESS_TYPES,
    getConversations, ReadMessage,
} from "../../redux/actions/messageAction";
import {unLikePost} from "../../redux/actions/postPollAction";

const ListeElement = ({user}) => {
    const { auth, message, online } = useSelector((state) => state);
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();



    const [search, setSearch] = useState("");
    const [searchUsers, setSearchUsers] = useState([]);
    const [FiltredUser, setFiltredUser] = useState([]);
    const [MessageNumber, setMessageNumber] = useState(user.messageNoViewed);



    const [page, setPage] = useState(0);


    useEffect(async () => {
        if (message.mesageSend != null) {
           

            if (message.mesageSend.sender == user._doc._id) {
                setMessageNumber(MessageNumber + 1)
                await dispatch({
                    type: MESS_TYPES.INIZIALIZE_MESSAGE,
                })
            }
        }
    }, [message.mesageSend]);

    const handleAddUser = (user) => {
        setSearch("");
        setSearchUsers([]);
        dispatch({
            type: MESS_TYPES.ADD_USER,
            payload: { ...user, text: "", media: [] },
        });
        dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online });
        return history.push(`/message/${user._id}`);
    };

    const isActive = (user) => {
        if (id === user._id) return "active";
        return "";
    };

    useEffect(() => {
        if (message.firstLoad) return;
        dispatch(getConversations({ auth }));
    }, [dispatch, auth, message.firstLoad]);

    // Load More
useEffect(()=>{
    if(message.idViewingMessage!=null){
        if(message.idViewingMessage==user._doc._id){
            setMessageNumber(0)
            dispatch({ type: MESS_TYPES.INIZIALIZE_IDVIEWMESSAGE })


        }
    }
},[message.idViewingMessage])

    useEffect(() => {
        if (message.resultUsers >= (page - 1) * 9 && page > 1) {
            dispatch(getConversations({ auth, page }));
        }
    }, [message.resultUsers, page, auth, dispatch]);

    // Check User Online - Offline
    useEffect(() => {
        if (message.firstLoad) {
            dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online });
        }
    }, [online, message.firstLoad, dispatch]);
    const  handleViewMessage=async (user) => {
        //if(user.messageNoViewed>0) {
            await dispatch(ReadMessage({id:user._doc._id,auth}));
setMessageNumber(0)

        //}
    }


    return (

                        <div
                            key={user._doc._id}
                            className={`message_user ${isActive(user._doc)}`}
                            onClick={async () => {
                                await dispatch({
                                    type: GLOBALTYPES.CHANGENOTIFICATIONNUMBER
                                });
                                await handleViewMessage(user)
                                handleAddUser(user._doc)
                            }}
                        >

                            <UserCard user={user._doc} />
                            <Badge badgeContent={MessageNumber} color="primary">
                            </Badge>
                        </div>

    );
};

export default ListeElement;
