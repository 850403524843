import { Grid, TextField, Divider, Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material'
import { React, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'


export default function CreatePageForm() {

    const CATEGORIES = ["Category1", "Category2", "Category3"]

    const { auth } = useSelector(state => state)

    // Controlled form data
    const [formData, setFormData] = useState({
        pageName: "",
        pageCategory: "",
        pageBio: "",
        pageAdresse: "",
        pagePhone: "",
        pageEmail: ""
    })

    // Form Errors
    const [formErrors, setFormErrors] = useState({
        pageName: true,
        pageCategory: true,
        pageAdresse: true,
        pagePhone: true,
        pageEmail: true
    })

    // True if the form is submitted, meaning that invalid fields will not
    // show errors unless the user tried to create a page.
    const [isTouched, setIsTouched] = useState(false)

    // Errors returned from server will be displayed in the form
    const [serverError, setServerError] = useState({})

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

        if (name === "pageEmail")
            setFormData({ ...formData, [name]: value.toLowerCase() })

        setServerError({})

        if (name !== "pageBio") {
            setFormErrors({ ...formErrors, [name]: !value })
            return;
        }
    }

    const handlePageCreate = async () => {
        if (!isTouched) setIsTouched(true)

        if (serverError.res) return;
        
        // Check if there are any errors
        for (const [key, value] of Object.entries(formData))
            if (!value && key !== "pageBio") return;

        // Send form data 
        // If success, Navigate to new page
        // If error, display it in the form 
        await axios.post('/api/page', {
            name: formData.pageName,
            category: formData.pageCategory,
            info: formData.pageBio,
            adress: formData.pageAdresse,
            phone: formData.pagePhone,
            email: formData.pageEmail,
            owner: auth.user._id
        }, {
            headers: { Authorization: auth.token }
        }).then(() => window.location.pathname = 'userpage/' + formData.pageName)
            .catch(err => setServerError(err.response.data))

    }

    return (
        <Grid container sx={{ padding: 2 }} spacing={2} >

            <Grid item xs={6}>
                <TextField
                    sx={{ width: "100%" }}
                    name="pageName"
                    value={formData.pageName}
                    variant='filled'
                    label='Page name (required)'
                    onChange={handleChange}
                    error={(formErrors.pageName && isTouched) || serverError.res === "duplicate name"}
                    helperText={formErrors.pageName && isTouched
                        ? 'This field is required.'
                        : serverError.res === "duplicate name"
                            ? "This name already exists."
                            : null}
                />
            </Grid>

            <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel
                        id="pageCategory"
                        sx={{
                            color: formErrors.pageCategory && isTouched
                                ? "#d32f2f"
                                : "rgba(0, 0, 0, 0.6)",
                        }}>
                        Category (required)</InputLabel>
                    <Select
                        name="pageCategory"
                        value={formData.pageCategory}
                        variant='filled'
                        id="pageCategory"
                        onChange={handleChange}
                        error={formErrors.pageCategory && isTouched}
                    >
                        {CATEGORIES.map((cat, index) =>
                            <MenuItem key={index} value={cat}>{cat}</MenuItem>
                        )}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                        {formErrors.pageCategory && isTouched ? 'This field is required.' : null}
                    </FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={6} sx={{ width: "100%" }}>
                <TextField
                    sx={{ width: "100%" }}
                    name="pageBio"
                    value={formData.pageBio}
                    variant='filled'
                    label='Bio (facultatif)'
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6} sx={{ width: "100%" }}>
                <TextField
                    sx={{ width: "100%" }}
                    name="pageAdresse"
                    value={formData.pageAdresse}
                    variant='filled'
                    label='Adresse (required)'
                    onChange={handleChange}
                    error={formErrors.pageAdresse && isTouched}
                    helperText={formErrors.pageAdresse && isTouched ? 'This field is required.' : null}
                />
            </Grid>

            <Grid item xs={6} sx={{ width: "100%" }}>
                <TextField
                    sx={{ width: "100%" }}
                    name="pagePhone"
                    value={formData.pagePhone}
                    variant='filled'
                    label='Phone number (required)'
                    onChange={handleChange}
                    error={(formErrors.pagePhone && isTouched) || serverError.res === "invalid phone number"}
                    helperText={formErrors.pagePhone && isTouched
                        ? 'This field is required.'
                        : serverError.res === "invalid phone number"
                            ? "This phone number is not valid."
                            : null}
                />
            </Grid>

            <Grid item xs={6} sx={{ width: "100%" }}>
                <TextField
                    sx={{ width: "100%" }}
                    name="pageEmail"
                    value={formData.pageEmail}
                    variant='filled'
                    label='Email (required)'
                    onChange={handleChange}
                    error={(formErrors.pageEmail && isTouched)
                        || serverError.res === "invalid email"
                        || serverError.res === "duplicate email"
                    }
                    helperText={formErrors.pageEmail && isTouched
                        ? 'This field is required.'
                        : serverError.res === "invalid email"
                            ? "This email is not valid."
                            : serverError.res === "duplicate email"
                                ? "This email is already being used."
                                : null}
                />
            </Grid>
            <Divider />
            <div className="text-center " style={{ paddingTop: "32px", margin: "0 auto" }}>
                <button type="button" className="btn btn-primary btn-lg" onClick={handlePageCreate}>Create Page</button>
            </div>
        </Grid>

    )
}