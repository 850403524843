import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createPost, updatePost } from "../redux/actions/postAction";
import Icons from "./Icons";
import { imageShow, videoShow, audioShow } from "../utils/mediaShow";

const StatusModal = () => {

  const { auth, theme, status, socket } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [content, setContent] = useState("");
  const [images, setImages] = useState([]);

  const [postType, setPostType] = useState("");

  const [stream, setStream] = useState(false);
  const videoRef = useRef();
  const refCanvas = useRef();
  const [tracks, setTracks] = useState("");


  const handleChangeImages = (e) => {
    const files = [...e.target.files];
    let err = "";
    let newImages = [];

    files.forEach((file) => {
      if (!file) return (err = "File does not exist.");

      if (file.size > 1024 * 1024 * 20) {
        return (err = "The image/video largest is 5mb.");
      }

      return newImages.push(file);
    });

    if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } });
    setImages([...images, ...newImages]);
  };


  const deleteImages = (index) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);
  };

  const handleStream = () => {


    setStream(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();

          const track = mediaStream.getTracks();
          setTracks(track[0]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleCapture = () => {
    const width = videoRef.current.clientWidth;
    const height = videoRef.current.clientHeight;

    refCanvas.current.setAttribute("width", width);
    refCanvas.current.setAttribute("height", height);

    const ctx = refCanvas.current.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, width, height);
    let URL = refCanvas.current.toDataURL();
    setImages([...images, { camera: URL }]);
  };

  const handleStopStream = () => {


    tracks.stop();
    setStream(false);
  };

  const handleSubmit = (e) => {


    e.preventDefault();
    if (images.length === 0)
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Please add your media." },
      });

    if (status.onEdit) {


      dispatch(updatePost({ content, images, auth, status, postType }));
    } else {


      dispatch(createPost({ content, images, auth, socket, postType }));
    }

    setContent("");
    setImages([]);
    if (tracks) tracks.stop();
    dispatch({ type: GLOBALTYPES.STATUS, payload: false });
  };

  useEffect(() => {


    if (status.onEdit) {
      setContent(status.content);
      setImages(status.images);
      setPostType(status.postType);
    }
  }, [status]);



  const handlePostTypeChange = (event) => {

    setPostType(event.target.value);
  };







  return (
    <div className="status_modal">
      <form onSubmit={handleSubmit}>
        <div className="status_header">
          {
            status.onEdit ?
            (<h5 className="m-0">Update Post</h5>):
            (<h5 className="m-0">Create Post</h5>)
          }
          
          <span
            onClick={() =>
              dispatch({
                type: GLOBALTYPES.STATUS,
                payload: false,
              })
            }
          >
            &times;
          </span>
        </div>

        <div className="status_body">
          <textarea
            name="content"
            value={content}
            placeholder={`${auth.user.fullname}, what are you thinking?`}
            onChange={(e) => setContent(e.target.value)}
            style={{
              filter: theme ? "invert(1)" : "invert(0)",
              color: theme ? "white" : "#111",
              background: theme ? "rgba(0,0,0,.03)" : "",
            }}
          />

          <div className="d-flex">
            <div className="flex-fill">
              <select value={postType} onChange={handlePostTypeChange}>
                <option value="">Select a niche</option>
                <option value="fashion">Fashion</option>
                <option value="beauty">Beauty</option>
                <option value="photography">Photography</option>
                <option value="food">Food</option>
                <option value="gadgets_and_technology">
                  Gadgets and Technology
                </option>
                <option value="diy_crafts">DIY Crafts</option>
                <option value="music_and_instruments">
                  Music and Instruments
                </option>
                <option value="art">Art</option>
                <option value="health_and_fitness">Health and Fitness</option>
                <option value="sports">Sports</option>
                <option value="diet_&_weight_loss">Diet & Weight Loss</option>
                <option value="self-motivation">Self-Motivation</option>
                <option value="entrepreneurship">Entrepreneurship</option>
                <option value="beverage">Beverage</option>
                <option value="foreign_languages">Foreign Languages</option>
                <option value="interior_design">Interior Design</option>
                <option value="gardening">Gardening</option>
                <option value="farming">Farming</option>
                <option value="job_and_resume">Job and Resume</option>
                <option value="design_and_development">Design and Development</option>
                <option value="lifestyle">Lifestyle</option>
                <option value="books_and_literature">Books and Literature</option>
                <option value="cars_&_moto">Cars & Moto</option>
              </select>
            </div>
            <Icons setContent={setContent} content={content} theme={theme} />
          </div>
          <div className="show_images">
            {images.map((img, index) => (
              <div key={index} id="file_img">
                {img.camera ? (
                  imageShow(img.camera, theme)
                ) : status.onEdit && status.images.includes(img) ? (
                  <>
                    {img.filetype.filetype.startsWith("video/")
                      ? videoShow(`${process.env.REACT_APP_PUBLIC_FOLDER}/${img.filename.filename}`, theme)
                      : img.filetype.filetype.startsWith("audio/")
                        ? audioShow(`${process.env.REACT_APP_PUBLIC_FOLDER}/${img.filename.filename}`, theme)
                        : imageShow(`${process.env.REACT_APP_PUBLIC_FOLDER}/${img.filename.filename}`, theme)}
                  </>
                ) : (
                  <>
                    {img.type.startsWith("video/")
                      ? videoShow(URL.createObjectURL(img), theme)
                      : img.type.startsWith("audio/")
                        ? audioShow(URL.createObjectURL(img), theme)
                        : imageShow(URL.createObjectURL(img), theme)}
                  </>
                )}
                <span onClick={() => deleteImages(index)}>&times;</span>
              </div>
            ))}
          </div>

          {stream && (
            <div className="stream position-relative">
              <video
                autoPlay
                muted
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ filter: theme ? "invert(1)" : "invert(0)" }}
              />

              <span onClick={handleStopStream}>&times;</span>
              <canvas ref={refCanvas} style={{ display: "none" }} />
            </div>
          )}

          <div className="input_images">
            {stream ? (
              <i className="fas fa-camera" onClick={handleCapture} />
            ) : (
              <>
                <i className="fas fa-camera" onClick={handleStream} />
                <div className="file_upload">
                  <i className="fas fa-file-audio" />
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="audio/*"
                    onChange={handleChangeImages}
                  />
                </div>
                <div className="file_upload">
                  <i className="fas fa-image" />
                  <input
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    accept="image/*,video/*"
                    onChange={handleChangeImages}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="status_footer">
        {
            status.onEdit ?
            (<button className="btn btn-secondary w-100" type="submit">
            Update
          </button>):
            (<button className="btn btn-secondary w-100" type="submit">
            Post
          </button>)
          }
          
        </div>
      </form>
    </div>
  );
};

export default StatusModal;
