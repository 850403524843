import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dislikePost, unDislikePost, unLikePost} from "../redux/actions/postPollAction";
const PollDislikeButton = ({poll,
  post,
}) => {
useEffect(()=>{

  setIsDisLike(((post.dislikes).includes(auth.user._id)))

},[poll])

    useEffect(()=>{
        setIsDisLike(((post.dislikes).includes(auth.user._id)))
    },[post.dislikes.length,post.likes.length])
  const postId=post._id
  const dispatch = useDispatch();
  const { auth, theme, socket } = useSelector((state) => state);


  const [isDisLike, setIsDisLike] = useState(((post.dislikes).includes(auth.user._id)));
  let otherPost;

 // const [isDislike, setIsDislike] = useState(false);
  const handleDislike = async () => {
   
    if(poll.post1[0]._id==post._id){
     
      otherPost=poll.post2[0]

           }else{
               otherPost=poll.post1[0]
               console.log(otherPost)

           }
 //   if (loadDislike) return;
    if(!isDisLike){
         if((post.likes).includes(auth.user._id)){
            await dispatch(unLikePost({ poll,postId:post._id, auth, socket }));

         }else if(((otherPost.dislikes).includes(auth.user._id))){
          await dispatch(unDislikePost({poll, postId: otherPost._id, auth, socket}));
      }else if(((otherPost.likes).includes(auth.user._id))){
          await dispatch(unLikePost({ poll,postId:otherPost._id, auth, socket }));
      }
         setIsDisLike(true);
         try {
             await dispatch(dislikePost({ poll, postId:post._id, auth, socket }));
         } catch (error) {
           }
      }
   };

  const handleUnDislike = async () => {
    setIsDisLike(false);

    //setLoadDislike(true);
    await dispatch(unDislikePost({poll, postId:post._id, auth, socket }));
    //setLoadDislike(false);
  };
  return (
    <>



      {(isDisLike) ? (
          (new Date(poll.finishDate) > new Date()) ?
          <div className="like-button">
              <div className="like-icon">
              <i
                  className="fas fa-thumbs-down text-danger"
                  onClick={handleUnDislike}
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.dislikes.length}
              /> 
                </div>

<div className="like-buttonPoll text-danger ">{post.dislikes.length}</div>

        </div>
              :
              <div className="like-button">
              <div className="like-icon">
              <i
                  className="fas fa-thumbs-down text-danger"
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.dislikes.length}
              />
               </div>

<div className="like-buttonPoll text-danger ">{post.dislikes.length}</div>

        </div>
      ) : (
          (new Date(poll.finishDate) >new Date()) ?
          <div className="like-button">
              <div className="like-icon">

              <i
                  className="fas fa-thumbs-down "
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  onClick={handleDislike}

                  data-number={post.dislikes.length}
              /> 
               </div>

<div className="like-buttonPoll ">{post.likes.length}</div>

        </div>
              : 
              <div className="like-button">
              <div className="like-icon">

              
              <i
                  className="fas fa-thumbs-down "
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.dislikes.length}
              />
               </div>

<div className="like-buttonPoll ">{post.dislikes.length}</div>

        </div>
      )
      }




    </>
  );
};
export default PollDislikeButton;