import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPoll } from "../../redux/actions/pollAction";
import LoadIcon from "../../images/loading.gif";
import PollCard from "../../components/PollCard";

const Poll = () => {
  const { id } = useParams();
  const [poll, setPoll] = useState([]);

  const { auth, detailPost } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPoll({ detailPost, id, auth }));

    if (detailPost.length > 0) {
      const newArr = detailPost.filter((post) => post._id === id);
      setPoll(newArr);
    }
  }, [detailPost, dispatch, id, auth]);



  return (
    <div className="posts">
      {poll.length === 0 && (
        <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
      )}

      {poll.map((item) => (
        <div style={{paddingLeft:"25%" , paddingRight:"25%"}}>
        <PollCard key={item._id} poll={item} />
        </div>
      ))}
    </div>
  );
};

export default Poll;