import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { invite, uninvite } from "../../redux/actions/profileAction"

const InvitationBtn = ({ user }) => {
  const [invited, setInvited] = useState(false);

  const { auth, profile, socket } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (auth.user.invitationsSent.find((item) => item._id === user._id)) {
      setInvited(true);
    }
    return () => setInvited(false);
  }, [auth.user.invitationsSent, user._id]);

  const handleInvite = async () => {
    if (load) return;

    setInvited(true);
    setLoad(true);
    await dispatch(invite({ users: profile.users, user, auth, socket }));
    setLoad(false);
  };

  const handleUnInvite = async () => {
    if (load) return;

    setInvited(false);
    setLoad(true);
    await dispatch(uninvite({ users: profile.users, user, auth, socket }));
    setLoad(false);
  };

  return (
    <>
      {invited ? (
        <button className="btn btn-outline-danger" onClick={handleUnInvite}>
          UnInvite
        </button>
      ) : (
        <button className="btn btn-outline-info" onClick={handleInvite}>
          Invite
        </button>
      )}
    </>
  );
};

export default InvitationBtn;
