import React, {Fragment, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDataAPI } from "../../utils/fetchData";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import LoadIcon from "../../images/loading.gif";

const Search = () => {
    const [searchValue, setSearchValue] = useState('');
    const { search } = useSelector((state) => state);
    const dispatch = useDispatch();

    const handleSearch = () => {
        // Perform the desired action with the search value
    };



    const handleInputChange = (event) => {
        setSearchValue(event.target.value);

        const inputValue = event.target.value.toLowerCase();

        const filteredData = search.UserListe.filter((friend) =>
            friend.fullname.toLowerCase().includes(inputValue)
        );


        dispatch({ type: GLOBALTYPES.CHANGE, payload: {"filtredListe":filteredData} });


    };

  return (
      <Fragment>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2%', marginBottom: '2%' }}>
              <div>
                  <div className="input-group">
                      <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" onKeyUp={handleSearch} onChange={handleInputChange} />
                  </div>
              </div>
          </div>
      </Fragment>
  );
};

export default Search;