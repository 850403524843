import React, { Fragment } from "react";
import Carousel from "../../Carousel";

const PollCardImages = ({ post, poll, theme,type}) => {
        return (

    <div className="card_body">
      <div
        className="card_body-content"
        style={{
          filter: theme ? "invert(1)" : "invert(0)",
          color: theme ? "white" : "#111",
        }}
      ></div>
      {post.images.length > 0 && (
        <Fragment>
        
        <Carousel
        type={type}
          post={post}
          poll={poll}
          images={post.images}
          id={post._id}
          className="sameImageSizePoll"
        />
        </Fragment>
      )}
    </div>
  );
};
export default PollCardImages;