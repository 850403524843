import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import UserCard from "../../components/UserCard";
import InvitationBtn from "../../components/home/invitationBtn";
import LoadIcon from '../../images/loading.gif'
import { Link } from "react-router-dom";
import "./myFriendsManagement.css";
import { getSuggestions } from "../../redux/actions/suggestionsAction";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";
import {getDataAPI} from "../../utils/fetchData";

const Suggestions = () => {
    useEffect(() => {
            dispatch(getSuggestions(auth.token))


    }, [])
    const dispatch = useDispatch();

    const [friends, setFriends] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
   const { auth, suggestions,search } = useSelector((state) => state);

    useEffect(async () => {
        dispatch({ type: GLOBALTYPES.INITIALIZE, payload: {"friends":suggestions.users,"PageName":"suggestion"} });

    }, [suggestions.users]);
    useEffect(() => {
        if(suggestions.users!=[]){
            setIsLoaded(true)
        }
    }, [suggestions.users]);
 
  return (
    <div>
      <div className="item-container--YN">
          {!isLoaded ? (
                  <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
              ) :(<>
          <i
            className="fas fa-redo"
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(getSuggestions(auth.token))}
          />

              {search.filtredListe.map((user) => (
            <div className="card--YN" key={user._id}>

              <div className="div--img--YN">
                <Link to={`/profile/${user._id}`}>
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${user.avatar}`}
                    alt=""
                    className="FeaturedFriendsImg--YN"
                    id="Suggestions--picture--YN" />
                </Link>
              </div>
              <div className="div--text--YN">
                <Link to={`/profile/${user._id}`} className="text-dark">
                  {user.fullname}
                </Link>
                <br></br>
                <InvitationBtn user={user} />
              </div>
            </div>))}
          </>)
          }
      </div>
    </div>
  );
};
export default Suggestions;
