export const GLOBALTYPES = {
    AUTH: "AUTH",
    ALERT: "ALERT",
    THEME: 'THEME',
    STATUS: 'STATUS',
    CHANGE:'CHANGE',
    ADDPOST:'ADDPOST',
    MODAL: 'MODAL',
    INITIALIZE:'INITIALIZE',
    CLICK:'CLICK',
    CHANGENOTIFICATIONNUMBER:'CHANGENOTIFICATIONNUMBER',
    INITIALIZEELEMENT:'INITIALIZEELEMENT',

    INITIALSTATE:"INITIALSTATE",
    SOCKET: 'SOCKET',
    ONLINE: 'ONLINE',
    ELEMENTCHANGED:"ELEMENTCHANGED",
    OFFLINE: 'OFFLINE',
    CALL: 'CALL',
    CLICKCHALLENGECOMMENT:'CLICKCHALLENGECOMMENT'
    ,

    REALOADDATA: "REALOADDATA",
    PEER: 'PEER',
    POLL: 'POLL',
    CHALLENGE: 'CHALLENGE',
    UPLOADMEDIA: 'UPLOADMEDIA'
}

export const EditData = (data, id, post) => {
    const newData = data.map(item =>
        (item._id === id ? post : item)
    )
    return newData;
}

export const DeleteData = (data, id) => {
    const newData = data.filter(item => item._id !== id)
    return newData;
}