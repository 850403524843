import React , {useEffect} from "react";
import Login from "./login";
import Register from "./register";
import { connect , useDispatch , useSelector } from "react-redux";
import { toggleLoginComp } from "../redux/actions/loginRegisterActions";
import {setRocketStyle} from '../redux/actions/rocketStyleAction'
import {
  barber,
  blackSmith,
  photographer,
  keepUrSkills,
  urocklive,
  makeup,
  group,
  chef,
  tailor,
  rocket,
} from "../images/index";
const LoginRegisterPage = ({ loginComp, toggleLoginComp }) => {
    const rocketStyle = useSelector((state) => state.rocket.style);
  const dispatch = useDispatch();

  const rocketAnimation = (e) => {
    const valueY = e.pageY * -1 / 20;

    const newStyle = {
        transition: `transform 0.1s ease`,
      transform: `translate(0, ${valueY}px)`,
    };

    dispatch(setRocketStyle(newStyle));
  };

  useEffect(() => {
    // Clean up the rocket style when the component unmounts
    return () => {
      dispatch(setRocketStyle({}));
    };
  }, [dispatch]);
  return (
    <div className="auth_page">
      <div className="auth_container">
        {loginComp ? (
          <Login toggleLoginComp={toggleLoginComp} />
        ) : (
          <Register toggleLoginComp={toggleLoginComp} />
        )}
      </div>
      <img src={urocklive} className="urocklive" />
      <img src={keepUrSkills} className="keepyourskills" />
      <img src={rocket} className="rocket bounce-animation" onMouseMove={rocketAnimation} style={rocketStyle} />
      <div className="images_container">
        <img src={barber} className="barber slideup-animation" />
        <img src={blackSmith} className="blacksmith slideup-animation" />
        <img src={photographer} className="photographer slideup-animation" />
        <img src={makeup} className="makeup slideup-animation" />
        <img src={group} className="group slideup-animation" />
        <img src={tailor} className="tailor slideup-animation" />
        <img src={chef} className="chef slideup-animation" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginComp: state.loginRegister.loginComp,
  };
};

const mapDispatchToProps = {
  toggleLoginComp,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegisterPage);
