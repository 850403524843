import React, { useState, useEffect } from "react";
import { getDataAPI } from "../../utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ProductItem from "./dotmenu";
import "./myFriendsManagement.css";
import {MESS_TYPES} from "../../redux/actions/messageAction";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";

const FeaturedFriends = () => {
  const { auth,search } = useSelector((state) => state);
  useEffect(() => {
    return () => {
       dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "friend"}});

    }
  }, [])

  const [friends, setFriends] = useState([]);
  const dispatch = useDispatch();
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [Loaded, setLoaded] = useState(false);


  const { id } = useParams();
  useEffect(async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});

    const res = await getDataAPI(`user/${id}/invitations`, auth.token);

    setFriends(res.data.user.friends);
    dispatch({ type: GLOBALTYPES.INITIALIZE, payload: {"friends":res.data.user.friends,"PageName":"friends"} });

    setFriends(res.data.user.friends);
setLoaded(true)

  }, [auth, id,search.friends]);



  return (
      Loaded &&(
    <div>
      <div className="item-container--YN">
        {search.filtredListe.map((friend) => (
          <div className="card--YN" key={friend._id}>
            <div className="div--img--YN">
              <Link to={`/profile/${friend._id}`}>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${friend.avatar}`}
                  alt=""
                  className="FeaturedFriendsImg--YN"
                />
              </Link>

            </div>
            <div className="div--text--YN">
              <Link to={`/profile/${friend._id}`} className="text-dark">
                {friend.fullname}
              </Link>
              {/* <p>{friend.friends}</p> */}
              <div className="FriendsMainContainer--text">
                <ProductItem user={friend} />
                
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
      ));
};
export default FeaturedFriends;
