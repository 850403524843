import React, { useState } from 'react'
import AddAndEdit from './AddAndEdit'
import CreateServiceForm from './CreateServiceForm';
import CustomModal from './CustomModal';

export default function PageServices({ addService }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);


    return (
        <>
            <AddAndEdit handleOpen={handleOpen} />
            <CustomModal open={open} handleClose={handleClose} name="Add Service">
                <CreateServiceForm
                    handleClose={handleClose}
                    addService={addService}
                />
            </CustomModal>
        </>
    )
}