import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {likePost, unDislikePost} from "../redux/actions/postAction";
import {unLikePost} from "../redux/actions/postAction";

const LikeButton = ({post}) => {



  useEffect(()=>{
    setIsLike(((post.likes).includes(auth.user._id)))
  },[post.dislikes.length,post.likes.length])
  const { theme,auth ,socket} = useSelector((state) => state);
  const dispatch = useDispatch();

  const [isLike, setIsLike] = useState(((post.likes).includes(auth.user._id)));
  const handleLike = async () => {
    if(!isLike) {
      if (((post.dislikes).includes(auth.user._id))) {
        await dispatch(unDislikePost({post, postId: post._id, auth, socket}));

      }
      //  if (loadLike) return;
      setIsLike(true)

      await dispatch(likePost({post, auth, socket}));
    }


  };
  const handleUnLike = async () => {

    setIsLike(false)

    //   if (loadLike) return;

    // setLoadLike(true);
    await dispatch(unLikePost({ post,postId:post._id, auth, socket }));
    // setLoadLike(false);
  }
  return (
    <>
      {isLike ? (
        <div className="like-button">
        <div className="like-icon">
        <i
          className="fas fa-thumbs-up text-danger"
          onClick={handleUnLike}
          style={{ filter: theme ? "invert(1)" : "invert(0)" }}
          data-number={post.likes.length}

       
        />
         </div>

<div className="like-buttonPoll ">{post.likes.length}</div>

        </div>
      ) : (
        <div className="like-button">
        <div className="like-icon">
        <i
          className="far fa-thumbs-up"
          onClick={handleLike}
          data-number={post.likes.length}
        />
        </div>

<div className="like-buttonPoll ">{post.likes.length}</div>

        </div>
      )}

    </>
  );
};

export default LikeButton;
