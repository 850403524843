import React, {useEffect, useState} from "react";
import PollCardHeader from "./home/post_card/PollCardHeader";
import PollCardImages from "./home/post_card/PollCardImages";
import PollCardSubject from "./home/post_card/PollCardSubject";
import PollCardFooter from "./home/post_card/PollCardFooter";
import Comments from "./home/Comments";
import PollInputComment from "./home/PollInputComment";
import {GLOBALTYPES} from "../redux/actions/globalTypes";
import {useDispatch, useSelector} from "react-redux";
const PollCard = ({ poll, theme, isPicks }) => {
  const { auth, page } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [Poll, setPoll] = useState(poll);

  const changeVariable=async () => {

    if ((page.ElemntChanged != null) && (poll._id == page.ElemntChanged._id)) {
      await setPoll(page.ElemntChanged)
    }}
  useEffect(async () => {
    await changeVariable().then(()=>{
      if ((page.ElemntChanged != null) && (poll._id == page.ElemntChanged._id))

        dispatch({
          type: GLOBALTYPES.INITIALIZEELEMENT
        });
    })
  },[page])
  return (
    <div className="card my-3  " style={{maxHeight:"10%",height:"10%"}} >
      {!isPicks && <PollCardHeader post={Poll} />}

      <PollCardSubject post={Poll} theme={theme}  />
      <div className="polls-container" >
        <div className="pollNumber1"  >
          <PollCardImages post={Poll.post1[0]} theme={theme} poll={Poll}  type={"poll"} />
          <PollCardFooter isChallenge={false} poll={Poll} post={Poll.post1[0]}  postId={Poll.post1[0]._id} />
          <Comments post={Poll.post1[0]} poll={Poll} />
          <PollInputComment post={Poll.post1[0]} poll={Poll} />

        </div>
        <div className="pollNumber2">
          <PollCardImages post={Poll.post2[0]} theme={theme} poll={poll} type={"poll"} />
          <PollCardFooter isChallenge={false} poll={Poll} post={Poll.post2[0]}  postId={Poll.post2[0]._id} />
          <Comments post={Poll.post2[0]} poll={Poll} />
          <PollInputComment post={Poll.post2[0]} poll={Poll} />
        </div>
      </div>
    </div>
  );
};
export default PollCard;