import { GLOBALTYPES } from "./globalTypes";
import { getDataAPI, postDataAPI} from "../../utils/fetchData";
import valid from "../../utils/valid";
import axios from "axios";




export const loginGoogle = () => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
        window.location.href =`https://Yourock.live/auth/google`;

        const resData1 = await axios.post('/api/google')
        console.log(resData1.data)

        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: resData1.data.access_token,
                user: resData1.data.user,
            },
        });

        localStorage.setItem("firstLogin", true);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: resData1.data.msg,

            },
        });
    } catch (error) {
        return error.message;
    }
};

export const loginFacebook = () => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
        window.location.href = 'https://Yourock.live/auth/facebook/callback';
        const resData1 = await postDataAPI('/facebook')
        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: resData1.data.access_token,
                user: resData1.data.user,
            },
        });

        localStorage.setItem("firstLogin", true);

        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: resData1.msg,
            },
        });
    } catch (error) {
        return error.message;
    }
};

export const login = (data) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
        const res = await postDataAPI("login", data);
        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: res.data.access_token,
                user: res.data.user,
            },
        });

        localStorage.setItem("firstLogin", true);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res.data.msg,
            },
        });
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response.data.msg,
            },
        });
    }
};

export const refreshToken = () => async (dispatch) => {
    const firstLogin = localStorage.getItem("firstLogin");
    if (firstLogin) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        try {
            const res = await postDataAPI("refresh_token");
            dispatch({
                type: GLOBALTYPES.AUTH,
                payload: {
                    token: res.data.access_token,
                    user: res.data.user,
                },
            });

            dispatch({ type: GLOBALTYPES.ALERT, payload: {} });
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: {
                    error: err.response.data.msg,
                },
            });
        }
    }
};

export const register = (data) => async (dispatch) => {
    const check = valid(data);
    if (check.errLength > 0)
        return dispatch({ type: GLOBALTYPES.ALERT, payload: check.errMsg });

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

        const res = await postDataAPI("register", data);
        dispatch({
            type: GLOBALTYPES.AUTH,
            payload: {
                token: res.data.access_token,
                user: res.data.user,
            },
        });

        localStorage.setItem("firstLogin", true);
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                success: res.data.msg,
            },
        });
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response.data.msg,
            },
        });
    }
};

export const logout = () => async (dispatch) => {
    try {
        localStorage.removeItem("firstLogin");
        await postDataAPI("logout");
        window.location.href = "/";
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: {
                error: err.response.data.msg,
            },
        });
    }
};
