import React, {useEffect, useState} from "react";
import PollCardImages from "./home/post_card/PollCardImages";
import PollCardSubject from "./home/post_card/PollCardSubject";
import PollCardFooter from "./home/post_card/PollCardFooter";
import Comments from "./home/Comments";
import PollInputComment from "./home/PollInputComment";
import ChallengeCardHeader from "./home/post_card/ChallengeCardHeader";
import {useDispatch, useSelector} from "react-redux";
import {GLOBALTYPES} from "../redux/actions/globalTypes";
const ChallengeCard = ({ challenge, theme, isPicks }) => {
console.log(challenge)
    const { auth, page } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [Challenge, setChallenge] = useState(challenge);
    const changeVariable=async () => {

        if ((page.ElemntChanged != null) && (Challenge._id == page.ElemntChanged._id)) {
            await setChallenge(page.ElemntChanged)
        }}
    useEffect(async () => {
        await changeVariable().then(()=>{
            if ((page.ElemntChanged != null) && (Challenge._id == page.ElemntChanged._id))

                dispatch({
                type: GLOBALTYPES.INITIALIZEELEMENT
            });
        })
    },[page])
  return (
      <div>
          { challenge &&(
    <div className="card my-3 ">

      <ChallengeCardHeader post={Challenge} />
      <PollCardSubject post={Challenge} theme={theme} />

      <div className="polls-container">
        <div className="pollNumber1">
          <PollCardImages post={Challenge.post1[0]} theme={theme} poll={Challenge} type={"challenge"} />

            <PollCardFooter  isChallenge={true}  poll={Challenge} post={Challenge.post1[0]} postId={Challenge.post1[0]._id} />
          <Comments post={Challenge.post1[0]} poll={Challenge} />
          <PollInputComment post={Challenge.post1[0]} poll={Challenge} />
        </div>
        <div className="pollNumber2">
          <PollCardImages post={Challenge.post2[0]} theme={theme} poll={Challenge} type="challenge" />
            <PollCardFooter  isChallenge={true}  poll={Challenge} post={Challenge.post2[0]} postId={Challenge.post2[0]._id} />

          <Comments post={Challenge.post2[0]} poll={Challenge} />
          <PollInputComment post={Challenge.post2[0]} poll={Challenge} />
        </div>
      </div>
    </div>)}
      </div>
  );
};
export default ChallengeCard;