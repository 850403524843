import { Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import PageInfo from './PageInfo';
import PageSocial from './PageSocial';
import PageReviews from './PageReviews';
import PageServices from './PageServices';
import SubService from './SubService';

export default function AboutPage(props) {

    const [subSection, setSubSection] = useState("subSection1")
    const [serviceSections, setServiceSections] = useState({})
    const [id, setId] = useState(1)

    const CATEGORIES = ["General Information", "Websites and Social Networks", "Reviews", "Services"]

    const { pageData: { category, info, adress, phone, reviews, services, websites }, owner } = props

    const handleClick = e => setSubSection(e.target.id)

    const addService = (name, desc) => {
        setServiceSections({ ...serviceSections, [id]: { name, desc } })
        setId(id => id + 1)
    }

    const changeService = (id, name, desc) => setServiceSections({ ...serviceSections, [id]: { name, desc } })

    return (
        <>
            <Grid container padding={2} spacing={2}>
                <Grid item xs={3}>
                    <Stack spacing={2}>
                        {CATEGORIES.map((elem, index) =>
                            <Typography key={index} id={`subSection${index + 1}`} onClick={handleClick} sx={{
                                fontFamily: "Century gothic",
                                fontSize: subSection === `subSection${index + 1}` ? "20px" : "16px",
                                cursor: "pointer",
                                fontWeight: subSection === `subSection${index + 1}` ? "bold" : ""
                            }}>
                                {elem}
                            </Typography>
                        )}
                        {Object.keys(serviceSections).map((id) =>
                            <Typography id={id} key={id} onClick={handleClick} sx={{
                                fontFamily: "Century gothic",
                                fontSize: subSection === id ? "20px" : "16px",
                                cursor: "pointer",
                                fontWeight: subSection === id ? "bold" : "",
                                paddingLeft: "8px"
                            }}>
                                {serviceSections[id].name}
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={9}>
                    {subSection === "subSection1"
                        && <PageInfo bio={{ category, info, adress, phone }} owner={owner} />
                    }
                    {subSection === "subSection2" && <PageSocial websites={websites} owner={owner} />}
                    {subSection === "subSection3" && <PageReviews reviews={reviews} owner={owner} />}
                    {subSection === "subSection4"
                        && <PageServices addService={addService} services={services} owner={owner} />
                    }
                    {serviceSections[subSection]
                        && <SubService
                            id={subSection}
                            name={serviceSections[subSection].name}
                            desc={serviceSections[subSection].desc}
                            changeService={changeService}
                        />
                    }

                </Grid>
            </Grid>
        </>
    )
}