const initialState = {
    loginComp: true
  };
  
  const loginRegisterReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'TOGGLE_LOGIN_COMP':
        return {
          ...state,
          loginComp: !state.loginComp
        };
      default:
        return state;
    }
  };
  
  export default loginRegisterReducer;