import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createPoll } from "../redux/actions/pollAction";
import Icons from "./Icons";
import { imageShow, videoShow, audioShow } from "../utils/mediaShow";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postDataAPI, patchDataAPI } from "../utils/fetchData";
import { imageUpload } from "../utils/imageUpload";

const UploadMediaModal = () => {
  const { auth, theme, uploadMedia, socket } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [imagesPost1, setImagesPost1] = useState([]);

  const [postType, setPostType] = useState("");

  const [stream, setStream] = useState(false);
  const videoRef = useRef();
  const refCanvas = useRef();
  const [tracks, setTracks] = useState("");


  const handleChangeImagesPost1 = (e) => {
    const files = [...e.target.files];
    let err = "";
    let newImages = [];

    files.forEach((file) => {
      if (!file) return (err = "File does not exist.");

      if (file.size > 1024 * 1024 * 5) {
        return (err = "The image/video largest is 5mb.");
      }

      return newImages.push(file);
    });

    if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } });
    setImagesPost1([...imagesPost1, ...newImages]);
  };

  const deleteImagesPost1 = (index) => {
    const newArr = [...imagesPost1];
    newArr.splice(index, 1);
    setImagesPost1(newArr);
  };

  const handleStream = () => {
    setStream(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();

          const track = mediaStream.getTracks();
          setTracks(track[0]);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleCapture = () => {
    const width = videoRef.current.clientWidth;
    const height = videoRef.current.clientHeight;

    refCanvas.current.setAttribute("width", width);
    refCanvas.current.setAttribute("height", height);

    const ctx = refCanvas.current.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, width, height);
    let URL = refCanvas.current.toDataURL();
    setImages([...images, { camera: URL }]);
  };

  const handleStopStream = () => {
    tracks.stop();
    setStream(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imagesPost1.length === 0)
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Please add your media." },
      });

    let mediaPost1 = [];

    if (imagesPost1.length > 0) {
      mediaPost1 = await imageUpload(imagesPost1);
    }

    const res1 = await postDataAPI(
      "postpolls",
      { images: mediaPost1 },
      auth.token
    );

    const post = res1.data.newPost._id;
    let postNumber;

    if(uploadMedia.userCreator._id === auth.user._id){
      postNumber = 1;
    }
  
    if(uploadMedia.userinvited._id === auth.user._id){
      postNumber = 2;
    }

    const res = await patchDataAPI(
      `challenge/${uploadMedia._id}/uploadmedia`,
      { post, postNumber },
      auth.token
    );

    setImagesPost1([]);
    if (tracks) tracks.stop();
    dispatch({ type: GLOBALTYPES.UPLOADMEDIA, payload: null });
    dispatch({ type: GLOBALTYPES.REALOADDATA });

  };


  const handlePostTypeChange = (event) => {
    setPostType(event.target.value);

  };

  return (
    <div className="status_modal">
      <form onSubmit={handleSubmit}>
        <div className="status_header">
          <h5 className="m-0">Upload Your Media</h5>
          <span
            onClick={() =>
              dispatch({
                type: GLOBALTYPES.UPLOADMEDIA,
                payload: false,
              })
            }
          >
            &times;
          </span>
        </div>

        <div className="status_body">

          <div className="show_images">
            {imagesPost1.map((img, index) => (
              <div key={index} id="file_img">
                {img.camera ? (
                  imageShow(img.camera, theme)
                ) : img.url ? (
                  <>
                    {img.type.startsWith("video/")
                      ? videoShow(img.url, theme)
                      : img.type.startsWith("audio/")
                        ? audioShow(img.url, theme)
                        : imageShow(img.url, theme)}
                  </>
                ) : (
                  <>
                    {img.type.startsWith("video/")
                      ? videoShow(URL.createObjectURL(img), theme)
                      : img.type.startsWith("audio/")
                        ? audioShow(URL.createObjectURL(img), theme)
                        : imageShow(URL.createObjectURL(img), theme)}
                  </>
                )}
                <span onClick={() => deleteImagesPost1(index)}>&times;</span>
              </div>
            ))}
          </div>

          {stream && (
            <div className="stream position-relative">
              <video
                autoPlay
                muted
                ref={videoRef}
                width="100%"
                height="100%"
                style={{ filter: theme ? "invert(1)" : "invert(0)" }}
              />

              <span onClick={handleStopStream}>&times;</span>
              <canvas ref={refCanvas} style={{ display: "none" }} />
            </div>
          )}

          <div className="input_images">
            {stream ? (
              <i className="fas fa-camera" onClick={handleCapture} />
            ) : (
              <>
                <i className="fas fa-camera" onClick={handleStream} />

                <div className="file_upload">
                  <i className="fas fa-image" />
                  <input
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    accept="image/*,video/*"
                    onChange={handleChangeImagesPost1}
                  />
                </div>
                <div className="file_upload">
                  <i className="fas fa-file-audio" />
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="audio/*"
                    onChange={handleChangeImagesPost1}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="status_footer">
          <button className="btn btn-secondary w-100" type="submit">
            Upload Media
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadMediaModal;
