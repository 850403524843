import React, { useEffect, useState } from "react";
import Info from "../../components/profile/Info";
import Posts from "../../components/profile/Posts";
import Saved from "../../components/profile/Saved";
import { useSelector, useDispatch } from "react-redux";
import LoadIcon from "../../images/loading.gif";
import { getProfileUsers } from "../../redux/actions/profileAction";
import {useLocation, useParams} from "react-router-dom";
import my_Adds from "../../components/home/my_Adds.png";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import "./addsButton.css";
import PollsSection from "./PollsSection";
import ChallengesSection from "./ChallengesSection";
// import StatusButton from "../../components/home/StatusButton";
// import PollsModal from "../../components/PollsModal";
import my_challenges from "../../components/home/my_challenges.png";
import my_picks from "../../components/home/my_picks.png";
import my_picksFilled from "../../components/home/my_picksFilled.png";
import my_challengesFilled from "../../components/home/my_challengesFilled.png";
import my_AddsFilled from "../../components/home/my_AddsFilled.png";
import "../../components/home/StatusButton.css";

const Profile = () => {
  const { profile,page, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [saveTab, setSaveTab] = useState(false);
  const [postTab, setPostTab] = useState(true);
  const [pollsTab, setPollsTab] = useState(false);
  const [challengesTab, setChallengesTab] = useState(false);
  const [my_challengesActive, setMy_challengesActive] = useState(false);
  const [my_picksActive, setMy_picksActive] = useState(false);
  const [my_AddsActive, setMy_AddsActive] = useState(true);


  useEffect(() => {
    if (page.ClickChallangeComment) {
      showChallengesTab()
      dispatch({ type: GLOBALTYPES.INITIALSTATE});

    }
  }, [page.ClickChallangeComment]);

  const showPollsTab = () => {

    setPostTab(false);
    setSaveTab(false);
    setPollsTab(true);
    setChallengesTab(false);
    setMy_picksActive(true);
    setMy_AddsActive(false);
    setMy_challengesActive(false);
  };
  const showChallengesTab = () => {


    setPostTab(false);
    setSaveTab(false);
    setPollsTab(false);
    setChallengesTab(true);
    setMy_challengesActive(true);
    setMy_AddsActive(false);
    setMy_picksActive(false);
  };
  const showPostTab = () => {

    setPostTab(true);
    setSaveTab(false);
    setPollsTab(false);
    setChallengesTab(false);
    setMy_AddsActive(true);
    setMy_challengesActive(false);
    setMy_picksActive(false);
  };
  const showSaveTab = () => {

    setPostTab(false);
    setSaveTab(true);
    setPollsTab(false);
    setChallengesTab(false);
  };

  let { state } = useLocation();


  useEffect(() => {
    if (profile.ids.every((item) => item !== id)) {
      dispatch(getProfileUsers({ id, auth }));
    }
  }, [id, auth, dispatch, profile.ids]);
  return (
    <div className="profile">
      <Info auth={auth} profile={profile} dispatch={dispatch} id={id} />
      <div className="threeContainer">
        <img
          src={my_challengesActive ? my_challengesFilled : my_challenges}
          className="ThreeButtonsImage"
          onClick={() => showChallengesTab()}
          alt=''
        />
        <img
          src={my_picksActive ? my_picksFilled : my_picks}
          className="ThreeButtonsImage"
          onClick={() => showPollsTab()}
          alt=''
        />
        <img
          src={my_AddsActive ? my_AddsFilled : my_Adds}
          className="ThreeButtonsImage"
          id="threeContainerImg3"
          /* onClick={() => dispatch({ type: GLOBALTYPES.STATUS, payload: true })} */
          onClick={() => showPostTab()}
          alt=''
        />
      </div>
      {
        auth.user._id === id && postTab ?( <img
        src={my_Adds}
        id="addsButtonWished"
        onClick={() => dispatch({ type: GLOBALTYPES.STATUS, payload: true })}
        alt=''
      />):null
      }
      
      {auth.user._id === id && (
        <div className="profile_tab">
          {/* <button
            className={challengesTab ? "active" : ""}
            onClick={() => showChallengesTab()}
          >
            Arena
          </button>
          <button
            className={pollsTab ? "active" : ""}
            onClick={() => showPollsTab()}
          >
            Polls
          </button>
          <button
            className={postTab ? "active" : ""}
            onClick={() => showPostTab()}
          >
            Posts
          </button> */}
          {/* <button
            className={saveTab ? "active" : ""}
            onClick={() => showSaveTab()}
          >
            Saved
          </button>*/}
        </div>
      )}
      {profile.loading ? (
        <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
      ) : (
        <>
          {postTab ? (
            <Posts auth={auth} profile={profile} dispatch={dispatch} id={id} />
          ) : saveTab ? (
            <Saved auth={auth} dispatch={dispatch} />
          ) : challengesTab ? (
            <ChallengesSection />
          ) : pollsTab ? (
            <PollsSection />
          ) : null}
        </>
      )}
    </div>
  );
};

export default Profile;
