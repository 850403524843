import React, {useState, useRef, useEffect, Fragment} from "react";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createChallenge } from "../redux/actions/challengeAction";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Icons from "./Icons";
import {postDataAPI} from "../utils/fetchData";
import {PROFILE_TYPES} from "../redux/actions/profileAction";
import {createNotify} from "../redux/actions/notifyAction";
import Peer from "peerjs";


const ChallengeReqModal = () => {
  const { auth, theme, socket, profile } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [content, setContent] = useState("");
  const [postType, setPostType] = useState("");
  const [Title, setTitle] = useState("");
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);
  const [isFinishDateSelected, setIsFinishDateSelected] = useState(false);
  const [mentions, setMentions] = useState('')
  const [selectedFinishDate, setSelectedFinishDate] = useState('');
  const [selectedStartTime, setSelectedStartTime] = useState(""); // State for time input
  const [selectedFinishTime, setsSelectedFinishTime] = useState(""); // State for time input
  let Today=(new Date())
  let currentDay= String(Today.getDate()).padStart(2, '0');

  let currentMonth = String(Today.getMonth()+1).padStart(2,"0");

  let currentYear = Today.getFullYear();
  const minDate =`${currentYear}-${currentMonth}-${currentDay}`;

const CheckDate=()=>{
    let newFinishDate=new Date(selectedFinishDate + 'T' + selectedFinishTime)
    let newStartDate=new Date(selectedStartDate + 'T' + selectedStartTime)

    if(newFinishDate==newStartDate){
        return 0
    }else if(newFinishDate>newStartDate)
    return 1
    else return -1
}

  const handleStartTimeChange = (event) => {
    var currentHours = Today.getHours();
    var currentMinutes = Today.getMinutes();
    var formattedTime = currentHours + ':' + currentMinutes ;

    if(selectedStartDate==minDate) {

      const [var1Hours, var1Minutes] = event.target.value.split(":");


        const var1Date = new Date(2000, 0, 1, var1Hours, var1Minutes);
      const [var2Hours, var2Minutes] = formattedTime.split(":");


        const var2Date = new Date(2000, 0, 1, var2Hours, var2Minutes);

      if(var1Date<=var2Date){
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: "Please select start  time not depassed ." },
        })

        let ExtractedTime=Today


        ExtractedTime.setMinutes(ExtractedTime.getMinutes() + 2);
       let  NewcurrentHours = ExtractedTime.getHours();
        let NewcurrentMinutes = ExtractedTime.getMinutes();
         formattedTime = NewcurrentHours + ':' + NewcurrentMinutes ;

        setIsStartTimeSelected(true);

        setSelectedStartTime(formattedTime)

      }else{
        setIsStartTimeSelected(true);
        setSelectedStartTime(event.target.value)
    }}else
    {

        if(isFinishDateSelected){
            const [var2Hours, var2Minutes] = selectedFinishTime.split(":");
         let   NewFinishDate=new Date(selectedFinishDate + 'T' + selectedFinishTime)
         let   NewStartDate=new Date(selectedStartDate + 'T' + selectedStartTime)
if( NewStartDate>NewFinishDate ){
    const [var1Hours, var1Minutes] = event.target.value.split(":");


    const var1Date = new Date(2000, 0, 1, var1Hours, var1Minutes);

    var1Date.setMinutes(var1Date.getMinutes() + 2);
    let  NewFinalHour = var1Date.getHours();
    let NewFinalMinutes = var1Date.getMinutes();
   formattedTime = NewFinalHour + ':' + NewFinalMinutes ;
    formattedTime = NewFinalHour + ':' + NewFinalMinutes ;
    setSelectedStartTime(formattedTime)
    setSelectedStartTime(event.target.value)
}

        }else {
      setIsStartTimeSelected(true);
      setSelectedStartTime(event.target.value);
    }}
  };

  const handleFinishTimeChange = (event) => {

    if(selectedStartDate==selectedFinishDate){


        if(selectedStartTime>event.target.value){
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: "Please select finish time grater than start time ." },
        })
           let NewFinishTime= selectedStartTime;

            setsSelectedFinishTime(NewFinishTime)
      }
      else{
          setsSelectedFinishTime(event.target.value);
      }
    }
    else {

      setsSelectedFinishTime(event.target.value);
    }
    };



  let ExtractedTimeMax=Today
  ExtractedTimeMax.setDate(ExtractedTimeMax.getDate() + 2)
  let MaxDay= String(Today.getDate()).padStart(2, '0');

  let MonthMax = String(Today.getMonth()+1).padStart(2,"0");

  let YearMax = Today.getFullYear();
  const MaxDate =`${YearMax}-${MonthMax}-${MaxDay}`;

  const [maxDate, setMaxDate] = useState(MaxDate);
  const handleStartDateChange = (event) => {
    setIsStartDateSelected(true)
    setSelectedStartDate(event.target.value);
  };

  const handleFinishDateChange = (event) => {

    if (selectedStartDate > event.target.value) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Please select finish date grater than start Date ." },
      })


    setSelectedFinishDate(selectedStartDate);
  }else {
        if (selectedStartDate == selectedFinishDate) {

          if(isFinishDateSelected){
            if ((CheckDate == -1)||(CheckDate == 0)) {
                const [var1Hours, var1Minutes] = selectedStartTime.split(":");

                const var1Date = new Date(2000, 0, 1, var1Hours, var1Minutes);
                var1Date.setMinutes(var1Date.getMinutes() + 2);
                let NewFinalHour = var1Date.getHours();
                let NewFinalMinutes = var1Date.getMinutes();
                setsSelectedFinishTime(NewFinalHour + ':' + NewFinalMinutes);
                setSelectedFinishDate(event.target.value);

            } else {
                setSelectedFinishDate(event.target.value);
            }}

        } else
            setSelectedFinishDate(event.target.value);


        setIsFinishDateSelected(true)
    }
  };
  function extractIdFromUrl(url) {
    const lastSlashIndex = url.lastIndexOf("/");
    if (lastSlashIndex !== -1) {
      return url.substr(lastSlashIndex + 1);
    }
    return null;
  }
  const url = window.location.href;
  const id = extractIdFromUrl(url);

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (Title == "") {
          return dispatch({
              type: GLOBALTYPES.ALERT,
              payload: {error: "Please add a title."},
          })
      }
      else       if (content == "") {
          return dispatch({
              type: GLOBALTYPES.ALERT,
              payload: {error: "Please add subbject."},
          })}

   else if((new Date(selectedStartDate + 'T' + selectedStartTime)=="Invalid Date")&&(new Date(selectedFinishDate + 'T' + selectedFinishTime)=="Invalid Date")){
      
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Please add a valid date  and time." },
      })
    }
   else if((minDate==selectedStartDate) &&(new Date(selectedStartDate + 'T' + selectedStartTime)<(new Date()))){

      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Choose a time that has not passed " },
      })}
   else if((minDate==selectedStartDate) &&(new Date(selectedStartDate + 'T' + selectedStartTime)<(new Date()))){

      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Choose a time that has not passed " },
      })
    }

    else if (!/^(#[^#\s]+\s*)+$/.test(mentions.trim())) 

      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "The mentions field is not valid." }
      })

    else {
       const mongofinishDate = new Date(selectedFinishDate + 'T' + selectedFinishTime);
       const mongobeginDate = new Date(selectedStartDate + 'T' + selectedStartTime);

      const user = {
        _id: id,
      };

          try {
              const res = await postDataAPI(`challenge`, {
                  title:Title,
                  userinvited: user._id,
                  beginDate:mongobeginDate,
                  subject:content,
                  finishDate:mongofinishDate,
                  postType,
                  mentions
              }, auth.token)
              if (res.data.error==true){
                  return dispatch({
                      type: GLOBALTYPES.ALERT,
                      payload: {error: res.data.msg},
                  })

              }else {
              dispatch({type: PROFILE_TYPES.INVITE, payload: res.data.newUser})
              dispatch({
                  type: GLOBALTYPES.AUTH,
                  payload: {
                      ...auth,
                      user: {...auth.user, challengesSentt: [...auth.user.challengesSent, res.data.result._id]}
                  }
              })

                  socket.emit('follow', res.data.newUser)
              // Notify
              //const content = content;
              const msg = {
                  id: auth.user._id,
                  text: 'sent you a challenge request.',
                  recipients: [res.data.newUser._id],
                  url: `/challenge`,
                  content:content
              }


                  dispatch(createNotify({msg, auth, socket}))
              setContent("");
              dispatch({type: GLOBALTYPES.CHALLENGE, payload: false});}
          } catch (err) {
              console.log(err)

            //  dispatch({
             //     type: GLOBALTYPES.ALERT,
                //  payload: {error: err.response.data.msg}
              //})
          }


      }
  };

  const handlePostTypeChange = (event) => {
    setPostType(event.target.value);
    
  };



  return (
    <div className="status_modal">
      <form onSubmit={handleSubmit}>
        <div className="status_header" id="status_headerChallenge">
          <h5 className="m-0">You will Rock the world too!!</h5>
          <span
            onClick={() =>
              dispatch({
                type: GLOBALTYPES.CHALLENGE,
                payload: false,
              })
            }
          >
            &times;
          </span>
        </div>

        <div className="status_body" id="status_bodyChallenge">
            <div className="form-group">
                <input type="text" value={Title} onChange={(e)=>setTitle(e.target.value)} className="form-control" id="formGroupExampleInput" placeholder="Challenge Title"/>
            </div>
          <textarea
            name="content"
            value={content}
            placeholder={`Enter Subject here `}
            onChange={(e) => {

                setContent(e.target.value)

            }

          }
            style={{
              filter: theme ? "invert(1)" : "invert(0)",
              color: theme ? "white" : "#111",
              background: theme ? "rgba(0,0,0,.03)" : "",
              fontFamily: "inherit"
            }}
          />

          <div className="d-flex">
            <div className="flex-fill">
              <select value={postType} onChange={handlePostTypeChange}     className="form-control" style={{maxWidth:"75%"}}>
                <option value="">Select a niche</option>
                <option value="fashion">Fashion</option>
                <option value="beauty">Beauty</option>
                <option value="photography">Photography</option>
                <option value="food">Food</option>
                <option value="gadgets_and_technology">
                  Gadgets and Technology
                </option>
                <option value="diy_crafts">DIY Crafts</option>
                <option value="music_and_instruments">
                  Music and Instruments
                </option>
                <option value="art">Art</option>
                <option value="health_and_fitness">Health and Fitness</option>
                <option value="sports">Sports</option>
                <option value="diet_&_weight_loss">Diet & Weight Loss</option>
                <option value="self-motivation">Self-Motivation</option>
                <option value="entrepreneurship">Entrepreneurship</option>
                <option value="beverage">Beverage</option>
                <option value="foreign_languages">Foreign Languages</option>
                <option value="interior_design">Interior Design</option>
                <option value="gardening">Gardening</option>
                <option value="farming">Farming</option>
                <option value="job_and_resume">Job and Resume</option>
                <option value="design_and_development">Design and Development</option>
                <option value="lifestyle">Lifestyle</option>
                <option value="books_and_literature">Books and Literature</option>
                <option value="cars_&_moto">Cars & Moto</option>
              </select>
            </div>

            <Icons setContent={setContent} content={content} theme={theme} />
          </div>

          <textarea
            name="mentions"
            value={mentions}
            placeholder={`Enter Mentions here `}
            onChange={(e) => setMentions(e.target.value)}
            style={{
              filter: theme ? "invert(1)" : "invert(0)",
              color: theme ? "white" : "#111",
              background: theme ? "rgba(0,0,0,.03)" : "",
              marginTop: "5px",
              fontFamily: "inherit"
            }}
          />

          <div >
            <h5>select start Date :</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>


              <input
                  style={{maxWidth:"50%"}}
                  className="form-control"
                  defaultValue={minDate}
                  min={minDate}
                  max={maxDate}

                  type="date"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
              />
              {isStartDateSelected&& (<input
                  type="time"
                  className="form-control"
                  style={{ maxWidth: "50%" }}
                  value={selectedStartTime}
                  onChange={handleStartTimeChange}
              />




              )}
            </div>
          </div>
          <div >
            {isStartTimeSelected&&(
                <Fragment>

                <h5>select finish Date :</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>

              <input
                  style={{maxWidth:"50%"}}
                  className="form-control"
                  defaultValue={selectedStartDate}
                  min={selectedStartDate}
                  max={maxDate}

                  type="date"
                  value={selectedFinishDate}
                  onChange={handleFinishDateChange}
              />
              {isFinishDateSelected&& (
              <input
                  type="time"
                  className="form-control"
                  style={{ maxWidth: "50%" }}
                  value={selectedFinishTime}
                  onChange={handleFinishTimeChange}
              />)}

            </div>
                </Fragment>
            )}


          </div>

        </div>
        <div className="status_footer" id="status_footerChallenge">
          <button className="btn btn-secondary w-100" type="submit">
            Send Challenge
          </button>
        </div>
      </form>
    </div>
  );
};
export default ChallengeReqModal;