import React, {useState, useEffect, Fragment} from "react";
import { getDataAPI } from "../../utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import challenges from "./challenges.png";
import no_challenges from "./no_challenges.png";
import { MESS_TYPES } from "../../redux/actions/messageAction";

import "./myFriendsManagement.css";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";

const FriendsOnline = () => {
  useEffect(() => {
    return () => {
      dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "friend"}});

    }
  }, [])
  const [Loaded, setLoaded] = useState(false);

  const [onlineFriends, setOnlineFriends] = useState([]);
  const [on_going_challenge, setOn_going_challenge] = useState(null);
  const { auth, message, online, profile } = useSelector((state) => state);

  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});

    const res = await getDataAPI(`user/${id}/invitations`, auth.token);



    dispatch({ type: MESS_TYPES.CHECK_ONLINE_OFFLINE, payload: online });



    setOnlineFriends(res.data.user.friends.filter(friend => online.includes(friend._id)));


    setOn_going_challenge(res.data.user.on_going_challenge);
  }, [auth, online, id]);
  useEffect(async () => {

    dispatch({ type: GLOBALTYPES.INITIALIZE, payload: {"friends":onlineFriends,"PageName":"online"} });

    setLoaded(true)
  },[onlineFriends])
  useEffect(async () => {





  }, [on_going_challenge]);

  return (
      Loaded &&(
          <div className="Friends--item-container">
            {onlineFriends.map((friend) => (

                <Fragment>


                  <header className="Online--Navigationheader--YN">
                    <Link to={`/profile/${friend._id}`}>
                      <img
                          src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${friend.avatar}`}
                          className="Online--Navigationimg--YN"
                      />
                    </Link>

                    <nav className="Online--Navigationnav">
                      <ul className="Online--Navigationnav_links--YN">
                        <li className="Online--Navigationli--YN">
                          <Link to={`/profile/${friend._id}`} className="text-dark">
                            {friend.fullname}
                          </Link>
                        </li>
                      </ul>
                    </nav>


                    {
                      friend.challange  ? (
                          <img src={challenges} className="Online--Navigationimg1--YN" />
                      ) : (
                          <img src={no_challenges} className="Online--Navigationimg1--YN" />
                      )
                    }

                  </header>
                </Fragment>
            ))}
          </div>
      ));
};

export default FriendsOnline;