import React from "react";
import Avatar from "../../Avatar";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { GLOBALTYPES } from "../../../redux/actions/globalTypes";
import { deletePoll } from "../../../redux/actions/pollAction";
import { BASE_URL } from "../../../utils/config";
import {deletePost} from "../../../redux/actions/postAction";
import {deleteChallenge} from "../../../redux/actions/challengeAction";

const ChallengeCardHeader = ({ post }) => {
  const today=new Date()



  const { auth, socket } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleEditPost = () => {
    dispatch({ type: GLOBALTYPES.STATUS, payload: { ...post, onEdit: true } });
  };


  const handleDeleteChallenge= () => {
    if (window.confirm("Are you sure want to delete this Challenge?")) {
      dispatch(deleteChallenge({ Challenge: { post }, auth, socket }));
      history.push("/");
      window.location.reload();

    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${BASE_URL}/ViewChallenge/${post._id}`);
  };
  return (
    <div className="card_header">
      <div className="d-flex">
        <Avatar
          src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.userCreator.avatar}`}
          size="big-avatar"
        />
        <div className="card_name">
          <h6 className="m-0">
            <Link to={`/profile/${post.userCreator._id}`} className="text-dark">
              {post.userCreator.fullname}
            </Link>
          </h6>
          <small className="text-muted">
            {moment(post.createdAt).fromNow()}
          </small>
        </div>
      </div>
      <div className="d-flex">
        <Avatar
          src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${post.userinvited.avatar}`}
          size="big-avatar"
        />
        <div className="card_name">
          <h6 className="m-0">
            <Link to={`/profile/${post.userinvited._id}`} className="text-dark">
              {post.userinvited.fullname}
            </Link>
          </h6>
          <small className="text-muted">
            {moment(post.createdAt).fromNow()}
          </small>
        </div>
      </div>
      <div className="nav-item dropdown">
        <span className="material-icons" id="moreLink" data-toggle="dropdown">
          more_horiz
        </span>
        <div className="dropdown-menu">

          <div className="dropdown-item" onClick={handleCopyLink}>
            <span className="material-icons">content_copy</span> Copy Link
          </div>
          {((auth.user._id === post.userCreator._id)||(auth.user._id === post.userinvited._id))&&(new Date(post.finishDate)<today) && (
              <>
                <div className="dropdown-item" onClick={handleDeleteChallenge}>
                  <span className="material-icons">delete_outline</span> Remove

                </div>
              </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengeCardHeader;