import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import ChallengeRes from "../components/ChallengeRes";
import Info from "../components/profile/Info";
import LoadIcon from "../images/loading.gif";
import { useParams } from "react-router-dom";
import { getProfileUsers } from "../redux/actions/profileAction";

const Challenge = () => {
  const { profile, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const id = auth.user._id;

  useEffect(() => {
    if (profile.ids.every((item) => item !== id)) {
      dispatch(getProfileUsers({ id, auth }));
    }
  }, [id, auth, dispatch, profile.ids]);
  return (
    <div className="profile">
      <Info auth={auth} profile={profile} dispatch={dispatch} id={id} />
      {auth.user._id === id && (
        <div className="profile_tab">
          <button
            className="active" >
            challenge Requests
          </button>

        </div>
      )}
      {profile.loading ? (
        <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
      ) : (
        <ChallengeRes />
      )}
    </div>
  );
};

export default Challenge;
