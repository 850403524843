import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

export default function AddAndEdit(props) {
    const { edit, handleOpen } = props
    return (
        <span
            style={{
                position: "absolute",
                left: "90%",
                top: "85%",
                border: "2px solid black",
                borderRadius: "50%",
                padding: "10px",
                textAlign: "center",
                cursor: "pointer",
                width: "fit-content",
                height: "fit-content"
            }}
            onClick={handleOpen}
        >
            {
                edit
                    ? <EditIcon sx={{ fontSize: "48px" }} />
                    : <AddIcon sx={{ fontSize: "48px" }} />
            }
        </span>
    )
}