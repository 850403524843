import React, { useState } from 'react'
import AddAndEdit from './AddAndEdit'
import CustomModal from './CustomModal';
import EditServiceForm from './EditServiceForm';

export default function SubService(props) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false);

    return (
        <div style={{ whiteSpace: "pre-wrap" }}>
            {props.desc}
            <AddAndEdit edit handleOpen={handleOpen} />
            <CustomModal open={open} handleClose={handleClose} name="Edit Service" >
                <EditServiceForm {...props} handleClose={handleClose} />
            </CustomModal>
        </div>
    )
}