import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Send from "../../../images/send.svg";
import LikeButton from "../../LikeButton";
import DislikeButton from "../../DislikeButton";
import { useSelector, useDispatch } from "react-redux";
import {

  savePost,
  unSavePost,
} from "../../../redux/actions/postAction";
import ShareModal from "../../ShareModal";
import { BASE_URL } from "../../../utils/config";

const CardFooter = ({ post }) => {

  const [isShare, setIsShare] = useState(false);
  const { auth, theme, socket } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [saved, setSaved] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false);
  // Likes


  // Dislikes


  // Saved
  useEffect(() => {
    if (auth.user.saved.find((id) => id === post._id)) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [auth.user.saved, post._id]);
  const handleSavePost = async () => {
    if (saveLoad) return;
    setSaveLoad(true);
    await dispatch(savePost({ post, auth }));
    setSaveLoad(false);
  };
  const handleUnSavePost = async () => {
    if (saveLoad) return;
    setSaveLoad(true);
    await dispatch(unSavePost({ post, auth }));
    setSaveLoad(false);
  };

  return (
    <div className="card_footer">
      <div className="card_icon_menu">
          <LikeButton
              post={post}
          />
          <DislikeButton
              post={post}


          />
          <Link to={`/post/${post._id}`} className="text-dark">
          <div className="like-button">
              <div className="like-icon">

            <i className="far fa-comment" data-number={post.comments.length} />
            </div>

<div className="like-buttonPoll ">{post.comments.length}</div>

        </div>
          </Link>
          <i className="fa fa-share-alt	" onClick={() => setIsShare(!isShare)} />

          {/* <img src={Send} alt="Send" onClick={() => setIsShare(!isShare)} /> */}
        {saved ? (
          <i className="fas fa-bookmark text-info" onClick={handleUnSavePost} />
        ) : (
          <i className="far fa-bookmark" onClick={handleSavePost} />
        )}
      </div>
      
      {isShare && (
        <ShareModal url={`${BASE_URL}/post/${post._id}`} theme={theme} />
      )}
    </div>
  );
};

export default CardFooter;
