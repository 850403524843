import { GLOBALTYPES, DeleteData } from '../actions/globalTypes'
import {postDataAPI, getDataAPI, deleteDataAPI, patchDataAPI} from '../../utils/fetchData'
import {createNotify, removeNotify} from "./notifyAction";

export const MESS_TYPES = {

    INIZIALIZE_IDVIEWMESSAGE: 'INIZIALIZE_IDVIEWMESSAGE',
    CHANGEMESSAGENUMBER: 'CHANGEMESSAGENUMBER',

    ADD_USER: 'ADD_USER',
    INIZIALIZE_MESSAGE: 'INIZIALIZE_MESSAGE',
    ADD_MESSAGE: 'ADD_MESSAGE',
    SENDMESSAGE: 'SENDMESSAGE',
    GET_CONVERSATIONS: 'GET_CONVERSATIONS',
    GET_MESSAGES: 'GET_MESSAGES',
    UPDATE_MESSAGES: 'UPDATE_MESSAGES',
    DELETE_MESSAGES: 'DELETE_MESSAGES',
    DELETE_CONVERSATION: 'DELETE_CONVERSATION',
    CHECK_ONLINE_OFFLINE: 'CHECK_ONLINE_OFFLINE'
}



export const addMessage = ({createdAt,auth,sender,recipient,text, media,socket,message}) => async (dispatch) =>{
    try {
        let message = {
            sender,
            recipient,
            text,
            media,
            createdAt,
            viewed:false
        }

    const { _id, avatar, fullname, username } = auth.user
    socket.emit('addMessage', {...message, user: { _id, avatar, fullname, username } })

        dispatch({type: MESS_TYPES.ADD_MESSAGE, payload: message})


        let newMessage=message

        const  result =  await postDataAPI('message', newMessage, auth.token)

        const msg = {
            id: result.data.result,
            text: 'send you a message.',
            recipients: [result.data.result.recipient],
            url: `/message`,
            content:result.data.result.text,
            image:result.data.result. media[0]
        }
        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
    
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}
export const ReadMessage = ({ id,auth}) => async (dispatch) => {
    /*   const newPost = { ...post, likes: post.likes.filter(like => like._id !== auth.user._id) }
       dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })
   */




    try {
        await patchDataAPI(`ViewMessage/${id}/`, null, auth.token)

        // Notify


    } catch (err) {
        console.log(err)
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}


export const getConversations = ({auth, page = 1}) => async (dispatch) => {
    try {
        const res = await getDataAPI(`conversations?limit=${page * 9}`, auth.token)
        
        let newArr = [];
        res.data.conversations.forEach(item => {
            item.recipients.forEach(cv => {
                if(cv._id !== auth.user._id){
                    newArr.push({...cv, text: item.text, media: item.media, call: item.call})
                }
            })
        })

        dispatch({
            type: MESS_TYPES.GET_CONVERSATIONS, 
            payload: {newArr, result: res.data.result}
        })

    } catch (err) {
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}

export const getMessages = ({auth, id, page = 1}) => async (dispatch) => {
    try {
        const res = await getDataAPI(`message/${id}?limit=${page * 9}`, auth.token)
        const newData = {...res.data, messages: res.data.messages.reverse()}

        dispatch({type: MESS_TYPES.GET_MESSAGES, payload: {...newData, _id: id, page}})
    } catch (err) {
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}

export const loadMoreMessages = ({auth, id, page = 1}) => async (dispatch) => {
    try {
        const res = await getDataAPI(`message/${id}?limit=${page * 9}`, auth.token)
        const newData = {...res.data, messages: res.data.messages.reverse()}

        dispatch({type: MESS_TYPES.UPDATE_MESSAGES, payload: {...newData, _id: id, page}})
    } catch (err) {
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}

export const deleteMessages = ({msg, data, auth}) => async (dispatch) => {
    const newData = DeleteData(data, msg._id)
    dispatch({type: MESS_TYPES.DELETE_MESSAGES, payload: {newData, _id: msg.recipient}})
    try {
        await deleteDataAPI(`message/${msg._id}`, auth.token)
    } catch (err) {
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}

export const deleteConversation = ({auth, id}) => async (dispatch) => {
    dispatch({type: MESS_TYPES.DELETE_CONVERSATION, payload: id})
    try {
        await deleteDataAPI(`conversation/${id}`, auth.token)
    } catch (err) {
        dispatch({type: GLOBALTYPES.ALERT, payload: {error: err.response.data.msg}})
    }
}