import React, { useState, useEffect } from "react";
import { getDataAPI, putDataAPI, patchDataAPI } from "../../utils/fetchData";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { acceptInvitation, refuseInvitation } from "../../redux/actions/profileAction";
import "./myFriendsManagement.css";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";

const Invitations = () => {
  useEffect(() => {
    return () => {
      dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "friend"}});

    }
  }, [])
  const [invitations, setInvitations] = useState([]);
  const [Loaded, setLoaded] = useState(false);

  const { auth, profile, socket ,search} = useSelector((state) => state);
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {

    await  dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "friend"}});

    const res = await getDataAPI(`user/${id}/invitations`, auth.token);
    dispatch({ type: GLOBALTYPES.INITIALIZE, payload: {"friends":res.data.user.invitations,"PageName":"invitation"} });
setLoaded(true)

    setInvitations(res.data.user.invitations);
  }, [auth, id]);

  const handleAcceptInvitation = async (invitation) => {
    if (load) return;

    setLoad(true);
    await dispatch(acceptInvitation({ users: profile.users, user: invitation, auth, socket }));
    setLoad(false);
  };

  const handleRefuseInvitation = async (invitation) => {
    if (load) return;

    setLoad(true);
    await dispatch(refuseInvitation({ users: profile.users, user: invitation, auth, socket }));
    setLoad(false);
  };

  return (
      Loaded &&(
    <div>
      <div className="item-container--YN">
        {search.filtredListe.map((invitation) => (
          <div className="card--YN" key={invitation._id}>
            <div className="div--img--YN">
              <Link to={`/profile/${invitation._id}`}>
                <img
                  src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${invitation.avatar}`}
                  alt=""
                  className="FeaturedFriendsImg--YN"
                  id="Suggestions--picture--YN"
                />
              </Link>

            </div>
            <div className="div--text--YN">
              <Link to={`/profile/${invitation._id}`} className="text-dark">
                {invitation.fullname}
              </Link>
              {/* <p>{friend.friends}</p> */}
              <button
                onClick={() => handleAcceptInvitation(invitation)}
                className="Suggestions--Button--YN"
                id="button-7"
              >
                Confirm
              </button>
              <button
                onClick={() => handleRefuseInvitation(invitation)}
                className="Suggestions--Button--YN"
                id="button-8"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
      ));
};
export default Invitations;
