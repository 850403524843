import { GLOBALTYPES } from '../actions/globalTypes'


const uploadMediaReducer = (state = null, action) => {
    switch (action.type) {
        case GLOBALTYPES.UPLOADMEDIA:
            return action.payload;
        default:
            return state;
    }
}


export default uploadMediaReducer