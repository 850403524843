import { GLOBALTYPES, EditData, DeleteData } from './globalTypes'
import { POST_TYPES } from './postAction'
import { postDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from '../actions/notifyAction'


export const createComment = ({ post, newComment, auth, socket, poll }) => async (dispatch) => {
    let newPost;
    if (poll) {

        const { post1, post2 } = poll;
        const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
        const otherPost = poll[`post${otherPostIndex}`][0];
        const newP = Object.assign({}, post, {
            comments: [...post.comments, newComment],
        });
        newPost = Object.assign({}, poll, {
            [`post${otherPostIndex}`]: [otherPost],
            [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
        });
    } else {
        newPost = { ...post, comments: [...post.comments, newComment] }
    }
   

        dispatch({ type: GLOBALTYPES.ELEMENTCHANGED, payload: {element:newPost} });

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

    try {

        const data = { ...newComment, postId: post._id, postUserId: post.user._id }
        const res = await postDataAPI('comment', data, auth.token)
        


        const newData = { ...res.data.newComment, user: auth.user }
        let newPost;
        if (poll) {
            const { post1, post2 } = poll;
            const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
            const otherPost = poll[`post${otherPostIndex}`][0];

            const newP = Object.assign({}, post, {
                comments: [...post.comments, newData],
            });


            newPost = Object.assign({}, poll, {
                [`post${otherPostIndex}`]: [otherPost],
                [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
            });


        } else {

            newPost = { ...post, comments: [...post.comments, newData] }
        }

        dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

        // Socket
        socket.emit('createComment', newPost)

if(typeof poll !== 'undefined'){
        if (poll.userCreator) {
            // Notify

            const msg = {
                id: res.data.newComment._id,
                text: newComment.reply ? 'mentioned you in a comment.' : 'has commented on your challenge.',
                recipients: newComment.reply ? [newComment.tag._id] : [post.user._id],
                url: `/profile/${post.user._id}`,
                content: poll.subject
            };

            dispatch(createNotify({ msg, auth, socket }));

        } else if (poll.user && poll.post1) {

            // Notify
            const msg = {
                id: res.data.newComment._id,
                text: newComment.reply ? 'mentioned you in a comment.' : 'has commented on your poll.',
                recipients: newComment.reply ? [newComment.tag._id] : [post.user._id],
                url: `/poll/${poll._id}`,
                content: poll.subject
            }


            dispatch(createNotify({msg, auth, socket}))

        }        } else {
            // Notify
            const msg = {
                id: res.data.newComment._id,
                text: newComment.reply ? 'mentioned you in a comment.' : 'has commented on your post.',
                recipients: newComment.reply ? [newComment.tag._id] : [post.user._id],
                url: `/post/${post._id}`,
                content: post.content,
            }


            dispatch(createNotify({ msg, auth, socket }))
            


        }

    } catch (err) {



        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })

    }
}

export const updateComment = ({ comment, post, content, auth, poll }) => async (dispatch) => {
    const newComments = EditData(post.comments, comment._id, { ...comment, content })
    let newPost;
    if (poll) {
        const { post1, post2 } = poll;
        const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
        const otherPost = poll[`post${otherPostIndex}`][0];
        const newP = Object.assign({}, post, {
            comments: newComments,
        });
        newPost = Object.assign({}, poll, {
            [`post${otherPostIndex}`]: [otherPost],
            [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
        });
    } else {
        newPost = { ...post, comments: newComments }
    }
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })
    try {
        patchDataAPI(`comment/${comment._id}`, { content }, auth.token)
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }
}

export const likeComment = ({ comment, post, auth, poll, socket }) => async (dispatch) => {
    const isDisliked = comment.dislikes.filter((dislike) => dislike._id === auth.user._id).length > 0;
    let newPost;
    if (isDisliked) {
        const newComment = {
            ...comment,
            dislikes: comment.dislikes.filter((dislike) => dislike._id !== auth.user._id),
            likes: [...comment.likes, auth.user]
        }

        const newComments = EditData(post.comments, comment._id, newComment)
        if (poll) {
            const { post1, post2 } = poll;
            const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
            const otherPost = poll[`post${otherPostIndex}`][0];
            const newP = Object.assign({}, post, {
                comments: newComments,
            });
            newPost = Object.assign({}, poll, {
                [`post${otherPostIndex}`]: [otherPost],
                [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
            });
        } else {
            newPost = { ...post, comments: newComments }
        }
    }
    else {
        const newComment = { ...comment, likes: [...comment.likes, auth.user] }

        const newComments = EditData(post.comments, comment._id, newComment)

        if (poll) {
            const { post1, post2 } = poll;
            const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
            const otherPost = poll[`post${otherPostIndex}`][0];
            const newP = Object.assign({}, post, {
                comments: newComments,
            });
            newPost = Object.assign({}, poll, {
                [`post${otherPostIndex}`]: [otherPost],
                [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
            });
        } else {
            newPost = { ...post, comments: newComments }
        }
    }
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })
    try {
        if (isDisliked) {
            await patchDataAPI(`comment/${comment._id}/undislike`, null, auth.token)
        }
        await patchDataAPI(`comment/${comment._id}/like`, null, auth.token)

        if (poll.userCreator) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/challenge/${poll._id}`,
                content: poll.subject
            };

            dispatch(createNotify({ msg, auth, socket }));
        } else if (poll.user && poll.post1) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/poll/${poll._id}`,
                content: poll.subject
            }

            dispatch(createNotify({ msg, auth, socket }))
        } else {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/post/${post._id}`,
                content: post.content,
            }

            dispatch(createNotify({ msg, auth, socket }))
        }

    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }
}

export const dislikeComment = ({ comment, post, auth, poll, socket }) => async (dispatch) => {
    const isLiked = comment.likes.filter((like) => like._id === auth.user._id).length > 0;
    let newPost;
    if (isLiked) {
        const newComment = {
            ...comment,
            likes: comment.likes.filter((like) => like._id !== auth.user._id),
            dislikes: [...comment.dislikes, auth.user]
        }

        const newComments = EditData(post.comments, comment._id, newComment)

        if (poll) {
            const { post1, post2 } = poll;
            const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
            const otherPost = poll[`post${otherPostIndex}`][0];
            const newP = Object.assign({}, post, {
                comments: newComments,
            });
            newPost = Object.assign({}, poll, {
                [`post${otherPostIndex}`]: [otherPost],
                [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
            });
        } else {
            newPost = { ...post, comments: newComments }
        }
    }
    else {
        const newComment = { ...comment, dislikes: [...comment.dislikes, auth.user] }

        const newComments = EditData(post.comments, comment._id, newComment)

        if (poll) {
            const { post1, post2 } = poll;
            const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
            const otherPost = poll[`post${otherPostIndex}`][0];
            const newP = Object.assign({}, post, {
                comments: newComments,
            });
            newPost = Object.assign({}, poll, {
                [`post${otherPostIndex}`]: [otherPost],
                [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
            });
        } else {
            newPost = { ...post, comments: newComments }
        }
    }
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })
    try {
        if (isLiked) {
            await patchDataAPI(`comment/${comment._id}/unlike`, null, auth.token)
        }
        await patchDataAPI(`comment/${comment._id}/dislike`, null, auth.token)

        if (poll.userCreator) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/challenge/${poll._id}`,
                content: poll.subject
            };

            dispatch(createNotify({ msg, auth, socket }));
        } else if (poll.user && poll.post1) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/poll/${poll._id}`,
                content: poll.subject
            }

            dispatch(createNotify({ msg, auth, socket }))
        } else {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/post/${post._id}`,
                content: post.content,
            }

            dispatch(createNotify({ msg, auth, socket }))
        }
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }
}

export const unLikeComment = ({ comment, post, auth, poll, socket }) => async (dispatch) => {

    const newComment = { ...comment, likes: DeleteData(comment.likes, auth.user._id) }

    const newComments = EditData(post.comments, comment._id, newComment)
    let newPost;
    if (poll) {
        const { post1, post2 } = poll;
        const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
        const otherPost = poll[`post${otherPostIndex}`][0];
        const newP = Object.assign({}, post, {
            comments: newComments,
        });
        newPost = Object.assign({}, poll, {
            [`post${otherPostIndex}`]: [otherPost],
            [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
        });
    } else {
        newPost = { ...post, comments: newComments }
    }

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

    try {
        await patchDataAPI(`comment/${comment._id}/unlike`, null, auth.token)

        if (poll.userCreator) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/challenge/${poll._id}`,
                content: poll.subject
            };

            dispatch(removeNotify({ msg, auth, socket }));
        } else if (poll.user && poll.post1) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/poll/${poll._id}`,
                content: poll.subject
            }

            dispatch(removeNotify({ msg, auth, socket }))
        } else {
            // Notify
            const msg = {
                id: comment._id,
                text: 'liked your comment.',
                recipients: [post.user._id],
                url: `/post/${post._id}`,
                content: post.content,
            }

            dispatch(removeNotify({ msg, auth, socket }))
        }
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }
}

export const unDislikeComment = ({ comment, post, auth, poll, socket }) => async (dispatch) => {
    const newComment = { ...comment, dislikes: DeleteData(comment.dislikes, auth.user._id) }

    const newComments = EditData(post.comments, comment._id, newComment)

    let newPost;
    if (poll) {
        const { post1, post2 } = poll;
        const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
        const otherPost = poll[`post${otherPostIndex}`][0];
        const newP = Object.assign({}, post, {
            comments: newComments,
        });
        newPost = Object.assign({}, poll, {
            [`post${otherPostIndex}`]: [otherPost],
            [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
        });
    } else {
        newPost = { ...post, comments: newComments }
    }

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

    try {
        await patchDataAPI(`comment/${comment._id}/undislike`, null, auth.token)

        if (poll.userCreator) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/challenge/${poll._id}`,
                content: poll.subject
            };

            dispatch(removeNotify({ msg, auth, socket }));
        } else if (poll.user && poll.post1) {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/poll/${poll._id}`,
                content: poll.subject
            }

            dispatch(removeNotify({ msg, auth, socket }))
        } else {
            // Notify
            const msg = {
                id: comment._id,
                text: 'disliked your comment.',
                recipients: [post.user._id],
                url: `/post/${post._id}`,
                content: post.content,
            }

            dispatch(removeNotify({ msg, auth, socket }))
        }
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }
}

export const deleteComment = ({ post, comment, auth, socket, poll }) => async (dispatch) => {
    const deleteArr = [...post.comments.filter(cm => cm.reply === comment._id), comment]

    let newPost;
    if (poll) {
        const { post1, post2 } = poll;
        const otherPostIndex = post1[0]._id === post._id ? 2 : 1;
        const otherPost = poll[`post${otherPostIndex}`][0];
        const newP = Object.assign({}, post, {
            comments: post.comments.filter(cm => !deleteArr.find(da => cm._id === da._id)),
        });
        newPost = Object.assign({}, poll, {
            [`post${otherPostIndex}`]: [otherPost],
            [`post${otherPostIndex === 1 ? 2 : 1}`]: [newP],
        });
    } else {
        newPost = {
            ...post,
            comments: post.comments.filter(cm => !deleteArr.find(da => cm._id === da._id))
        }
    }

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

    socket.emit('deleteComment', newPost)
    try {
        deleteArr.forEach(item => {
            deleteDataAPI(`comment/${item._id}`, auth.token)

            if (poll.userCreator) {
                // Notify
                const msg = {
                    id: item._id,
                    text: comment.reply ? 'mentioned you in a comment.' : 'has commented on your challenge.',
                    recipients: comment.reply ? [comment.tag._id] : [post.user._id],
                    url: `/challenge/${poll._id}`,
                    content: poll.subject
                };

                dispatch(removeNotify({ msg, auth, socket }));
            } else if (poll.user && poll.post1) {
                // Notify
                const msg = {
                    id: item._id,
                    text: comment.reply ? 'mentioned you in a comment.' : 'has commented on your poll.',
                    recipients: comment.reply ? [comment.tag._id] : [post.user._id],
                    url: `/poll/${poll._id}`,
                    content: poll.subject
                }

                dispatch(removeNotify({ msg, auth, socket }))
            } else {
                // Notify
                const msg = {
                    id: item._id,
                    text: comment.reply ? 'mentioned you in a comment.' : 'has commented on your post.',
                    recipients: comment.reply ? [comment.tag._id] : [post.user._id],
                    url: `/post/${post._id}`,
                    content: post.content,
                }

                dispatch(removeNotify({ msg, auth, socket }))
            }

        })
    } catch (err) {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err.response.data.msg } })
    }

}