import React, {useState, useEffect, Fragment} from "react";
import "./PollsSection.css";
import poll1 from "../../images/poll.png";
import yey from "../../images/yey.png";
import ney from "../../images/ney.png";
import my_Adds from "../../components/home/my_Adds.png";
import { useSelector, useDispatch } from "react-redux";
import PollModal from "../../components/PollModal";
import PollCard from "../../components/PollCard";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import { getDataAPI } from "../../utils/fetchData";
import equal2 from "../../images/equal2.png";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import myArena from "../../images/poll.png";

import { Dialog } from "@mui/material";

const PollsSection = () => {
  const [friends, setFriends] = useState([]);
  const [ActivePoll, setActivePoll] = useState(null);
  const { auth, poll } = useSelector((state) => state);
  const { id } = useParams()

  // Dialog open state and src state
  const [open, setOpen] = useState(false)
  const [dialogImageSrc, SetDialogImageSrc] = useState()

  // Dialog controller
  const handleDialogOpen = src => {
      SetDialogImageSrc(`${process.env.REACT_APP_PUBLIC_FOLDER}/${src}`)
      setOpen(true)
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const myAsyncFunction = async () => {
      try {

        const result = await getDataAPI(`userpolls/${id}`, auth.token);


        const data = result.data;

        const arrayOfPolls = data.polls;

        const leftImagesPath = arrayOfPolls.map(
          (e) => e.post1[0].images[0].filename.filename
        );
        const rightImagesPath = arrayOfPolls.map(
          (e) => e.post2[0].images[0].filename.filename
        );
        const leftIdsPath = arrayOfPolls.map((e) => e.post1[0]._id);
        const rightIdsPath = arrayOfPolls.map((e) => e.post2[0]._id);
        const pollsIdsNewstToOldestLeftToRight = mergeArrays(
          leftIdsPath,
          rightIdsPath
        );
        const winners = arrayOfPolls.map((e) => e.winner);
        const duplicatedWinners = duplicateElements(winners);
        let resultState = [];
        for (let i = 0; i < pollsIdsNewstToOldestLeftToRight.length; i++) {
          if (!duplicatedWinners[i][0]) {
            resultState.push("equal");
          } else if (
            pollsIdsNewstToOldestLeftToRight[i] === duplicatedWinners[i][0]
          ) {
            resultState.push("winner");
          } else {
            resultState.push("loser");
          }
        }
        const pollsImagesNewstToOldestLeftToRight = mergeArrays(
          leftImagesPath,
          rightImagesPath
        );
        const arrayInFriendsFormat = mergeArraysToObject(
          pollsImagesNewstToOldestLeftToRight,
          resultState
        );

        setFriends(arrayInFriendsFormat);

      } catch (error) {
        
      }
    };
    myAsyncFunction();
  }, [auth.token]);

  useEffect(()=>{
      
      
      }
  ,[friends])
  function mergeArrays(array1, array2) {
    try{
    const mergedArray = [];
    const maxLength = Math.max(array1.length, array2.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < array1.length) {
        mergedArray.push(array1[i]);
      }
      if (i < array2.length) {
        mergedArray.push(array2[i]);
      }
    }
    return mergedArray;}catch (e) {


    }
  }
  function convertArrayToArrayObjects(arr) {
    return arr.map((str) => ({ profilePicture: str }));
  }
  function mergeArraysToObject(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      throw new Error("The input arrays must have the same length.");
    }
    return arr1.map((str, index) => ({
      profilePicture: str,
      state: arr2[index],
    }));
  }
  function duplicateElements(arr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i], arr[i]);
    }
    return result;
  }
  const { homePosts, theme } = useSelector((state) => state);
  const location = useLocation();

  try{
  const postType = new URLSearchParams(location.search).get("postType");
  

  let postsToShow = homePosts.posts;
  if (postType !== null) {

    postsToShow = postsToShow.filter((post) => post.postType === postType);
  }
  }catch (e) {

    
  }
  useEffect(async () => {
try {
  const res = await getDataAPI(`ActivePoll/${id}`, auth.token);

  setActivePoll(res.data.polls)
}catch (e){

  
}

  }, [auth, homePosts.posts]);
  
  return (
    <Fragment >
        <div  style={{ display: 'flex' }}>
      <div style={{ backgroundColor: '#d5d5d5', flex: 1, marginRight: '10px',marginLeft:"10px",minHeight: '100%',borderRadius:"20px" }}>
      <div className="polls-container--YN">
        <div className="polls--header--container">
            <img src={yey} className="header--img--yey--ney" />
            <img src={ney} className="header--img--yey--ney" />
          </div>
          
          {friends.map((friend) => (
            <div className="pollscard--YN" /* key={friend._id} */>
              {friend.state === "winner" && (
                <div className="card--badge1">
                  <img src={yey} className="polls--img--yey--ney" />
                </div>
              )}
              {friend.state === "loser" && (
                <div className="card--badge2">
                  <img src={ney} className="polls--img--yey--ney" />
                </div>
              )}
              {friend.state === "equal" && (
                <div className="card--badge3">
                  <img
                    src={equal2}
                    className="polls--img--yey--ney"
                    id="polls--img--yey--ney--equal2"
                  />
                </div>
              )}
              <img
                src={`${process.env.REACT_APP_PUBLIC_FOLDER}/${friend.profilePicture}`}
                alt=""
                className="polls--img--polls"
                onClick={() => handleDialogOpen(friend.profilePicture)}
              />
            </div>
          ))}
        </div>
        {
        auth.user._id === id ?(<div> <img
        src={my_Adds}
        id="addsButtonWishedPolls"
        onClick={() => dispatch({ type: GLOBALTYPES.POLL, payload: true })}
        />
            </div>
              ):null
        }
        
        {poll && <PollModal />}
      </div>
      <div style={{ backgroundColor: '#d5d5d5', flex: 1 ,marginRight:"10px",minHeight: '300px',borderRadius:"20px"}}>



          <img src={myArena} className="challengesDiv--arenaImage" />
          <div className="challengesDiv rightChallenges d-flex flex-column" >
            <div className="challengesDiv--header">
              {ActivePoll!=null && (
                  <div className="posts">
                    {ActivePoll.map((poll) => (

                        <PollCard
                            key={poll._id}
                            poll={poll}
                            theme={theme}
                            isPicks={true}
                        />
                    )) }
                  </div>
              )}
            </div>
          </div>

        </div>









        {/*<div style={{ backgroundColor: '#d5d5d5', flex: 1 ,marginRight:"10px",minHeight: '300px',borderRadius:"20px"}}>*/}
        {/*  <img src={myArena} className="challengesDiv--arenaImage" />*/}
        {/*  <div className="challengesDiv rightChallenges d-flex flex-column" >*/}
        {/*    <div className="challengesDiv--header">*/}
        {/*  {ActivePoll!=null && (*/}
        {/*    <div className="posts">*/}
        {/*      {ActivePoll.map((poll) => (*/}

        {/*        <PollCard*/}
        {/*          key={poll._id}*/}
        {/*          poll={poll}*/}
        {/*          theme={theme}*/}
        {/*          isPicks={true}*/}
        {/*        />*/}
        {/*      )) }*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*  /!* i'm going to add here *!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        </div>



      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        sx={{"& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: "350px", 
          },
        }
      }}
      >
          <img 
            src={dialogImageSrc}
            alt=''
          />
      </Dialog>
    </Fragment>
  );
};
export default PollsSection;
