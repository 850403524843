import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
//import {likePost, unLikePost} from "../redux/actions/postAction";
import {dislikePost, likePost, unDislikePost, unLikePost} from "../redux/actions/postPollAction";
const LikeButton = ({poll, post, pollStatus,}) => {
    useEffect(()=>{

        setIsLike(((post.likes).includes(auth.user._id)))

    },[poll])
    useEffect(()=>{
        setIsLike(((post.likes).includes(auth.user._id)))

    },[post.dislikes.length,post.likes.length])
   const postId=post._id
    const dispatch = useDispatch();
    const { auth, theme, socket } = useSelector((state) => state);

    const [loadLike, setLoadLike] = useState(false);

    const [loadDislike, setLoadDislike] = useState(false);


    const [isLike, setIsLike] = useState(((post.likes).includes(auth.user._id)));

    const [isDislike, setIsDislike] = useState(false);
    const handleUnLike = async () => {

        setIsLike(false)

     //   if (loadLike) return;

       // setLoadLike(true);
        await dispatch(unLikePost({ poll,postId:post._id, auth, socket }));
       // setLoadLike(false);
    };
    let otherPost;
    const handleLike = async () => {
        
        if(poll.post1[0]._id==post._id){
   otherPost=poll.post2[0]
        }else{
            otherPost=poll.post1[0]
        }


        if(!isLike) {
            if (((post.dislikes).includes(auth.user._id))) {
                await dispatch(unDislikePost({poll, postId: post._id, auth, socket}));

            }else if(((otherPost.dislikes).includes (auth.user._id))){
                await dispatch(unDislikePost({poll, postId: otherPost._id, auth, socket}));
            }else if(((otherPost.likes).includes(auth.user._id))){
                await dispatch(unLikePost({ poll,postId:otherPost._id, auth, socket }));
            }
            //  if (loadLike) return;
            setIsLike(true)

            await dispatch(likePost({poll, postId: post._id, auth, socket}));
        }


    };



  return (
    <>
      {(isLike) ? (
          (new Date(poll.finishDate) > new Date()) ?

          <div className="like-button">
          <div className="like-icon" >

              <i
                  className="fas fa-thumbs-up text-danger"
                  onClick={async () => {
                      await handleUnLike()
                  }
                  }
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.likes.length}
              />
              
              </div>

      <div className="like-buttonPoll text-danger ">{post.likes.length}</div>

              </div>
               :(
<div className="like-button">
          <div className="like-icon" >
                  
              <i
                  className="fas fa-thumbs-up text-danger"
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.likes.length}
              />

</div>

<div className="like-buttonPoll text-danger ">{post.likes.length}</div>

        </div>
              
                  )
      ) : (
          (new Date(poll.finishDate) >new Date()) ?
          <div className="like-button">
          <div className="like-icon">
              <i
                  className="fas fa-thumbs-up "
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  onClick={async () => {
                      await handleLike()

                  }
                  }

                  data-number={post.likes.length}
              />
              </div>

<div className="like-buttonPoll ">{post.likes.length}</div>

        </div>
              : 
              <div className="like-button">
              <div className="like-icon" >

              <i
                  className="fas fa-thumbs-up "
                  style={{filter: theme ? "invert(1)" : "invert(0)"}}
                  data-number={post.likes.length}
              />
                    </div>

      <div className="like-buttonPoll ">{post.likes.length}</div>

              </div>
      )
      }

    </>
  );
};
export default LikeButton;