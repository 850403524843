import React from 'react'

export default function PageInfo({ bio, owner }) {

    return (
        <>
            {Object.entries(bio).map(([key, value]) => 
                <div key={key}>
                    <div>{key}</div>
                    <div>{value}</div>
                </div>
            )}
        </>
    )
}