import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { unfriend } from "../../redux/actions/profileAction";

export default function ProductItem(user) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { auth, profile, socket } = useSelector((state) => state);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Unfriend = async () => {
    if (load) return;

     setLoad(true);
    await dispatch(unfriend({ users: profile.users, user: user.user , auth, socket }));
    setLoad(false); 
    
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={Unfriend}>Unfriend</MenuItem>
      </Menu>
    </div>
  );
}
