
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createPoll } from "../redux/actions/pollAction";
import Icons from "./Icons";
import { imageShow, videoShow, audioShow } from "../utils/mediaShow";
import { postDataAPI } from "../utils/fetchData";
import { imageUpload } from "../utils/imageUpload";


const PollModal = () => {
    const [time, setTime] = useState(new Date());


    const [selectedTime, setSelectedTime] = useState(""); // State for time input
    const [minTime, setMinTime] = useState(""); // State for time input
    const [selectedDate, setSelectedDate] = useState('');
    const [mentions, setMentions] = useState('')
    const today = new Date();

    const handleTimeChange = (event) => {


        let newStartDate=new Date(selectedDate + 'T' + event.target.value)
        let Today=new Date()

        if(newStartDate<=Today){


            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Please select start time not depassed" },
            });
            Today.setMinutes(Today.getMinutes() + 2);

            let currentHours = (Today).getHours();
            let currentMinutes = (Today).getMinutes();
            let formattedTime = currentHours + ':' + currentMinutes;
            setSelectedTime(formattedTime)
        } else
            setSelectedTime(event.target.value);


    };
    useEffect(() => {
        const isDateSelectedToday = selectedDate == today.toISOString().split('T')[0];

        if (isDateSelectedToday) {
            setMinTime(today.getHours() + ":" + today.getMinutes())
        }
        else
            setMinTime("")
    }, [selectedDate]);


    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };
    const { auth, theme, status, socket } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [content, setContent] = useState("");
    const [images, setImages] = useState([]);
    const [imagesPost1, setImagesPost1] = useState([]);
    const [imagesPost2, setImagesPost2] = useState([]);
    let date = new Date();
    date.setDate(date.getDate() + 2)
    const [maxDate, setMaxDate] = useState(date.toISOString().split('T')[0]);
    const [minDate, setMinDate] = useState(new Date().toISOString().split('T')[0]);

    const [postType, setPostType] = useState("");

    const [stream, setStream] = useState(false);
    const videoRef = useRef();
    const refCanvas = useRef();
    const [tracks, setTracks] = useState("");


    const [value, setValue] = useState('10:00');

    const onChange = (timeValue) => {
        setValue(timeValue);
    }
    const handleChangeImagesPost1 = (e) => {
        const files = [...e.target.files];
        let err = "";
        let newImages = [];

        files.forEach((file) => {
            if (!file) return (err = "File does not exist.");

            if (file.size > 1024 * 1024 * 25) {
                return (err = "The image/video largest is 25mb.");
            }

            return newImages.push(file);
        });

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } });
        setImagesPost1([...imagesPost1, ...newImages]);
    };

    const handleChangeImagesPost2 = (e) => {
        const files = [...e.target.files];
        let err = "";
        let newImages = [];

        files.forEach((file) => {
            if (!file) return (err = "File does not exist.");

            if (file.size > 1024 * 1024 * 5) {
                return (err = "The image/video largest is 5mb.");
            }

            return newImages.push(file);
        });

        if (err) dispatch({ type: GLOBALTYPES.ALERT, payload: { error: err } });
        setImagesPost2([...imagesPost2, ...newImages]);
    };

    const deleteImagesPost1 = (index) => {
        const newArr = [...imagesPost1];
        newArr.splice(index, 1);
        setImagesPost1(newArr);
    };

    const deleteImagesPost2 = (index) => {
        const newArr = [...imagesPost2];
        newArr.splice(index, 1);
        setImagesPost2(newArr);
    };

    const handleStream = () => {
        setStream(true);
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then((mediaStream) => {
                    videoRef.current.srcObject = mediaStream;
                    videoRef.current.play();

                    const track = mediaStream.getTracks();
                    setTracks(track[0]);
                })
                .catch((err) => console.log(err));
        }
    };

    const handleCapture = () => {
        const width = videoRef.current.clientWidth;
        const height = videoRef.current.clientHeight;

        refCanvas.current.setAttribute("width", width);
        refCanvas.current.setAttribute("height", height);

        const ctx = refCanvas.current.getContext("2d");
        ctx.drawImage(videoRef.current, 0, 0, width, height);
        let URL = refCanvas.current.toDataURL();
        setImages([...images, { camera: URL }]);
    };

    const handleStopStream = () => {
        tracks.stop();
        setStream(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dateObject = new Date(selectedDate);


        if (imagesPost1.length === 0 || imagesPost2.length === 0)
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Please add your media." },
            });


        else if (new Date(selectedDate + 'T' + selectedTime) == "Invalid Date") {
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Please add a valid date  and time." },
            })
        } else if ((((new Date()).toISOString().split('T')[0]) == selectedDate) && (new Date(selectedDate + 'T' + selectedTime) < (new Date()))) {

            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Choose a time that has not passed " },
            })
        }
        else if (content == "") {
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Please add subject" },
            })
        } 

        else if (!/^(#[^#\s]+ *)+$/.test(mentions.trim())) 
            return dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "The mentions field is not valid." }
            })
  
        else {
            let mediaPost1 = [];

            let mediaPost2 = [];

            if (imagesPost1.length > 0) {
                mediaPost1 = await imageUpload(imagesPost1);
            }

            if (imagesPost2.length > 0) {
                mediaPost2 = await imageUpload(imagesPost2);
            }

            const res1 = await postDataAPI(
                "postpolls",
                { images: mediaPost1 },
                auth.token
            );

            const post1 = res1.data.newPost._id;

            const res2 = await postDataAPI(
                "postpolls",
                { images: mediaPost2 },
                auth.token
            );

            const post2 = res2.data.newPost._id;
            const concatenatedDateTime = new Date(selectedDate + 'T' + selectedTime);

            const mongofinishDate = concatenatedDateTime.toISOString();

            dispatch(
                createPoll({
                    post1,
                    post2,
                    subject: content,
                    finishDate: mongofinishDate,
                    auth,
                    socket,
                    postType,
                    mentions
                })
            );

            setContent("");
            setImagesPost1([]);
            setImagesPost2([]);
            if (tracks) tracks.stop();
            dispatch({ type: GLOBALTYPES.POLL, payload: false });
        }
    };

    useEffect(() => {
        if (status.onEdit) {
            setContent(status.content);
            setImages(status.images);
        }
    }, [status]);

    const handlePostTypeChange = (event) => {
        setPostType(event.target.value);

    };


    return (
        <div className="status_modal">
            <form onSubmit={handleSubmit}>
                <div className="status_header">
                    <h5 className="m-0">Create Poll</h5>
                    <span
                        onClick={() =>
                            dispatch({
                                type: GLOBALTYPES.POLL,
                                payload: false,
                            })
                        }
                    >
                        &times;
                    </span>
                </div>

                <div className="status_body">
                    <textarea
                        name="content"
                        value={content}
                        placeholder={`${auth.user.fullname}, what are you thinking?`}
                        onChange={(e) => setContent(e.target.value)}
                        style={{
                            filter: theme ? "invert(1)" : "invert(0)",
                            color: theme ? "white" : "#111",
                            background: theme ? "rgba(0,0,0,.03)" : "",
                        }}
                    />

                    <div className="d-flex">
                        <div className="flex-fill">
                            <select value={postType} onChange={handlePostTypeChange} className="form-control" style={{ maxWidth: "75%" }}>
                                <option value="">Select a niche</option>
                                <option value="fashion">Fashion</option>
                                <option value="beauty">Beauty</option>
                                <option value="photography">Photography</option>
                                <option value="food">Food</option>
                                <option value="gadgets_and_technology">
                                    Gadgets and Technology
                                </option>
                                <option value="diy_crafts">DIY Crafts</option>
                                <option value="music_and_instruments">
                                    Music and Instruments
                                </option>
                                <option value="art">Art</option>
                                <option value="health_and_fitness">Health and Fitness</option>
                                <option value="sports">Sports</option>
                                <option value="diet_&_weight_loss">Diet & Weight Loss</option>
                                <option value="self-motivation">Self-Motivation</option>
                                <option value="entrepreneurship">Entrepreneurship</option>
                                <option value="beverage">Beverage</option>
                                <option value="foreign_languages">Foreign Languages</option>
                                <option value="interior_design">Interior Design</option>
                                <option value="gardening">Gardening</option>
                                <option value="farming">Farming</option>
                                <option value="job_and_resume">Job and Resume</option>
                                <option value="design_and_development">Design and Development</option>
                                <option value="lifestyle">Lifestyle</option>
                                <option value="books_and_literature">Books and Literature</option>
                                <option value="cars_&_moto">Cars & Moto</option>
                            </select>
                        </div>

                        <Icons setContent={setContent} content={content} theme={theme} />
                    </div>

                    <h5>Mentions: </h5>
                    <textarea
                        name="mentions"
                        value={mentions}
                        placeholder={`Enter Mentions here `}
                        onChange={(e) => setMentions(e.target.value)}
                        style={{
                            filter: theme ? "invert(1)" : "invert(0)",
                            color: theme ? "white" : "#111",
                            background: theme ? "rgba(0,0,0,.03)" : "",
                            marginTop: "5px",
                            fontFamily: "inherit",
                            border: "1px solid #ccc",
                            padding: "8px",
                            resize: "vertical"
                        }}
                    />

                    <div>

                        <h5>Select Finish Date :</h5>
                        <input
                            style={{ maxWidth: "70%" }}
                            className="form-control"
                            defaultValue={minDate}
                            min={minDate}
                            max={maxDate}

                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </div>
                    <div>
                        <h5>select finish time :</h5>
                        <input
                            type="time"
                            className="form-control"
                            style={{ maxWidth: "70%" }}
                            value={selectedTime}
                            onChange={handleTimeChange}
                            min={minTime}
                        />


                    </div>

                    <h5 className="m-0">Option 1</h5>

                    <div className="show_images">
                        {imagesPost1.map((img, index) => (
                            <div key={index} id="file_img">
                                {img.camera ? (
                                    imageShow(img.camera, theme)
                                ) : img.url ? (
                                    <>
                                        {img.type.startsWith("video/")
                                            ? videoShow(img.url, theme)
                                            : img.type.startsWith("audio/")
                                                ? audioShow(img.url, theme)
                                                : imageShow(img.url, theme)}
                                    </>
                                ) : (
                                    <>
                                        {img.type.startsWith("video/")
                                            ? videoShow(URL.createObjectURL(img), theme)
                                            : img.type.startsWith("audio/")
                                                ? audioShow(URL.createObjectURL(img), theme)
                                                : imageShow(URL.createObjectURL(img), theme)}
                                    </>
                                )}
                                <span onClick={() => deleteImagesPost1(index)}>&times;</span>
                            </div>
                        ))}
                    </div>

                    {stream && (
                        <div className="stream position-relative">
                            <video
                                autoPlay
                                muted
                                ref={videoRef}
                                width="100%"
                                height="100%"
                                style={{ filter: theme ? "invert(1)" : "invert(0)" }}
                            />

                            <span onClick={handleStopStream}>&times;</span>
                            <canvas ref={refCanvas} style={{ display: "none" }} />
                        </div>
                    )}

                    <div className="input_images">
                        {stream ? (
                            <i className="fas fa-camera" onClick={handleCapture} />
                        ) : (
                            <>
                                <i className="fas fa-camera" onClick={handleStream} />

                                <div className="file_upload">
                                    <i className="fas fa-image" />
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        multiple
                                        accept="image/*,video/*"
                                        onChange={handleChangeImagesPost1}
                                    />
                                </div>
                                <div className="file_upload">
                                    <i className="fas fa-file-audio" />



                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept="audio/*"
                                        onChange={handleChangeImagesPost1}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <h5 className="m-0">Option 2</h5>

                    <div className="show_images">
                        {imagesPost2.map((img, index) => (
                            <div key={index} id="file_img">
                                {img.camera ? (
                                    imageShow(img.camera, theme)
                                ) : img.url ? (
                                    <>
                                        {img.type.startsWith("video/")
                                            ? videoShow(img.url, theme)
                                            : img.type.startsWith("audio/")
                                                ? audioShow(img.url, theme)
                                                : imageShow(img.url, theme)}
                                    </>
                                ) : (
                                    <>
                                        {img.type.startsWith("video/")
                                            ? videoShow(URL.createObjectURL(img), theme)
                                            : img.type.startsWith("audio/")
                                                ? audioShow(URL.createObjectURL(img), theme)
                                                : imageShow(URL.createObjectURL(img), theme)}
                                    </>
                                )}
                                <span onClick={() => deleteImagesPost2(index)}>&times;</span>
                            </div>
                        ))}
                    </div>

                    {stream && (
                        <div className="stream position-relative">
                            <video
                                autoPlay
                                muted
                                ref={videoRef}
                                width="100%"
                                height="100%"
                                style={{ filter: theme ? "invert(1)" : "invert(0)" }}
                            />

                            <span onClick={handleStopStream}>&times;</span>
                            <canvas ref={refCanvas} style={{ display: "none" }} />
                        </div>
                    )}

                    <div className="input_images">
                        {stream ? (
                            <i className="fas fa-camera" onClick={handleCapture} />
                        ) : (
                            <>
                                <i className="fas fa-camera" onClick={handleStream} />

                                <div className="file_upload">
                                    <i className="fas fa-image" />
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        multiple
                                        accept="image/*,video/*"
                                        onChange={handleChangeImagesPost2}
                                    />
                                </div>
                                <div className="file_upload">
                                    <i className="fas fa-file-audio" />
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        accept="audio/*"
                                        onChange={handleChangeImagesPost2}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="status_footer">
                    <button className="btn btn-secondary w-100" type="submit">
                        Post Poll
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PollModal;