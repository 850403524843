import React, { useState } from "react";
import Countdown from "../../Countdown";
const PollCardSubject = ({ post, theme }) => {

  const [readMore, setReadMore] = useState(false);

  return (
    <div
      className="card_body-content"
      style={{
        filter: theme ? "invert(1)" : "invert(0)",
        color: theme ? "white" : "#111",
      }}
    >
      <div className="card_body">
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "24px",
          marginBottom: "8px"
        }}>
          {post.title || "insert title here"}
          <Countdown poll={post} />
        </div>
        <span>
          {post.subject.length < 60
            ? post.subject
            : readMore
              ? post.subject + " "
              : post.subject.slice(0, 60) + "....."}
        </span>
        {post.subject.length > 60 && (
          <span className="readMore" onClick={() => setReadMore(!readMore)}>
            {readMore ? "Hide content" : "Read more"}
          </span>
        )}
        <div style={{marginTop: "16px"}}>
          {post.mentions}
        </div>
      </div>
    </div>
  );
};
export default PollCardSubject;