import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getChallenge, getPoll} from "../../redux/actions/pollAction";
import LoadIcon from "../../images/loading.gif";
import PollCard from "../../components/PollCard";
import ChallengeCard from "../../components/ChallengeCard";
import {getDataAPI} from "../../utils/fetchData";
import {POST_TYPES} from "../../redux/actions/postAction";

const ChallangeInfo = () => {
  const { id } = useParams();
  const [challenge, setChallenge] = useState(null);
  const { auth, detailPost } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(async () => {
    const res = await getDataAPI(`challenge/${id}`, auth.token)


      //const newArr = detailPost.filter((post) => post._id === id);

      setChallenge(res.data.Challenge);

  }, [id, auth]);
  useEffect(() => {

  }, [challenge]);


  return (
    <div className="posts">
      {challenge == null && (
        <img src={LoadIcon} alt="loading" className="d-block mx-auto my-4" />
      )}

      {(challenge!=null) && (
        <div style={{paddingLeft:"25%" , paddingRight:"25%"}}>
      {/*//  <PollCard key={item._id} poll={item} />*/}
          <ChallengeCard key={challenge._id} challenge={challenge} />
        </div>
      )}
    </div>
  );
};

export default ChallangeInfo;