import { GLOBALTYPES } from '../actions/globalTypes'




const initialState = {
     PageName:"",
     UserListe:[],
    filtredListe:[]
};


//-----------------------|| CARD REDUCER ||-----------------------//

const SearchReducer = (state = initialState, action) => {

    //const [listecard, addcart] = useState({cards:});

    switch (action.type) {
        case GLOBALTYPES.INITIALIZE:
            
            
            
            state.PageName=action.payload.PageName
            state.filtredListe=action.payload.friends
            state.UserListe=action.payload.friends
            return {
                ...state

            };
            case GLOBALTYPES.CHANGE:

            state.filtredListe=action.payload.filtredListe
                
                
            return {
                ...state

            };

        default:
            return state;


    }};

export default SearchReducer;
