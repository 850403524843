import { GLOBALTYPES } from './globalTypes'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'
import { POST_TYPES } from './postAction'

export const POLL_TYPES = {
    CREATE_POLL: 'CREATE_POLL',
    LOADING_POLL: 'LOADING_POLL',
    GET_POLLS: 'GET_POLLS',
    UPDATE_POLL: 'UPDATE_POLL',
    GET_POLL: 'GET_POLL',
    DELETE_POLL: 'DELETE_POLL'
}

export const createPoll = ({ post1, post2, subject, finishDate, auth, socket, postType, mentions }) => async (dispatch) => {
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })


        const res = await postDataAPI('polls', { post1, post2, subject, finishDate, postType, mentions }, auth.token)

        dispatch({
            type: POST_TYPES.CREATE_POST,
            payload: { ...res.data.newPoll, user: auth.user }
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })
        const content = subject;
        // Notify
        const msg = {
            id: res.data.newPoll._id,
            text: 'added a new poll.',
            recipients: res.data.newPoll.user.friends,
            url: `/poll/${res.data.newPoll._id}`,
            content
        }

        dispatch(createNotify({ msg, auth, socket }))


    } catch (err) {
        
        dispatch({
            type: GLOBALTYPES.ALERT,

            payload: { error: err.response.data.msg }
        })
    }

}
export const getChallenge = ({ detailChallenge, id, auth }) => async (dispatch) => {
    if (detailChallenge.every(poll => poll._id !== id)) {
        try {
            const res = await getDataAPI(`challenge/${id}`, auth.token)
            dispatch({ type: POST_TYPES.GET_POST, payload: res.data.challenge })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}
export const getPoll = ({ detailPost, id, auth }) => async (dispatch) => {
    if (detailPost.every(poll => poll._id !== id)) {
        try {
            const res = await getDataAPI(`polls/${id}`, auth.token)
            dispatch({ type: POST_TYPES.GET_POST, payload: res.data.poll })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}

export const getPost = ({ detailPost, id, auth }) => async (dispatch) => {
    if (detailPost.every(post => post._id !== id)) {
        try {
            const res = await getDataAPI(`post/${id}`, auth.token)
            dispatch({ type: POST_TYPES.GET_POST, payload: res.data.post })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}

export const deletePoll = ({ poll, auth, socket }) => async (dispatch) => {
    dispatch({ type: POST_TYPES.DELETE_POST, payload: poll })

    try {

        const res = await deleteDataAPI(`polls/${poll.post._id}`, auth.token)

        const content = res.data.newPoll.subject;
        // Notify
        const msg = {
            id: res.data.newPoll._id,
            text: 'added a new poll.',
            recipients: res.data.newPoll.user.friends,
            url: `/poll/${res.data.newPoll._id}`,
            content
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        
        dispatch({
            type: GLOBALTYPES.ALERT,

            payload: { error: err.response.data.msg }
        })
    }
}
export const deleteChallange    = ({ challange, auth, socket }) => async (dispatch) => {
  //  dispatch({ type: POST_TYPES.DELETE_POST, payload: poll })

    try {
        const res = await deleteDataAPI(`challenge/${challange._id}`, auth.token)

        const content = res.data.newPoll.subject;
        // Notify

        //dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {


        dispatch({
            type: GLOBALTYPES.ALERT,

            payload: { error: err.response.data.msg }
        })
    }
}