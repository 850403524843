import audiothumbnail from'./audiothumbnail.png'

export const imageShow = (src, theme) => {
  return (
    <img
      src={src}
      alt="images"
      className="img-thumbnail"
      style={{ filter: theme ? "invert(1)" : "invert(0)" }}
    />
  );
};

export const videoShow = (src, theme) => {
  return (
    <video
      controls
      src={src}
      alt="images"
      className="img-thumbnail"
      style={{ filter: theme ? "invert(1)" : "invert(0)" }}
    />
  );
};

export const audioShow = (src, theme) => {
  return (
    <div>
      <img src={audiothumbnail} alt="audio thumbnail" /> 
      <audio
        controls
        src={src}
        alt="audio"
        className="audio-thumbnail"
        style={{ filter: theme ? "invert(1)" : "invert(0)" }}
      />
    </div>
  );
};
