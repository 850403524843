import React, { useEffect, useState } from "react";
import Info from "../../components/profile/Info";
import { useSelector, useDispatch } from "react-redux";
import LoadIcon from "../../images/loading.gif";
import { getProfileUsers } from "../../redux/actions/profileAction";
import { useParams } from "react-router-dom";
import FeaturedFriends from "./FeaturedFriends";
import Invitations from "./Invitations";
import Suggestions from "./Suggestions";
import FriendsOnline from "./FriendsOnline";
import {GLOBALTYPES} from "../../redux/actions/globalTypes";
import search from "./searchNew.png";
import SearchUsers from "./SearchUsers";
import {getDataAPI} from "../../utils/fetchData";

const FriendsManagement = () => {
  const { profile, auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const {page} = useSelector((state) => state);

  const [friendsTab, setFriendsTab] = useState(true);
  const [invitationsTab, setInvitationsTab] = useState(false);
  const [suggestionsTab, setSuggestionsTab] = useState(false);
  const [onlineTab, setOnlineTab] = useState(false);
  const [number, setNumber] = useState(0);
  useEffect(async () => {

    if (page.ClickInvitations)
      showInvitationsTab()
    await dispatch({type: GLOBALTYPES.INITIALSTATE});


  }, [])
  useEffect(async () => {
    const res = await getDataAPI(`user/${id}/invitations`, auth.token);
   setNumber( res.data.user.invitations.length)
   // setInvitations(res.data.user.invitations);
  }, [auth, id]);
  const showFriendsTab = async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "friend"}});

    setFriendsTab(true);
    setInvitationsTab(false);
    setSuggestionsTab(false);
    setOnlineTab(false);
  };
  const showInvitationsTab = async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});

    setInvitationsTab(true);
    setFriendsTab(false);
    setSuggestionsTab(false);
    setOnlineTab(false);
  };
  const showSuggestionsTab = async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "suggestion"}});

    setSuggestionsTab(true);
    setFriendsTab(false);
    setInvitationsTab(false);
    setOnlineTab(false);
  };
  const showOnlineTab = async () => {
    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "online"}});

    setOnlineTab(true);
    setFriendsTab(false);
    setInvitationsTab(false);
    setSuggestionsTab(false);
  };
  useEffect(() => {
    if (profile.ids.every((item) => item !== id)) {

      dispatch(getProfileUsers({ id, auth }));
    }
  }, [id, auth, dispatch, profile.ids]);
  return (
      <div className="profile">
        <Info auth={auth} profile={profile} dispatch={dispatch} id={id} />
        {auth.user._id === id && (
            <div>
            <div className="profile_tab">
              <button
                  className={friendsTab ? "active" : ""}
                  onClick={async () => {
                    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});
                    showFriendsTab()
                  }}
              >
                Friends
              </button>
              <button
                  className={invitationsTab ? "active" : ""}
                  onClick={async () => {
                    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});
                    showInvitationsTab()
                  }}
              >
                Invitations<span
                  style={{
                    display: "inline-block",
                    opacity:"50%",
                    backgroundColor: "rgb(255, 0, 0)", // Red color in RGB format
                    color: "white",
                    fontSize: "12px",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    textAlign: "center",
                    lineHeight: "20px",
                    marginLeft: "5px"
                  }}
              >{number}</span>
              </button>
              <button
                  className={suggestionsTab ? "active" : ""}
                  onClick={async () => {
                    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});
                    showSuggestionsTab()
                  }}
              >
                Suggestions
              </button>
              <button
                  className={onlineTab ? "active" : ""}
                  onClick={async () => {
                    await dispatch({type: GLOBALTYPES.INITIALIZE, payload: {"friends": [], "PageName": "invitation"}});
                    showOnlineTab()
                  }}
              >
                Online
              </button>
              <SearchUsers/>
            </div>

            </div>

        )}
        {profile.loading ? (
            <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
        ) : (
            <>
              {friendsTab ? (
                  <FeaturedFriends />
              ) : invitationsTab ? (
                  <Invitations />
              ) : suggestionsTab ? (
                  <Suggestions />
              ) : onlineTab ? (
                  <FriendsOnline />
              ) : null}
            </>
        )}
      </div>
  );
};

export default FriendsManagement;