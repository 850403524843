import { GLOBALTYPES } from './globalTypes'
import { imageUpload } from '../../utils/imageUpload'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'
import {PROFILE_TYPES} from "./profileAction";

export const POST_TYPES = {
    CREATE_POST: 'CREATE_POST',
    LOADING_POST: 'LOADING_POST',
    GET_POSTS: 'GET_POSTS',
    UPDATE_POST: 'UPDATE_POST',
    GET_POST: 'GET_POST',
    DELETE_POST: 'DELETE_POST'
}


export const createPost = ({ content, images, auth, socket, postType }) => async (dispatch) => {
    let media = []
    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (images.length > 0) media = await imageUpload(images)

        const res = await postDataAPI('posts', { content, images: media, postType }, auth.token)

        dispatch({
            type: POST_TYPES.CREATE_POST,
            payload: { ...res.data.newPost, user: auth.user }
        })
        dispatch({
            type: PROFILE_TYPES.ADDPOST,
            payload: { post:res.data.newPost}
        })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

        // Notify
        const msg = {
            id: res.data.newPost._id,
            text: 'added a new post.',
            recipients: res.data.newPost.user.friends,
            url: `/post/${res.data.newPost._id}`,
            content,
            image: media[0].url
        }

        dispatch(createNotify({ msg, auth, socket }))

    } catch (err) {
        console.log(err)
        dispatch({
            type: GLOBALTYPES.ALERT,

            payload: { error: err.response.data.msg }
        })
    }
}

export const getPosts = (token, postType) => async (dispatch) => {
    try {
        dispatch({ type: POST_TYPES.LOADING_POST, payload: true })

        const url = postType ? `posts?postType=${postType}` : 'posts';
        const res = await getDataAPI(url, token)

        dispatch({
            type: POST_TYPES.GET_POSTS,
            payload: { ...res.data, page: 2 }
        })

        dispatch({ type: POST_TYPES.LOADING_POST, payload: false })
    } catch (err) {
        if (err.response && err.response.data && err.response.data.msg) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        } else {
            
        }
    }
}

export const updatePost = ({ content, images, auth, status, postType }) => async (dispatch) => {
    let media = []
    
    const newImages = images.filter(img => !status.images.includes(img));
    const oldImages = images.filter(img => status.images.includes(img));


    if (status.content === content
        && newImages.length === 0
        && oldImages.length === status.images.length
    ) return;

    try {
        dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
        if (newImages.length > 0) media = await imageUpload(newImages)

        const res = await patchDataAPI(`post/${status._id}`, {
            content, images: [...oldImages, ...media], postType
        }, auth.token)

        dispatch({ type: POST_TYPES.UPDATE_POST, payload: res.data.newPost })

        dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } })
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const likePost = ({ post, auth, socket }) => async (dispatch) => {
    /*const isDisliked = post.dislikes.filter((dislike) => dislike._id === auth.user._id).length > 0;

    let newPost;
    if (isDisliked) {
        // Remove dislike and add like
        newPost = {
            ...post,
            dislikes: post.dislikes.filter((dislike) => dislike._id !== auth.user._id),
            likes: [...post.likes, auth.user],
        };
        dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
        socket.emit('unDislikePost', newPost);
        socket.emit('likePost', newPost);
    } else {
        // Add like
      */
    post.likes.push(auth.user._id)


        dispatch({ type: POST_TYPES.UPDATE_POST, payload: post });
        socket.emit('likePost', post);


    try {
       /* let text
        if (isDisliked) {
            await patchDataAPI(`post/${post._id}/undislike`, null, auth.token)
        text='Disliked your Post.'
        }
        else {

        */
        let text
            await patchDataAPI(`post/${post._id}/like`, null, auth.token);
            text='liked your post.'

        //}
        // Notify

        const msg = {
            id: auth.user._id,
            text: text,
            recipients: [post.user._id],
            url: `/post/${post._id}`,
            content: post.content,
        };

        dispatch(createNotify({ msg, auth, socket }));
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg },
        });
    }
};

export const unLikePost = ({ post, auth, socket }) => async (dispatch) => {
 /*   const newPost = { ...post, likes: post.likes.filter(like => like._id !== auth.user._id) }
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })
*/
        let index = post.likes.indexOf(auth.user._id);
      post.likes.splice(index, 1);



    let  otherPost
    dispatch({ type: GLOBALTYPES.ELEMENTCHANGED, payload: {element:post} });
    socket.emit('unLikePost', post)

    try {
        await patchDataAPI(`post/${post._id}/unlike`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'liked your post.',
            recipients: [post.user._id],
            url: `/post/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const dislikePost = ({ post, auth, socket }) => async (dispatch) => {
   /* const isLiked = post.likes.filter((like) => like._id === auth.user._id).length > 0;

    let newPost;
    if (isLiked) {
        // Remove like and add dislike
        newPost = {
            ...post,
            likes: post.likes.filter((like) => like._id !== auth.user._id),
            dislikes: [...post.dislikes, auth.user],
        };
        dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost });
        socket.emit('unLikePost', newPost);
        socket.emit('dislikePost', newPost);
    }
    else {
        // Add dislike
        newPost = {
            ...post,
            dislikes: [...post.dislikes, auth.user],
        };*/
    post.dislikes.push(auth.user._id)

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: post });
        socket.emit('dislikePost', post);
  //  }

    try {
      //  if (isLiked) {
       //     await patchDataAPI(`post/${post._id}/unlike`, null, auth.token)
       // }
        await patchDataAPI(`post/${post._id}/dislike`, null, auth.token);

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'disliked your post.',
            recipients: [post.user._id],
            url: `/post/${post._id}`,
            content: post.content,
        };

        dispatch(createNotify({ msg, auth, socket }));
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg },
        });
    }
};

export const unDislikePost = ({ post, auth, socket }) => async (dispatch) => {
 /*   const newPost = { ...post, dislikes: post.dislikes.filter(dislike => dislike._id !== auth.user._id) }
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: newPost })

    socket.emit('unDislikePost', newPost)
*/

    let index = post.dislikes.indexOf(auth.user._id);
    post.dislikes.splice(index, 1);
    dispatch({ type: POST_TYPES.UPDATE_POST, payload: post })

    try {
        await patchDataAPI(`post/${post._id}/undislike`, null, auth.token)

        // Notify
        const msg = {
            id: auth.user._id,
            text: 'disliked your post.',
            recipients: [post.user._id],
            url: `/post/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const getPost = ({ detailPost, id, auth }) => async (dispatch) => {
    if (detailPost.every(post => post._id !== id)) {
        try {
            const res = await getDataAPI(`post/${id}`, auth.token)
            dispatch({ type: POST_TYPES.GET_POST, payload: res.data.post })
        } catch (err) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: err.response.data.msg }
            })
        }
    }
}

export const deletePost = ({ post, auth, socket }) => async (dispatch) => {
    dispatch({ type: POST_TYPES.DELETE_POST, payload: post })

    try {
        const res = await deleteDataAPI(`post/${post._id}`, auth.token)

        // Notify
        const msg = {
            id: post._id,
            text: 'added a new post.',
            recipients: res.data.newPost.user.friends,
            url: `/post/${post._id}`,
        }
        dispatch(removeNotify({ msg, auth, socket }))

    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const savePost = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: [...auth.user.saved, post._id] }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`savePost/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}

export const unSavePost = ({ post, auth }) => async (dispatch) => {
    const newUser = { ...auth.user, saved: auth.user.saved.filter(id => id !== post._id) }
    dispatch({ type: GLOBALTYPES.AUTH, payload: { ...auth, user: newUser } })

    try {
        await patchDataAPI(`unSavePost/${post._id}`, null, auth.token)
    } catch (err) {
        dispatch({
            type: GLOBALTYPES.ALERT,
            payload: { error: err.response.data.msg }
        })
    }
}