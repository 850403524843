import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { login,loginGoogle,loginFacebook  } from "../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { facebook, google } from "../images";

const Login = ({toggleLoginComp}) => {
  const initialState = { email: "", password: "" };
  const [userData, setUserData] = useState(initialState);
  const { email, password } = userData;

  const [typePass, setTypePass] = useState(false);

  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth.token && auth.token !== "") {
      history.push("/");
    }
  }, [auth.token, history]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(userData));
  };

  const handleGoogle = (e) => {
    e.preventDefault();
    dispatch(loginGoogle());
  }

  const handleFacebook = (e) => {
    e.preventDefault();
    dispatch(loginFacebook());
  }

  
 
  return (
    <form onSubmit={handleSubmit}>
      <div className="loginbtn">
        <div className="login" >Login</div>
        <div className="register" onClick={toggleLoginComp}>Register</div>
      </div>
      <div className="auth_gf">
        <span>Sign In With:</span>
        <div className="gf_container">
        <button onClick={handleGoogle} ><img src={google} alt="google-button" /></button>
          <button onClick={handleFacebook}><img src={facebook} alt="facebook-button" /></button>
        </div>

        <span>Or:</span>
      </div>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          id="exampleInputEmail1"
          name="email"
          placeholder="Email or username"
          aria-describedby="emailHelp"
          onChange={handleChangeInput}
          value={email}
        />
      </div>

      <div className="form-group">
        <div className="pass">
          <input
            type={typePass ? "text" : "password"}
            className="form-control"
            id="exampleInputPassword1"
            placeholder="Password"
            onChange={handleChangeInput}
            value={password}
            name="password"
          />
        </div>
      </div>

      <div className="bottom_info">
        <label className="rmbr-checkbox">
          Remember Me
          <input type="checkbox" />
          <span className="checkmark"></span>
        </label>

        <p>Forgot Password?</p>
      </div>

      <button
        type="submit"
        className="btn_login login"
        disabled={email && password ? false : true}
      >
        SIGN IN
      </button>

      <p className="under_btn">Not a Member? <span onClick={toggleLoginComp}>Register</span></p>
    </form>
  );
};

export default Login;
