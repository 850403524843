import { GLOBALTYPES } from '../actions/globalTypes'

const challengeReducer = (state = false, action) => {
    switch (action.type){
        case GLOBALTYPES.CHALLENGE:
            return action.payload;
        default:
            return state;
    }
}

export default challengeReducer