import { GLOBALTYPES } from './globalTypes'
import { imageUpload } from '../../utils/imageUpload'
import { postDataAPI, getDataAPI, patchDataAPI, deleteDataAPI } from '../../utils/fetchData'
import { createNotify, removeNotify } from './notifyAction'

export const POST_TYPES = {
  CREATE_POST: 'CREATE_POST',
  LOADING_POST: 'LOADING_POST',
  GET_POSTS: 'GET_POSTS',
  UPDATE_POST: 'UPDATE_POST',
  GET_POST: 'GET_POST',
  DELETE_POST: 'DELETE_POST'
}


export const createPost = ({ content, images, auth, socket, postType, pollOrChallenge }) => async (dispatch) => {
  let media = []
  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
    if (images.length > 0) media = await imageUpload(images)

    const res = await postDataAPI('posts', { content, images: media, postType }, auth.token)

    dispatch({
      type: POST_TYPES.CREATE_POST,
      payload: { ...res.data.newPost, user: auth.user }
    })

    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } })

  } catch (err) {
    
    dispatch({
      type: GLOBALTYPES.ALERT,

      payload: { error: err.response.data.msg }
    })
  }
}

export const updatePost = ({ content, images, auth, status, postType }) => async (dispatch) => {
  let media = []
  const imgNewUrl = images.filter(img => !img.url)
  const imgOldUrl = images.filter(img => img.url)

  if (status.content === content
    && imgNewUrl.length === 0
    && imgOldUrl.length === status.images.length
  ) return;

  try {
    dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } })
    if (imgNewUrl.length > 0) media = await imageUpload(imgNewUrl)

    const res = await patchDataAPI(`post/${status._id}`, {
      content, images: [...imgOldUrl, ...media], postType
    }, auth.token)

    dispatch({ type: POST_TYPES.UPDATE_POST, payload: res.data.newPost })

    dispatch({ type: GLOBALTYPES.ALERT, payload: { success: res.data.msg } })
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg }
    })
  }
}

export const likePost = ({ poll, postId, auth, socket }) => async (dispatch) => {

  let newPoll

  if (poll.post1[0]._id==postId)

      newPoll=poll.post1[0].likes.push(auth.user._id)
  else
     newPoll=poll.post2[0].likes.push(auth.user._id)


  dispatch({ type: GLOBALTYPES.ELEMENTCHANGED, payload: {element:poll} });
  socket.emit('likePost', poll);

  try {
    await patchDataAPI(`postpoll/${postId}/like`, poll._id, auth.token);

    if (poll.userCreator) {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'liked your challenge.',
        recipients: [poll.userCreator._id, poll.userinvited._id],
        url: `/challenge/${poll._id}`,
        content: poll.subject
      };
      dispatch(createNotify({ msg, auth, socket }));
    } else {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'liked your poll.',
        recipients: [poll.user._id],
        url: `/poll/${poll._id}`,
        content: poll.subject
      };
      dispatch(createNotify({ msg, auth, socket }));
    }

  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg },
    });
  }
};

export const unLikePost = ({ poll, postId, auth, socket }) => async (dispatch) => {


  if (poll.post1[0]._id==postId)
  {
    let index = poll.post1[0].likes.indexOf(auth.user._id);
    poll.post1[0].likes.splice(index, 1);

  }
  else {
    let index =  poll.post2[0].likes.indexOf(auth.user._id);
    poll.post2[0].likes.splice(index, 1);
   // poll.post2[0].likes.pull(auth.user._id)
  }

  let  otherPost
  dispatch({ type: GLOBALTYPES.ELEMENTCHANGED, payload: {element:poll} });
  //dispatch({ type: POST_TYPES.UPDATE_POST, payload: poll })

  socket.emit('unLikePost', poll)

  try {

    await patchDataAPI(`postpoll/${postId}/unlike`, null, auth.token)

    if (poll.userCreator) {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'liked your challenge.',
        recipients: [poll.userCreator._id, poll.userinvited._id],
        url: `/challenge/${poll._id}`,
      }
      dispatch(removeNotify({ msg, auth, socket }))
    } else {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'liked your poll.',
        recipients: [poll.user._id],
        url: `/poll/${poll._id}`,
      }
      dispatch(removeNotify({ msg, auth, socket }))
    }

  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg }
    })
  }
}

export const dislikePost = ({ poll, postId, auth, socket }) => async (dispatch) => {


  if (poll.post1[0]._id==postId)

    poll.post1[0].dislikes.push(auth.user._id)
  else
    poll.post2[0].dislikes.push(auth.user._id)

  dispatch({ type: GLOBALTYPES.ELEMENTCHANGED, payload: {element:poll} });
  //dispatch({ type: POST_TYPES.UPDATE_POST, payload: poll })

  //socket.emit('unLikePost', poll)

  socket.emit('dislikePost', poll);

  try {
    const data =null;
    await patchDataAPI(`postpoll/${postId}/dislike`, data, auth.token);

    if (poll.userCreator) {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'disliked your challenge.',
        recipients: [poll.userCreator._id, poll.userinvited._id],
        url: `/challenge/${poll._id}`,
        content: poll.subject
      };
      dispatch(createNotify({ msg, auth, socket }));
    } else {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'disliked your poll.',
        recipients: [poll.user._id],
        url: `/poll/${poll._id}`,
        content: poll.subject
      };
      dispatch(createNotify({ msg, auth, socket }));
    }


  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg },
    });
  }
};

export const unDislikePost = ({ poll, postId, auth, socket }) => async (dispatch) => {
 /* let newPost;
  if (poll.post1[0]._id === postId) {
    const newP = {
      ...poll.post1[0],
      dislikes: poll.post1[0].dislikes.filter((dislike) => dislike._id !== auth.user._id),
    };
    newPost = {
      ...poll,
      post1: [newP]
    }
  } else {
    const newP = {
      ...poll.post2[0],
      dislikes: poll.post1[0].dislikes.filter((dislike) => dislike._id !== auth.user._id),
    };
    newPost = {
      ...poll,
      post2: [newP]
    }
  }*/
  if (poll.post1[0]._id==postId)
  {
    let index = poll.post1[0].dislikes.indexOf(auth.user._id);
    poll.post1[0].dislikes.splice(index, 1);

  }
  else {
    let index =  poll.post2[0].dislikes.indexOf(auth.user._id);
    poll.post2[0].dislikes.splice(index, 1);
    // poll.post2[0].likes.pull(auth.user._id)
  }
  dispatch({ type: POST_TYPES.UPDATE_POST, payload: poll })

  socket.emit('unDislikePost', poll)

  try {

    await patchDataAPI(`postpoll/${postId}/undislike`, null, auth.token)

    if (poll.userCreator) {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'disliked your challenge.',
        recipients: [poll.userCreator._id, poll.userinvited._id],
        url: `/challenge/${poll._id}`,
      }
      dispatch(removeNotify({ msg, auth, socket }))
    } else {
      // Notify
      const msg = {
        id: auth.user._id,
        text: 'disliked your poll.',
        recipients: [poll.user._id],
        url: `/poll/${poll._id}`,
      }
      dispatch(removeNotify({ msg, auth, socket }))
    }
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: { error: err.response.data.msg }
    })
  }
}

