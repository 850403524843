import React, { useEffect, useState } from "react";
import PageCover from "../../components/userpage/PageCover"
import PageMain from "../../components/userpage/PageMain"
import axios from "axios";
import { useSelector } from "react-redux";
import Loading from "../../components/alert/Loading";

export default function Page() {
    const { auth } = useSelector(state => state)
    const [pageData, setPageData] = useState({})
    const pageName = window.location.pathname.split('/').at(-1)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchPageData() {
            await axios.get('/api/page/' + pageName, {
                headers: { Authorization: auth.token }
            }).then(res => {
                setPageData(res.data);
                setIsLoading(false)
            })
                .catch(() => window.location.pathname = "/notFound")
        }
        fetchPageData()
    }, [pageData, auth.token, pageName])
    return (
        <>
            {isLoading
                ? <Loading />
                :
                <>
                    <PageCover />
                    <PageMain pageData={pageData} owner={pageData.owner === auth.user._id} />
                </>
            }
        </>
    )
}