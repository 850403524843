import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRouter from "./customRouter/PrivateRouter";

import Home from "./pages/home";
import Login from "./pages/login_registerPage";
import Register from "./pages/register";

import Profile from "./pages/profile/[id]"
import FriendsManagement from "./pages/friendsManagement/[id]"
import Conversation from "./pages/message/[id]"
import Poll from "./pages/poll/[id]"
import Post from "./pages/post/[id]"
import Savedd from "./pages/savedd/[id]"
import Settings from "./pages/settings/[id]"
import Page from "./pages/userpage/[id]"

import Message from "./pages/message/index.js"
import Discover from "./pages/discover"
import Challenge from "./pages/challenge"
import NotFound from "./components/NotFound";

import Alert from "./components/alert/Alert";
import Header from "./components/header/Header";
import StatusModal from "./components/StatusModal";
import ChallengeReqModal from "./components/ChallengeReqModal";
import UploadMediaModal from "./components/UploadMediaModal";

import { useSelector, useDispatch } from "react-redux";
import { refreshToken } from "./redux/actions/authAction";
import { getPosts } from "./redux/actions/postAction";
import { getSuggestions } from "./redux/actions/suggestionsAction";

import io from "socket.io-client";
import { GLOBALTYPES } from "./redux/actions/globalTypes";
import SocketClient from "./SocketClient";

import { getNotifies } from "./redux/actions/notifyAction";
import CallModal from "./components/message/CallModal";
import Peer from "peerjs";

import dotenv from "dotenv";
import ChallangeInfo from "./pages/Challange/[id]";
dotenv.config();

function App() {
  const { auth, status, modal, challenge, call, uploadMedia } = useSelector((state) => state);
  const dispatch = useDispatch();
  dotenv.config();
  useEffect(() => {
    dispatch(refreshToken());

    const socket = io();
    dispatch({ type: GLOBALTYPES.SOCKET, payload: socket });
    return () => socket.close();
  }, [dispatch]);

  useEffect(() => {
    if (auth.token) {
      dispatch(getPosts(auth.token));
      dispatch(getSuggestions(auth.token));
      dispatch(getNotifies(auth.token));
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
        }
      });
    }
  }, []);

  useEffect(() => {
    const newPeer = new Peer(undefined, {
      path: "/",
      secure: true,
    });

    dispatch({ type: GLOBALTYPES.PEER, payload: newPeer });
  }, [dispatch]);

  return (
      <Router>
        <Alert />

        <input type="checkbox" id="theme" />
        <div className={`App ${(status || modal) && "mode"}`} style={{overflow:'scroll'}}>
          <div className="main">
            {auth.token && <Header />}
            {status && <StatusModal />}
            {auth.token && <SocketClient />}
            {call && <CallModal />}
            {challenge && <ChallengeReqModal />}
            {uploadMedia && <UploadMediaModal />}

            <Route exact path="/" component={auth.token ? Home : Login} />
            <Route exact path="/register" component={Register} />


          {auth.token &&
            <Switch>
              <PrivateRouter path="/friendsManagement/:id" component={FriendsManagement} />
              <PrivateRouter path="/message/:id" component={Conversation} />
              <PrivateRouter path="/message" component={Message}/>

              <PrivateRouter path="/poll/:id" component={Poll} />
              <PrivateRouter path="/post/:id" component={Post} />
              <PrivateRouter path="/ViewChallenge/:id" component={ChallangeInfo} />

              <PrivateRouter path="/profile/:id" component={Profile} />
              <PrivateRouter path="/savedd/:id" component={Savedd} />
              <PrivateRouter path="/settings/:id" component={Settings} />
              <PrivateRouter path="/userpage/:id" component={Page} />
            </Switch>}

            {auth.token && 
              <Switch>
                <PrivateRouter path="/discover" component={Discover}/>
                <PrivateRouter path="/challenge" component={Challenge}/>
              </Switch>
            }

          <Route path="/notFound" component={NotFound} />
        </div>
        </div>
      </Router>
  );
}

export default App;